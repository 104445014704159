import React, { useEffect, useState } from "react";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import PersonIcon from "@mui/icons-material/Person";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Avatar,
  Select,
  MenuItem,
  Tabs,
  Tab,
  ListItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Icon,
} from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import {
  deboardUser,
  getLookup,
  RecruiterList,
  reporterDashboard,
} from "../../../services/apiRequests";
import { groupLookup } from "../../../utils/helpers";
import { ListItemButton } from "@mui/joy";
import { List } from "@mui/material";
import useLoader from "../../../Loader/useLoader";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import NoRecord from "../../../assets/icons/members_pana.svg";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ paddingTop: "1rem" }}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};
const colors = () => {
  const color = [
    "#FF5733", // Red-Orange
    "#33FF57", // Green
    "#3357FF", // Blue
    "#FF33A6", // Pink
    "#FFBD33", // Yellow-Orange
    "#33FFD5", // Turquoise
    "#A633FF", // Purple
    "#FF8633", // Orange
    "#33FF8A", // Light Green
    "#3390FF", // Light Blue
  ];
  return color[Math.floor(Math.random() * color.length)];
};

const Roles = () => {
  const [lookup, setLookup] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [activeTab, setActiveTab] = useState(0);
  const [choosedUsers, setChoosedUsers] = useState({ name: "" });
  const [approvers, setAprrovers] = useState([]);
  const [superVisor, setSuperviser] = useState({});
  const [reportingUsers, setReportingUsers] = useState([]);
  const [listSet, setList] = useState([]);
  const [change, setChange] = useState(false);
  const [localValues, setLocalValues] = useState({
    userCountry: "",
    userName: "",
  });

  const { getAccess } = useAuth();

  const { canReadWrite: accessDeBoard } = getAccess("25");

  const onTabChange = (_, index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    getReporterDetails();
  }, [localValues]);

  async function getReporterDetails() {
    try {
      showLoader();
      const reporterReportee = await RecruiterList(
        localValues.userName,
        localValues.userCountry
      );
      if (reporterReportee.status == 200) {
        setList(reporterReportee.data);
      }
    } catch (e) {
      //
    } finally {
      hideLoader();
    }
  }

  useEffect(() => {
    functionOnMount();
  }, []);

  async function functionOnMount() {
    showLoader();
    try {
      const lukup = await getLookup();
      if (lukup.status === 200) {
        const groupedLookup = groupLookup(lukup.data);

        setLookup(groupedLookup);
      }
    } catch (e) {
      //
    } finally {
      hideLoader();
    }
  }

  async function userReporterDashboard(e) {
    try {
      showLoader();
      setChoosedUsers(e);
      const res = await reporterDashboard(e.id);
      if (res.status === 200) {
        setAprrovers(res.data.approvers);
        setReportingUsers(res.data.reportingUsers);
        setSuperviser(res.data.supervisors);
      }
    } catch (error) {
      //
    } finally {
      hideLoader();
    }
  }
  
  const handleChange = (e) => {
    setLocalValues({ ...localValues, [e.target.name]: e.target.value });
  };

  const deboard = async () => {
    try {
      showLoader();
      const res = await deboardUser(choosedUsers?.id);
      if (res.status === 200) {
        toast.success("Candidate De board succesfully ");
        getReporterDetails();
        handleCloase();
      }
    } catch (error) {
      //
    } finally {
      hideLoader();
    }
  };

  const handleOpen = () => {
    setChange(true);
  };

  const handleCloase = () => {
    setChange(false);
  };

  const handleList = (e) => {
    console.log(e);
  };

  const getExpantion = (key) => {
    let findObj = "";
    if (key) findObj = lookup?.ROLE.find((obj) => obj.value === key);
    return findObj ? findObj.label : "";
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        margin: "1rem 2rem 1rem 1rem",
      }}
    >
      <Box
        sx={{
          border: "1px solid var(--grey-350)",
          borderRadius: "6px",
          paddingBottom: "1rem",
        }}
      >
        <Grid
          container
          sx={{
            padding: "1rem",
            border: "1px solid #c2c2b7",
            borderRadius: "0.6rem",
          }}
        >
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                width: "60%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  background: "#EDF6FC",
                  borderRadius: "50%",
                  height: "4.5rem",
                  width: "4.5rem",
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #44A0E3",

                  justifyContent: "center",
                }}
              >
                <PublicIcon />
              </Box>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "1rem",
                  fontWeight: "700",

                  lineHeight: "2rem",
                  textAlign: "left",
                  color: "var(--grey-400)",
                }}
              >
                Country
              </Typography>
              <Select
                onChange={handleChange}
                sx={{ minWidth: "14rem" }}
                name="userCountry"
                value={localValues.userCountry}
              >
                <MenuItem value="none">Select</MenuItem>
                {lookup?.USERCOUNTRY?.map((obj, key) => (
                  <MenuItem key={key} value={obj.value}>
                    {obj.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  background: "#EDF6FC",
                  border: "1px solid #44A0E3",
                  borderRadius: "50%",
                  height: "4.3rem",
                  width: "4.3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PersonIcon />
              </Box>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontSize: "1rem",
                  fontWeight: "700",
                  lineHeight: "2rem",
                  textAlign: "left",
                  color: "var(--grey-400)",
                }}
              >
                Role
              </Typography>

              <Select
                onChange={handleChange}
                sx={{ width: "14rem" }}
                name="userName"
                value={localValues.userName}
              >
                <MenuItem value="none">Select</MenuItem>
                {lookup?.ROLE?.map((obj, key) => (
                  <MenuItem key={key} value={obj.value}>
                    {obj.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {listSet.length > 0 && (
        <Box
          sx={{
            display: "flex",
            padding: "1rem",
            border: "1px solid #c2c2b7",
            borderRadius: "0.6rem",
          }}
        >
          <Box sx={{ width: "20%" }}>
            <Typography
              sx={{
                padding: "0 0 0.5rem 1rem",
                fontWeight: "bolder",
              }}
            >
              Members
            </Typography>
            {listSet.length < 0 ? (
              <Typography sx={{ fontWeight: "Bolder", color: "black" }}>
                User Name
              </Typography>
            ) : (
              <List
                sx={{
                  overflowX: "hidden",
                  maxHeight: "70vh", // Adjust the height as needed
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    height: "2px", // Height for horizontal scrollbar
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#E5E5E5", // Scrollbar thumb color
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555", // Scrollbar thumb hover color
                  },
                  // For Firefox
                  scrollbarWidth: "thin", // "auto" or "thin"
                  scrollbarColor: "#E5E5E5 #fff", // thumb and track color
                }}
              >
                {listSet?.map((obj, key) => {
                  return (
                    <ListItemButton
                      key={key}
                      onClick={(e) => userReporterDashboard(obj)}
                    >
                      <Grid
                        item
                        xs={4}
                        sx={{
                          width: "100%",
                          display: "flex",
                          gap: "6px",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "6px",
                            alignItems: "center",
                            width: "100%",
                            margin: "0.7rem 0 0.7rem 0",
                          }}
                        >
                          <Avatar
                            sx={{ bgcolor: colors(), fontWeight: "bolder" }}
                          >
                            {obj?.name
                              .match(/\b(\w)/g)
                              ?.join("")
                              ?.toUpperCase()}
                          </Avatar>
                          <Typography
                            sx={{
                              fontFamily: "Open Sans",
                              fontSize: "1rem",
                              fontWeight: "700",
                              lineHeight: "2rem",
                              textAlign: "left",
                            }}
                          >
                            {obj.name}
                          </Typography>
                        </Box>
                      </Grid>
                    </ListItemButton>
                  );
                })}
              </List>
            )}
          </Box>
          {choosedUsers.name !== "" ? (
            <Box sx={{ width: "80%" }}>
              <Box
                name="Reporter"
                sx={{ background: "#F2F9FD", padding: "0 0 0.5rem 1rem" }}
              >
                <Grid container sx={{ justifyContent: "space-between" }}>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      gap: "6px",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "1rem",
                        fontWeight: "700",
                        lineHeight: "2rem",
                        textAlign: "left",
                      }}
                    >
                      Member Name
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "6px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar sx={{ bgcolor: colors(), fontWeight: "bolder" }}>
                        {choosedUsers?.name
                          .match(/\b(\w)/g)
                          ?.join("")
                          ?.toUpperCase()}
                      </Avatar>

                      <Typography
                        sx={{
                          fontFamily: "Open Sans",
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "var(--grey-400)",
                        }}
                      >
                        {choosedUsers.name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sx={{ display: "grid", gap: "6px" }}>
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontSize: "1rem",
                        fontWeight: "700",
                        lineHeight: "2rem",
                        textAlign: "left",
                      }}
                    >
                      Supervisor
                    </Typography>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "direction",
                        gap: ".5rem",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bolder",
                          color: "black",
                          fontSize: "0.8rem",
                        }}
                      >
                        Name:
                        <span style={{ color: "grey" }}>
                          {`${superVisor?.firstName ?? ""} ${
                            superVisor?.lastName ?? ""
                          }`}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bolder",
                          color: "black",
                          fontSize: "0.8rem",
                        }}
                      >
                        Email:
                        <span style={{ color: "grey" }}>
                          {superVisor?.emailId ?? ""}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "bolder",
                          color: "black",
                          fontSize: "0.8rem",
                        }}
                      >
                        Role:
                      </Typography>
                    </Stack>
                  </Grid>
                  {accessDeBoard === true ? (
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Button
                        onClick={handleOpen}
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                      >
                        De Board
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
              <Tabs
                onChange={onTabChange}
                value={activeTab}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                TabIndicatorProps={{
                  style: {
                    display: "none", // Hide the indicator if needed
                  },
                }}
              >
                <Tab
                  label="Approvers"
                  sx={{ textTransform: "none", fontWeight: "bolder" }}
                />
                <Tab
                  label="Reportees"
                  sx={{ textTransform: "none", fontWeight: "bolder" }}
                />
              </Tabs>
              <TabPanel value={activeTab} index={0}>
                <Box name="Reportee">
                  <Table sx={{ width: "100%", paddingTop: "0" }}>
                    <TableHead>
                      <TableRow
                        sx={{
                          borderBottom: "1px solid  #D9D9D9",
                          background: "#F7F7F7",
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            // textAlign: "center",
                            textAlign: "left",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            // textAlign: "center",
                            textAlign: "left",
                          }}
                        >
                          Email Id
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            // textAlign: "center",
                            textAlign: "left",
                          }}
                        >
                          Roles
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {approvers?.map((obj, key) => {
                        return (
                          <TableRow key={key}>
                            <TableCell>{`${obj.firstName} ${obj.lastName}`}</TableCell>

                            <TableCell>{obj.emailId}</TableCell>
                            <TableCell>{getExpantion(obj.roleCd)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <Box name="Reportee">
                  <Table sx={{ width: "100%" }}>
                    <TableHead>
                      <TableRow
                        sx={{
                          borderBottom: "1px solid  #D9D9D9",
                          background: "#F7F7F7",
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            // textAlign: "center",
                            textAlign: "center",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            // textAlign: "center",
                            textAlign: "center",
                          }}
                        >
                          Email Id
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            // textAlign: "center",
                            textAlign: "center",
                          }}
                        >
                          Roles
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportingUsers?.map((obj, key) => {
                        return (
                          <TableRow key={key}>
                            <TableCell>{`${obj.firstName} ${obj.lastName}`}</TableCell>

                            <TableCell>{obj.emailId}</TableCell>
                            <TableCell>{getExpantion(obj.roleCd)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </TabPanel>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img src={NoRecord} />
              <Typography variant="h5">
                Please Select Members to Deboard
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {change && (
        <Dialog open={change}>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "1rem",
                borderRadius: "0.2rem",
                border: "1px solid grey",
              }}
            >
              <WarningAmberRoundedIcon
                sx={{ width: "5rem", height: "5rem", color: "#FF6D00" }}
              />
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                }}
              >
                Alert
              </Typography>
              <Typography sx={{ marginTop: "0.5rem" }} variant="h6">
                Are you sure you want to De board this candidate?
              </Typography>
              <DialogActions>
                <Button onClick={handleCloase} variant="outlined">
                  Cancel
                </Button>
                <Button variant="contained" onClick={deboard} autoFocus>
                  Ok
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default Roles;
