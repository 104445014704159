import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { MdOutlineDashboard as Dashboard } from "react-icons/md";
import { MdOutlineCalculate as RateCalculatorTabView } from "react-icons/md";
import { IoMdCheckboxOutline as VMSValue } from "react-icons/io";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import "../component/Login/Login.css";
import SearchIcon from "@mui/icons-material/Search";
import { Icon, InputAdornment, Stack, TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  conatiner1: {
    display: "flex",
    width: "98%",
    height: "66px",
    justifyContent: "space-between",
    alignItems: "center",
    flexShrink: "0",
    // borderBottom: "1px solid var(--Secon-2-D-Grey, #D9D9D9)",
    background: "var(--Background, #FFF)",
  },
  customPadding: {
    padding: "5px 10px !important",
  },
  parentcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",

    width: "100%",
  },
}));
const CommonContainer = ({
  DisplayAddnew,
  handleChange,
  HandleFilter,
  header,
  addCalc,

  DisplaySearch,
}) => {
  const [names, setName] = useState();
  const classes = useStyles();
  return (
    <div className={classes.parentcontainer}>
      <Paper elevation={0} className={classes.conatiner1}>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "0px",
          }}
        >
          <Grid
            item
            xs={6}
            md={2}
            sx={{
              display: "flex",
              gap: "8px",
              padding: "4px 16px 4px 20px",
              //maxWidth: "638px",
              minWidth: "fit-content",
              marginLeft: "0px",
              alignItems: "center",
            }}
          >
            <Icon>
              {header === "VMS Value" ? (
                <VMSValue />
              ) : header === "Rate Calculator Dashboard" ? (
                <Dashboard />
              ) : (
                <RateCalculatorTabView />
              )}
            </Icon>
            <p>{header}</p>
          </Grid>
          <Grid
            item
            xs={5}
            md={6}
            sx={{
              display: "flex",
              gap: "20px",
              // height: "50px",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Stack>
              {DisplaySearch && (
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // width: "382px",
                  }}
                >
                  {/* <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  onClick={() => {
                    if (names) {
                      HandleFilter(names);
                    } else {
                      alert("Enter Name to get list of Candidates");
                    }
                  }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton> */}
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      className: classes.customPadding,
                      classes: {
                        input: classes.customPadding,
                      },
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        HandleFilter(names);
                      }
                    }}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    placeholder="Search By Name"
                  />
                </Paper>
              )}
            </Stack>
            {/* <Button
              variant="outlined"
              size="small"
              startIcon={<FilterAltOutlinedIcon />}
              sx={{
                color: "black",
                border: "solid black 1px",
                textTransform: "none",
                padding: "4px 16px 4px 8px",
                minWidth: "80px",
              }}
            >
              Filter
            </Button> */}
            <Stack>
              {DisplayAddnew && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    minWidth: "140px",
                    padding: "9px 10px",
                    textTransform: "none",
                  }}
                  onClick={() => handleChange("Post", -1, "")}
                  startIcon={<AddBoxOutlinedIcon />}
                >
                  <Divider
                    sx={{ height: 18, m: 0.5, color: "white" }}
                    orientation="vertical"
                  />
                  {addCalc}
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default CommonContainer;
