import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { setVmsModal, setVmsStates } from "../../../reduxApp/fieldStore";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateById } from "../../../services/apiRequests";
import {
  Box,
  Typography,
  Modal,
  Button,
  Paper,
  TextField,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import map from "lodash";
import {
  getVmsDataByPagesandRows,
  activityDataSave,
} from "../../../services/apiRequests";
export const vmsInitialValue = {
  vmsCompany: "",
  bussinessUnit: "",
  customerName: "",
  feePercent: "",
};
const VMSModal = ({ setvmsObj, vmsValues, vmsEdit, postApi }) => {
  const dispatch = useDispatch();
  const employSelector = useSelector((state) => {
    return state.dropedField;
  });
  const [VMSFee, setVmsFee] = useState([
    "BU01",
    "BU02",
    "BU03",
    "BU04",
    "BU05",
    "BU06",
    "BU07",
    "BU08",
    "BU09",
    "BU010",
    "BU011",
    "BU012",
    "none",
  ]);
  useEffect(() => {
    (async () => {
      if (employSelector.vmsStats.action !== "Post") {
        await getCandidateById(
          "getbyvmsid",
          employSelector.vmsStats.vmsId
        ).then((res) => {
          setvmsObj(res.data);
        });
      }
      const vmsCompany = await getVmsDataByPagesandRows(0, 1000).data;
      const extractedData = map(
        vmsCompany,
        ({ customerName, feePercent }) => customerName
      );
      //const vmsArray = extractedData };
      //setVmsFee(vmsArray);
    })();
  }, []);

  const modalDiv = [
    {
      name: "Customer Name",
      stateName: "customerName",
    },
    {
      name: "VMS",
      stateName: "feePercent",
    },
    // {
    //   name: "VMS Company",
    //   stateName: "vmsCompany",
    // },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    display: "flex",
    flexDirection: "column",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: "30px",
              fontWeight: "600",
              color: "rgba(0,0,0,0.8)",
            }}
          >
            {employSelector.vmsStats.action === "Edit"
              ? "Update VMS"
              : "Add VMS"}
          </Typography>
          <IconButton
            onClick={() => {
              dispatch(setVmsModal(""));
              setvmsObj(vmsInitialValue);
            }}
          >
            <CloseIcon sx={{ color: "grey" }} />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            border: "1px solid rgba(0,0,0,0.9)",
            borderRadius: "2px",
            padding: "10px",
            gap: "20px",
            justifyContent: "space-evenly",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
              width: "225px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "700",
              }}
            >
              Business Unit
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={vmsValues["bussinessUnit"]}
              sx={{ width: "100%" }}
              InputProps={{
                style: {
                  marginTop: "5px",
                  fontSize: "14px",
                },
              }}
              placeholder={"Enter Business Unit"}
              onChange={(e) =>
                setvmsObj({
                  ...vmsValues,
                  bussinessUnit: e.target.value,
                })
              }
            >
              {VMSFee.map((obj) => (
                <MenuItem value={obj}>{obj}</MenuItem>
              ))}
            </Select>
          </Box>
          {modalDiv.map((item) => {
            return (
              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    color: "rgba(0,0,0,0.8)",
                    fontWeight: "700",
                  }}
                >
                  {item.name}
                </Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={vmsValues[item.stateName]}
                  InputProps={{
                    style: {
                      // width: "90%",
                      marginTop: "5px",
                      fontSize: "14px",
                    },
                  }}
                  placeholder={`Enter ${item.name}`}
                  onChange={(e) =>
                    setvmsObj({
                      ...vmsValues,
                      [item.stateName]: e.target.value,
                    })
                  }
                />
              </Box>
            );
          })}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="outlined"
            InputProps={{
              style: {
                fontSize: "14px",
              },
            }}
            sx={{
              margin: "10px",
              color: "#6e57a8",
              fontWeight: "600",
              border: "1px solid black",
            }}
            onClick={() => {
              setvmsObj(vmsInitialValue);
              dispatch(setVmsModal(""));
              dispatch(
                setVmsStates({
                  vmsId: -1,
                  action: "",
                })
              );
            }}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            sx={{ margin: "15px", background: "#0f4e96" }}
            onClick={() => {
              employSelector.vmsStats.action === "Post" ? postApi() : vmsEdit();
              dispatch(setVmsModal(""));
              dispatch(
                setVmsStates({
                  vmsId: -1,
                  action: "",
                })
              );

              // setvmsObj(vmsInitialValue);
            }}
          >
            Save & Close
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};
export default VMSModal;
