import React, { useState, useRef, Suspense, useEffect } from "react";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import GradingIcon from "@mui/icons-material/Grading";
import { styled, useTheme } from "@mui/material/styles";
import CommonContainer from "../../CommonContainer/CommonContainer";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { MdOutlineDashboard as Dashboard } from "react-icons/md";
import DashboardTabsAll from "./DashboardTabsAll";
import { MdOutlineCalculate as RateCalculatorTabView } from "react-icons/md";
import { IoMdCheckboxOutline as VMSValue } from "react-icons/io";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { BiDetail as BidBreakDown } from "react-icons/bi";
import { MdMenuBook as Narrative } from "react-icons/md";
import { dashboard_api } from "../../constance/DashboardJson";
import { Routepath } from "../../routing/routpath";
//import { SsidChartIcon as RepostsIcon } from "@mui/icons-material/SsidChart";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { MdOutlineNotificationsActive as Notification } from "react-icons/md";
import {
  Paper,
  Grid,
  Card,
  Box,
  List,
  Button,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Tooltip,
  ListItem,
  IconButton,
  Typography,
  Slide,
} from "@mui/material";
import {
  setDashboardChild,
  updateCandidateDetails,
  setMatchedLookupobj,
} from "../../reduxApp/fieldStore";
import sliderIcon from "../../assets/Images/sliderIcon.png";
import MuiDrawer from "@mui/material/Drawer";
import { ICONS } from "../../constance/SideBarJson";
import { NavLink, Outlet } from "react-router-dom";
import { Translate } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import { getLoginApi } from "../../services/apiRequests";
import { useDispatch, useSelector } from "react-redux";
import useMount from "../../hooks/useMount";
import { screenAccess } from "../../reduxApp/fieldStore";
import Loader from "../../Loader";
import HttpClient from "../../utils/HttpClient";
import { useMemo } from "react";
import { getAuthToken } from "../../selectors/loginAuthSelector";
import { subRoutes } from "../../routing/routes";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { getAuthorization } from "../../selectors/authSelectors";
import HowToRegIcon from "@mui/icons-material/HowToReg";

const reactIcons = {
  Dashboard: Dashboard,
  RateCalculatorTabView: RateCalculatorTabView,
  VMSValue: VMSValue,
  Narrative: Narrative,
  Notification: Notification,
};

const LeftnavLinks = () => {
  const token = useSelector(getAuthToken);
  const loginDetials = useSelector(getAuthorization);
  const screenId = useSelector((state) => state.dropedField);
  useEffect(() => {
    return () => {
      HttpClient.interceptors.request.handlers = [];
      // HttpClient.interceptors.request.eject(interceptorsRef.current);
    };
  }, []);

  useMemo(() => {
    // HttpClient.interceptors.request.eject(interceptorsRef.current);
    HttpClient.interceptors.request.handlers = [];

    HttpClient.interceptors.request.use(
      (config) => {
        if (token && token.Uid) {
          config.headers = {
            ...config.headers,
            screenId: screenId.screenId,
            uuid: token.Uid,
            email: token.mailid,
            role: token.roles,
          };
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, [token, screenId.screenId]);

  const [styleList, setStyles] = useState("Dashboard");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const { getAccess } = useAuth();

  const { noAccess: noAccessRC } = getAccess("2");
  const { noAccess: noAccessVMS } = getAccess("7");
  const { noAccess: noAccessDashboard } = getAccess("1");
  const { noAccess: noAccessNotify } = getAccess("8");
  const { noAccess: noAccessReport } = getAccess("19");
  const { noAccess: noAccessAuditing } = getAccess("14");
  const { noAccess: noAccessOnboarded } = getAccess("22");
  const { noAccess: noAccessRoles, canReadWrite: AccessRoles } =
    getAccess("24");

  const drawerWidth = "200px";
  const StyleSet = (e, key) => {
    if (e.isActive) setStyles(key);
    return e.isActive ? { color: "white" } : { color: "black" };
  };
  useMount(async () => {
    const loginApiResponse = await getLoginApi();
    const screensDetailsValue = loginApiResponse.data[0];
    dispatch(screenAccess(screensDetailsValue));
  });

  function clearRc() {
    if (open) {
      dispatch(updateCandidateDetails({ ...dashboard_api }));
      dispatch(
        setDashboardChild({
          userAction: "Post",
          id: "",
          userName: "",
        })
      );

      dispatch(setMatchedLookupobj({}));
    }
  }

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transitions: "none",
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    overflowX: "hidden",
    transitions: "none",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    position: "static",

    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": {
        ...openedMixin(theme),
        position: "relative",
        zIndex: 1,
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": {
        ...closedMixin(theme),
        position: "relative",
        zIndex: 1,
      },
    }),
  }));

  return (
    <Box sx={{ background: "#f2f2f2", display: "flex" }}>
      <Grid
        container
        xs={2}
        gap={"20px"}
        flexWrap={"nowrap"}
        sx={{ position: "fixed", top: "56px" }}
      >
        <Grid item sx={{ position: "relative" }}>
          <Drawer variant="permanent" open={open}>
            <Box id="LeftNavBar">
              <List
                id="ul-Container"
                sx={{
                  paddingTop: "30px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {noAccessDashboard === false ? (
                  <ListItem
                    className={
                      open && styleList === "Dashboard" ? "activeList" : ""
                    }
                    onClick={clearRc}
                    id="listDashboard"
                    component={NavLink}
                    to={Routepath.DashboardTabsAll}
                    style={(e) => StyleSet(e, "Dashboard")}
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      paddingTop: "8px",
                      px: 2.5,
                    }}
                  >
                    <Tooltip title={open ? "" : "Dashboard"}>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                          paddingRight: "8px",

                          margin: 0,
                        }}
                      >
                        <span
                          style={{
                            fontSize: "24px",
                            display: "flex",
                            alignItems: "center",
                            color:
                              styleList === "Dashboard" ? "white" : "black",
                          }}
                        >
                          <Dashboard />
                        </span>
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText
                      className="listText"
                      primary="Dashboard"
                      sx={{
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItem>
                ) : null}
                <Box>
                  {noAccessRC === false ? (
                    <ListItem
                      className={
                        open && styleList === "RateCalculatorTabView"
                          ? "activeList"
                          : ""
                      }
                      id="listDashboard"
                      onClick={clearRc}
                      component={NavLink}
                      to={Routepath.RateCalculatorTabView}
                      style={(e) => StyleSet(e, "RateCalculatorTabView")}
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        paddingTop: "8px",
                        px: 2.5,
                      }}
                    >
                      <Tooltip title={open ? "" : "Rate Calculator"}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            paddingRight: "8px",

                            margin: 0,
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "24px",
                              color:
                                styleList === "RateCalculatorTabView"
                                  ? "white"
                                  : "black",
                            }}
                          >
                            <RateCalculatorTabView />
                          </span>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        className="listText"
                        primary="Rate Calculator"
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItem>
                  ) : null}
                  {noAccessVMS === false ? (
                    <ListItem
                      className={
                        open && styleList === "VMSValue" ? "activeList" : ""
                      }
                      onClick={clearRc}
                      id="listDashboard"
                      component={NavLink}
                      to={Routepath.VMSValue}
                      style={(e) => StyleSet(e, "VMSValue")}
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        paddingTop: "8px",
                        px: 2.5,
                      }}
                    >
                      <Tooltip title={open ? "" : "VMSValue"}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            paddingRight: "8px",

                            margin: 0,
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "24px",
                              color:
                                styleList === "VMSValue" ? "white" : "black",
                            }}
                          >
                            <VMSValue />
                          </span>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        className="listText"
                        primary="VMS Value"
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItem>
                  ) : null}
                  {noAccessNotify === false ? (
                    <ListItem
                      className={
                        open && styleList === "NotificationTables"
                          ? "activeList"
                          : ""
                      }
                      onClick={clearRc}
                      id="listDashboard"
                      component={NavLink}
                      to={Routepath.NotificationTables}
                      style={(e) => StyleSet(e, "NotificationTables")}
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        paddingTop: "8px",
                        px: 2.5,
                      }}
                    >
                      <Tooltip title={open ? "" : "NotificationTables"}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            paddingRight: "8px",

                            margin: 0,
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "24px",
                              color:
                                styleList === "NotificationTables"
                                  ? "white"
                                  : "black",
                            }}
                          >
                            <Notification />
                          </span>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        className="listText"
                        primary="Notification"
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItem>
                  ) : null}
                  {noAccessOnboarded === false ? (
                    <ListItem
                      className={
                        open && styleList === "OnBoarded" ? "activeList" : ""
                      }
                      onClick={clearRc}
                      id="listDashboard"
                      component={NavLink}
                      to={Routepath.AeNotification}
                      style={(e) => StyleSet(e, "OnBoarded")}
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        paddingTop: "8px",
                        px: 2.5,
                      }}
                    >
                      <Tooltip title={open ? "" : "OnBoarded"}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            paddingRight: "8px",

                            margin: 0,
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "24px",
                              color:
                                styleList === "OnBoarded" ? "white" : "black",
                            }}
                          >
                            <HowToRegIcon />
                          </span>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        className="listText"
                        primary="On Boarded"
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItem>
                  ) : null}
                  {noAccessAuditing === false ? (
                    <ListItem
                      className={
                        open && styleList === "Audit" ? "activeList" : ""
                      }
                      onClick={clearRc}
                      id="listDashboard"
                      component={NavLink}
                      to={Routepath.Auditing}
                      style={(e) => StyleSet(e, "Audit")}
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        paddingTop: "8px",
                        px: 2.5,
                      }}
                    >
                      <Tooltip title={open ? "" : "Audit"}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            paddingRight: "8px",

                            margin: 0,
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "24px",
                              color: styleList === "Audit" ? "white" : "black",
                            }}
                          >
                            <GradingIcon />
                          </span>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        className="listText"
                        primary="Audit"
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItem>
                  ) : null}

                  {noAccessReport === false ? (
                    <ListItem
                      className={
                        open && styleList === "Reports" ? "activeList" : ""
                      }
                      onClick={clearRc}
                      id="listDashboard"
                      component={NavLink}
                      to={Routepath.ReportComp}
                      style={(e) => StyleSet(e, "Reports")}
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        paddingTop: "8px",
                        px: 2.5,
                      }}
                    >
                      <Tooltip title={open ? "" : "Reports"}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            paddingRight: "8px",

                            margin: 0,
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "24px",
                              color:
                                styleList === "Reports" ? "white" : "black",
                            }}
                          >
                            <TrendingUpIcon />
                          </span>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        className="listText"
                        primary="Reports"
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItem>
                  ) : null}

                  {AccessRoles === true ? (
                    <ListItem
                      className={
                        open && styleList === "Rolespage" ? "activeList" : ""
                      }
                      onClick={clearRc}
                      id="listDashboard"
                      component={NavLink}
                      to={Routepath.RolesPage}
                      style={(e) => StyleSet(e, "Rolespage")}
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        paddingTop: "8px",
                        px: 2.5,
                      }}
                    >
                      <Tooltip title={open ? "" : "Rolespage"}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            paddingRight: "8px",

                            margin: 0,
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "24px",
                              color:
                                styleList === "Rolespage" ? "white" : "black",
                            }}
                          >
                            <ManageAccountsIcon />
                          </span>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        className="listText"
                        primary="Roles"
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItem>
                  ) : null}
                </Box>
              </List>
            </Box>
          </Drawer>
          <IconButton
            id="iconFill"
            onClick={() => setOpen(!open)}
            edge="start"
            sx={{
              borderRadius: "50%",
              position: "relative",
              top: "14px",
              zIndex: "1",
              transform: open
                ? "translate(225px,75px)"
                : "translate(57px,77px)",
              marginLeft: open ? "-47px" : "-12px",
            }}
          >
            <PlayCircleOutlineIcon
              id={open && "rotate_circle"}
              sx={{ color: "black" }}
            />
          </IconButton>
        </Grid>
      </Grid>
      <Paper
        id={open ? "mainContainer" : "minSidebarContainer"}
        elevation={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: open ? "74%" : "90vw",
          minHeight: "90vh",
          justifyContent: "flex-start",
          width: open ? "calc(100% + 235px)" : "calc(100% + 65px)",
          padding: !open ? "14px 0 0 65px" : "14px 0 0 200px",
        }}
      >
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </Paper>
    </Box>
  );
};

export default LeftnavLinks;
