import React, { useEffect } from "react";
import useMount from "./useMount";
import { useDispatch, useSelector } from "react-redux";
import { clearScreenId, setscreenId } from "../reduxApp/fieldStore";
import { noop } from "lodash";

export const useScreenId = (id, cb = noop) => {
  const dispatch = useDispatch();
  const screenId = useSelector((state) => state.dropedField);

  useMount(() => {
    dispatch(setscreenId(id));
    return () => {
      clearScreenId();
    };
  });

  useEffect(() => {
    if (screenId.screenId) {
      cb();
    }
  }, [screenId.screenId]);
};
