import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Grid } from "@mui/material";
import dayjs from "dayjs";

const DateField = (props) => {
  const {
    defaultValue,
    Action,
    id,
    fieldName,
    onChange,
    errorText,
    name,
    isRequired,
    display,
    minDate,
    maxDate,
  } = props;

  return (
    <>
      {display && (
        <Box id={name}>
          <Grid
            container
            sx={{
              lineHeight: "3.5",
              height: "56px",
              alignItems: "center",
              // columnGap: "30px",
              marginBottom: "5px",
              fontWeight: "600",
              wrap: "noWrap",

              fontSize: "14px",
            }}
          >
            <Box
              sx={{
                color: "var(--Black, var(--Text, #000))",
                textAlign: "right",
                fontSize: "14px",
                padding: "20px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                width: "195px",
                height: "56px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              className={isRequired && "mandatoryField"}
            >
              {fieldName}
            </Box>

            {Action === "View" ? (
              <DatePicker
                InputProps={{
                  style: {
                    borderColor: "rgba(0,0,0,0.9)",
                  },
                }}
                sx={{
                  backgroundColor: "#f2f2f2",

                  pointerEvents: "none",
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    color: "grey ",
                  },
                }}
                id="outlined-disabled"
                view="day, month, year"
                variant="outlined"
                className="defaultFieldStruc"
                value={dayjs(defaultValue)}
                slotProps={{
                  textField: {
                    error: false,
                  },
                }}
              ></DatePicker>
            ) : (
              <>
                <DatePicker
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                  sx={{
                    color: "black",
                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                      borderColor: "blue !important", // Replace with your desired color
                    },
                  }}
                  //  error={errorText}
                  //  helperText={errorText}
                  name={name}
                  //id={errorText ? "outlined-error-helper-text" : "outlined-basic"}

                  id="outlined-basic !important"
                  variant="outlined !important"
                  onChange={(e) => {
                    if (dayjs(e).isValid()) {
                      onChange(e, name);
                    }
                  }}
                  maxDate={maxDate}
                  minDate={minDate}
                  className="defaultFieldStruc"
                  defaultValue={defaultValue ? dayjs(defaultValue || "") : null}
                ></DatePicker>
              </>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default DateField;
