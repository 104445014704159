import React from "react";
import { useSelector } from "react-redux";
import { getAuthorization } from "../selectors/authSelectors";
import { includes, find } from "lodash";

export default function useAuth() {
  const sessionIds = useSelector(getAuthorization);
  // const isAuthenticated =
  const getAccess = (screenId) => {
    const { pgAccessMode } =
      find(sessionIds?.screensDetails, (acs) => acs.screenId === screenId) ??
      {};
    return {
      // canRead: acesss[screenId] === 'RW' ?? false,
      canReadWrite: includes(pgAccessMode, "RW") ?? true,
      noAccess: includes(pgAccessMode, "NA") ?? true,
    };
  };

  return {
    getAccess,
  };
}
