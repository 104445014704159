import React, { useState, useEffect } from "react";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { makeStyles } from "@mui/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import CANADAflag from "../../assets/Images/canada.png";
import usflag from "../../assets/Images/us.png";
import indiaflag from "../../assets/Images/india.png";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import { Routepath } from "../../routing/routpath";
import { useNavigate, useLocation } from "react-router";
import {
  database_List,
  renderCandidateStatus,
} from "../../constance/DashboardJson";
import TextFields from "../../CommonContainer/TextField";
import { Dropdown } from "../../CommonContainer/DropDown";
import DateField from "../../CommonContainer/DateField";
import TwoTextFields from "../../CommonContainer/TwoTextField";
import { dashboard_api } from "../../constance/DashboardJson";
import { setTypeOfResource } from "../../reduxApp/fieldStore";
import {
  getLookup,
  postRateCalc,
  getVmsDataByPagesandRows,
  updateCandidateById,
  submitMyrecord,
  calculateData,
} from "../../services/apiRequests";
import {
  updateCandidateDetails,
  setDashboardChild,
  setMatchedLookupobj,
} from "../../reduxApp/fieldStore";
import { useDispatch, useSelector } from "react-redux";
import {
  calculate_Errormsg,
  dashboard_Errormsg_submit,
  errorMsg,
} from "../../constance/validityItems";
import { isValid } from "../../utils/validationField";
import { dashboard_Errormsg } from "../../constance/validityItems";
import { groupLookup } from "../../utils/helpers";
import { map, isEmpty, find } from "lodash";
import useLoader from "../../Loader/useLoader";
import { MarkUpTable } from "../../CommonContainer/MarkUpTable";
import { styled } from "@mui/material/styles";
import { PayBenefits } from "../../CommonContainer/PayBenefits";
import { B2BIMariginLimits } from "../../CommonContainer/B2BIMariginLimits";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useScreenId } from "../../hooks/useScreenId";
import toast from "react-hot-toast";
import CircleIcon from "@mui/icons-material/Circle";

const SquareRadio = styled(Radio)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    borderRadius: "0%",
    width: "20px",
    height: "20px",
    marginLeft: "3px",
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    borderRadius: "0%", // Remove border radius to keep it square when checked
    width: "20px",
    height: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  RateCalculator_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",

    justifyContent: "center",
  },
  usFlag: {
    width: "40px",
    height: "24px",
    background: `url(${usflag})`,
    backgroundColor: "lightgray",
    backgroundPosition: "-2.977px -4.849px",
    backgroundSize: "112.59% 140.42%",
    backgroundRepeat: "no-repeat",
  },
  india: {
    width: "40px",
    height: "24px",
    background: `url(${indiaflag})`,
    backgroundColor: "lightgray",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  CANADAflag: {
    width: "40px",
    height: "24px",
    background: `url(${CANADAflag})`,
    backgroundColor: "lightgray",
    backgroundPosition: "-2.977px -4.849px",
    backgroundSize: "112.59% 140.42%",
    backgroundRepeat: "no-repeat",
  },
  flags: {
    alignItems: "center",

    maxWidth: "478px",
    padding: "10px 4px 50px 0px",

    justifyContent: "space-between",
  },
  allFlag: {
    display: "flex",
    gap: "11px",
  },
  RateCalculator_Form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "741px",
    padding: "30px 0px 59px 0px",
    gap: "10px",
  },
  Field: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    gap: "30px",
    justifyContent: "flex-end",
  },
  align: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "40px",
    margin: "53px 24px 24px 24px",
    // margin: "27px 24px 32px",
    width: "100%",
  },
  text: {
    width: "478px",
  },
  label: {
    display: "flex",
    alignItems: "flex-end",
    width: "230px",
    justifyContent: "flex-end",
  },
  month: {
    width: "100px",
  },
  commission: {
    display: "flex",
    width: "478px",
    gap: "8px",
  },
  button: {
    display: "flex",

    justifyContent: "flex-end",
    width: "372px",
    padding: "20px",
    alignItems: "flex-start",
    gap: "20px",
  },
  clear: {
    borderRadius: "4px",
    border: "1px solid  #6C6C6C",
    background: "var(--Secon-2-L-White, #F7F7F7)",
    boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
    height: "40px",
    padding: "0px 8px",
    "&:hover": {
      border: "1px solid  #6C6C6C",
      background: "var(--Secon-2-L-White, #F7F7F7)",
      boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
    },
  },
  save: {},
}));

const RateCalculator = () => {
  const dispatch = useDispatch();
  const { getAccess } = useAuth();
  const { noAccess: totalComoer24 } = getAccess("10");
  const { noAccess: reMntlyCmsPH } = getAccess("11");
  const { noAccess: tcmnsPH } = getAccess("12");
  const { noAccess: hrlyRate } = getAccess("13");
  const { noAccess: Boarding } = getAccess("15");
  const { noAccess: termination } = getAccess("16");
  const { noAccess: calcCountry } = getAccess("17");
  const [bordingDate, setBordingDate] = useState();
  const [validate, setValidate] = useState(false);

  const visibleAccess = {
    recuiterMonthlyCommissionPerHour: totalComoer24,
    totalCommissionForMonthPro: reMntlyCmsPH,
    recruiterMonthlyCommissionPro: tcmnsPH,
    hourlyRate: hrlyRate,
    onboardingDt: Boarding,
    terminationDt: termination,
    calculationCountry: calcCountry,
  };

  const Navigate = useNavigate();
  const candidateList = useSelector((state) => state.dropedField);
  const [holidayToggle, setHoliddayToggle] = useState(false);
  const [holidayDataToggle, setHolidayDataTggle] = useState({});
  const [wholeRawlookup, setWholeRawlookup] = useState([]);
  const authRedux = useSelector((state) => state.auth);
  const [calculatedOnce, setCalculatorOnce] = useState(false);
  const [alignment, setAlignment] = useState("USA");
  const [dataChanged, setDataChanged] = useState();
  const [lookupGroup, setLookupGroup] = useState({});
  const [showLoader, hideLoader] = useLoader();
  const [calCountry, setCalcCountry] = useState();
  const [candidateDetails, setCandidateDetails] = useState({
    ...candidateList.rateCalciData,
  });

  const findFullform = (shortHand) => {
    const status = lookupGroup["CANDIDATE_STATUS"];
    const longHand = find(status, (key) => key.value === shortHand);
    return longHand?.label;
  };

  const [VMSFee, setVmsFee] = useState([]);
  const [errorText, setErrorText] = useState({});
  const [vmsForBu, setvmsForBu] = useState();

  const fetchLookup = async () => {
    try {
      showLoader();
      const res = await getLookup();
      const vmsCompany = (await getVmsDataByPagesandRows(0, 1000)).data;
      const extractedData = map(
        vmsCompany.content,
        ({ customerNameWithFee, feePercent, vmsId, customerId }) => ({
          label: customerNameWithFee,
          value: vmsId,
        })
      );

      const BuforVms = vmsCompany.content.reduce((acc, current) => {
        if (acc[current.bussinessUnit]) {
          acc[current.bussinessUnit].push({
            label: current.customerNameWithFee,
            value: current.vmsId,
            markup: current.allowedMarkupPct,
          });
        } else {
          acc[current.bussinessUnit] = [
            {
              label: current.customerNameWithFee,
              value: current.vmsId,
              markup: current.allowedMarkupPct,
            },
          ];
        }
        return acc;
      }, {});

      setvmsForBu(BuforVms);
      const vmsArray = { VMS: extractedData };

      if (res.status === 200) {
        setWholeRawlookup(res.data);
        const groupedLookup = groupLookup(res.data);
        const vmsLookup = groupLookup(extractedData);
        setLookupGroup(groupedLookup);
        convertToLables(
          vmsArray.VMS,
          groupedLookup,
          candidateList.rateCalciData
        );
        if (candidateList.rateCalciData.hasOwnProperty("candidateDetailId")) {
          setCalculatorOnce(true);
          setDataChanged(true);
        }

        const vmsFor = candidateList.rateCalciData.hasOwnProperty(
          "candidateDetailId"
        )
          ? BuforVms[candidateList.rateCalciData.candidateBusinessUnit]
            ? BuforVms[candidateList.rateCalciData.candidateBusinessUnit]
            : []
          : extractedData;
        setVmsFee({ VMS: vmsFor });

        // setVmsFee(vmsArray);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const minDate = (value) => {
    if (value === "terminationDt") {
      return bordingDate;
    }
  };

  const setMydivName = (name, divName) => {
    let myDivName = "";

    if (candidateDetails.candidateCountry === "CAD") {
      switch (name) {
        case "recuiterMonthlyCommission": {
          myDivName = "Recruiter Monthly Commission(CAD)";
          break;
        }
        case "totalCommissionFor24Months": {
          myDivName = "Total Commission for # months (CAD)";
          break;
        }
        default:
          myDivName = divName;
      }
    } else myDivName = divName;

    return myDivName;
  };
  function convertToLables(VMSFee, lookupGroup, objectJson) {
    const tempData = { ...objectJson };
    const recruiterSplit = authRedux.token.userName.split(" ");
    tempData.recuiterFirstName = recruiterSplit[0];
    tempData.recuiterLastName = recruiterSplit[1];
    if (tempData.hasOwnProperty("candidateDetailId")) {
      dispatch(setMatchedLookupobj(tempData));
      setCandidateDetails(tempData);
    }
  }

  useScreenId(
    2,
    () => {
      fetchLookup();
    },
    []
  );

  const SubmitEvent = async () => {
    try {
      let targetDiv = "";
      let isAllValid = true;
      let errorList = { ...dashboard_Errormsg };
      setValidate(true);
      for (let identity in errorList) {
        if (
          !(
            [
              "vacationNoOfDays",
              "sickLeaveNoOfDays",
              "holidays",
              "candidateInsurenceType",
              "refferalAmount",
              "immgirationType",
              "otherExpenses",
              "otherExpensesHourly",
            ].includes(identity) &&
            candidateDetails.candidateTypeOfResource == "C2C"
          )
        ) {
          if (
            !(
              [
                "candidateInsurenceType",
                "holidays",
                "immgirationType",
                "otherExpensesHourly",

                "vacationNoOfDays",
              ].includes(identity) &&
              candidateDetails.candidateCountry === "CAD"
            )
          ) {
            let isValidRes = isValid(
              identity,
              candidateDetails[identity],
              candidateDetails
            ); //returns errors list withrespected error msg
            errorList[identity] = isValidRes;

            if (isValidRes) {
              targetDiv = targetDiv === "" ? identity : targetDiv;
              isAllValid = false;
            }
          }
        }
      }
      if (isAllValid) {
        // isAllValid = true;
        let tempData = {
          ...candidateDetails,
        };

        tempData.calculationCountry =
          candidateList.loginData.roleCd === "RCTR" ||
          candidateList.loginData.roleCd === "ACCMNGR" ||
          candidateList.loginData.country === "India"
            ? candidateList.loginData.country
            : tempData.calculationCountry;

        // if (tempData.candidateCountry === "CANADA")
        //   tempData.provinceState = pairWithLabels(
        //     lookupGroup.PROVINCES_STRY_HLDY,
        //     tempData.provinceState
        //   );
        tempData.candidateBusinessUnit =
          candidateDetails.candidateTypeOfResource == "C2C"
            ? tempData.candidateBusinessUnit
              ? tempData.candidateBusinessUnit
              : null
            : tempData.candidateBusinessUnit;

        // tempData.preId = pairWithLabels(lookupGroup.YESNO, tempData.preId);
        tempData.holidays =
          candidateDetails.candidateTypeOfResource == "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? tempData.holidays
              ? tempData.holidays
              : "NO"
            : tempData.holidays;

        tempData.candidateInsurenceType =
          candidateDetails.candidateTypeOfResource == "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.candidateInsurenceType
              ? tempData.candidateInsurenceType
              : "NON"
            : tempData.candidateInsurenceType;

        // if (candidateDetails.leaveStateCd) {
        //   tempData.leaveStateCd = pairWithLabels(
        //     lookupGroup.STATE,
        //     tempData.leaveStateCd
        //   );
        // }
        tempData.immgirationType =
          candidateDetails.candidateTypeOfResource == "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.immgirationType
              ? tempData.immgirationType
              : "NO"
            : tempData.immgirationType;

        tempData.vacationNoOfDays =
          candidateDetails.candidateTypeOfResource === "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.vacationNoOfDays &&
              candidateDetails.vacationNoOfDays !== "" &&
              candidateDetails.vacationNoOfDays >= 0
              ? parseInt(candidateDetails.vacationNoOfDays)
              : 0
            : candidateDetails.vacationNoOfDays !== ""
            ? candidateDetails.vacationNoOfDays
            : 0;

        tempData.sickLeaveNoOfDays =
          candidateDetails.candidateTypeOfResource === "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.sickLeaveNoOfDays &&
              candidateDetails.sickLeaveNoOfDays !== "" &&
              candidateDetails.sickLeaveNoOfDays >= 0
              ? parseInt(candidateDetails.sickLeaveNoOfDays)
              : 0
            : candidateDetails.sickLeaveNoOfDays !== ""
            ? candidateDetails.sickLeaveNoOfDays
            : 0;

        tempData.otherExpenses =
          candidateDetails.candidateTypeOfResource === "C2C"
            ? candidateDetails.otherExpenses &&
              candidateDetails.otherExpenses !== "" &&
              candidateDetails.otherExpenses >= 0
              ? parseInt(candidateDetails.otherExpenses)
              : parseInt("0")
            : candidateDetails.otherExpenses !== ""
            ? candidateDetails.otherExpenses
            : parseInt("0");

        tempData.otherExpensesHourly =
          candidateDetails.candidateTypeOfResource === "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.otherExpensesHourly &&
              candidateDetails.otherExpensesHourly !== "" &&
              candidateDetails.otherExpensesHourly >= 0
              ? parseInt(candidateDetails.otherExpensesHourly)
              : 0
            : candidateDetails.otherExpensesHourly !== ""
            ? candidateDetails.otherExpensesHourly
            : 0;

        tempData.refferalAmount =
          candidateDetails.candidateTypeOfResource === "C2C"
            ? candidateDetails.refferalAmount &&
              candidateDetails.refferalAmount !== "" &&
              candidateDetails.refferalAmount >= 0
              ? parseInt(candidateDetails.refferalAmount)
              : 0
            : candidateDetails.refferalAmount !== ""
            ? candidateDetails.refferalAmount
            : 0;
        tempData.perDiemCount =
          candidateDetails.perDiemCount !== ""
            ? candidateDetails.perDiemCount
            : 0;

        tempData["recruiterCountry"] =
          candidateList.loginData.roleCd === "RCTR" ||
          candidateList.loginData.roleCd === "ACCMNGR" ||
          candidateList.loginData.country === "India"
            ? candidateList.loginData.country
            : "";

        tempData["loginUserId"] = candidateList.loginData.id;
        showLoader();

        if (
          !candidateDetails.hasOwnProperty("candidateDetailId") ||
          candidateDetails.candidateDetailId === null
        ) {
          setCalculatorOnce(true);
          await postRateCalc(tempData).then((res) => {
            dispatch(updateCandidateDetails(res.data));
            dispatch(
              setDashboardChild({
                id: res.data.candidateDetailId,
                userAction: "EditedRc",
                userName: res.data.candidateName,
              })
            );
            convertToLables(VMSFee.VMS, lookupGroup, res.data);
          });
        } else {
          await updateCandidateById(tempData.candidateDetailId, tempData).then(
            (res) => {
              dispatch(updateCandidateDetails(res.data));
              setCandidateDetails(res.data);
              dispatch(
                setDashboardChild({
                  id: res.data.candidateDetailId,
                  userAction: "EditedRc",
                  userName: res.data.candidateName,
                })
              );
              convertToLables(VMSFee.VMS, lookupGroup, res.data);
            }
          );
          // const activitylogModel = {
          //   applicationId: candidateDetails.applicationId,
          //   screenId: 2,
          //   actionType: "Edit",
          // };
          // await activityDataSave(activitylogModel);
          // setMarkupVisible(true);
        }

        setErrorText({});
      } else if (!isAllValid) {
        let scrollGetElement = document.getElementById(targetDiv);

        scrollGetElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setErrorText(errorList);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      hideLoader();
    }
  };

  const saveData = () => {
    toast.custom((t) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          position: "absolute",
          top: "50%",
          borderRadius: "8px",
          background: "#fff",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div style={{ marginBottom: "8px" }}>
          <strong>Are you sure you want to save this candidate?</strong>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <button
            style={{
              background: "#4CAF50",
              color: "white",
              padding: "8px 12px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              toast.dismiss(t.id);
              SubmitEvent();
            }}
          >
            Yes
          </button>
          <button
            style={{
              background: "#f44336",
              color: "white",
              padding: "8px 12px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              toast.dismiss(t.id); // Close toast
            }}
          >
            No
          </button>
        </div>
      </div>
    ));
  };

  const calculate = async () => {
    try {
      let targetDiv = "";
      let isAllValid = true;
      let errorList = { ...calculate_Errormsg };
      for (let identity in errorList) {
        if (
          !(
            [
              "vacationNoOfDays",
              "sickLeaveNoOfDays",
              "holidays",
              "candidateInsurenceType",
              "refferalAmount",
              "immgirationType",
              "otherExpenses",
              "otherExpensesHourly",
            ].includes(identity) &&
            candidateDetails.candidateTypeOfResource == "C2C"
          )
        ) {
          if (
            !(
              [
                "candidateInsurenceType",
                "holidays",
                "immgirationType",
                "otherExpensesHourly",

                "vacationNoOfDays",
              ].includes(identity) &&
              candidateDetails.candidateCountry === "CAD"
            )
          ) {
            let isValidRes = isValid(
              identity,
              candidateDetails[identity],
              candidateDetails
            ); //returns errors list withrespected error msg
            errorList[identity] = isValidRes;

            if (isValidRes) {
              targetDiv = targetDiv === "" ? identity : targetDiv;
              isAllValid = false;
            }
          }
        }
      }
      if (isAllValid) {
        let tempData = {
          ...candidateDetails,
        };
        tempData.calculationCountry =
          candidateList.loginData.roleCd === "RCTR" ||
          candidateList.loginData.roleCd === "ACCMNGR" ||
          candidateList.loginData.country === "India"
            ? candidateList.loginData.country
            : tempData.calculationCountry;

        tempData.candidateBusinessUnit =
          candidateDetails.candidateTypeOfResource == "C2C"
            ? tempData.candidateBusinessUnit
              ? tempData.candidateBusinessUnit
              : null
            : tempData.candidateBusinessUnit;

        tempData.holidays =
          candidateDetails.candidateTypeOfResource == "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? tempData.holidays
              ? tempData.holidays
              : "NO"
            : tempData.holidays;

        tempData.candidateInsurenceType =
          candidateDetails.candidateTypeOfResource == "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.candidateInsurenceType
              ? tempData.candidateInsurenceType
              : "NON"
            : tempData.candidateInsurenceType;

        tempData.immgirationType =
          candidateDetails.candidateTypeOfResource == "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.immgirationType
              ? tempData.immgirationType
              : "NO"
            : tempData.immgirationType;

        tempData.vacationNoOfDays =
          candidateDetails.candidateTypeOfResource === "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.vacationNoOfDays &&
              candidateDetails.vacationNoOfDays !== "" &&
              candidateDetails.vacationNoOfDays >= 0
              ? parseInt(candidateDetails.vacationNoOfDays)
              : 0
            : candidateDetails.vacationNoOfDays !== ""
            ? candidateDetails.vacationNoOfDays
            : 0;

        tempData.sickLeaveNoOfDays =
          candidateDetails.candidateTypeOfResource === "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.sickLeaveNoOfDays &&
              candidateDetails.sickLeaveNoOfDays !== "" &&
              candidateDetails.sickLeaveNoOfDays >= 0
              ? parseInt(candidateDetails.sickLeaveNoOfDays)
              : 0
            : candidateDetails.sickLeaveNoOfDays !== ""
            ? candidateDetails.sickLeaveNoOfDays
            : 0;

        tempData.otherExpenses =
          candidateDetails.candidateTypeOfResource === "C2C"
            ? candidateDetails.otherExpenses &&
              candidateDetails.otherExpenses !== "" &&
              candidateDetails.otherExpenses >= 0
              ? parseInt(candidateDetails.otherExpenses)
              : parseInt("0")
            : candidateDetails.otherExpenses !== ""
            ? candidateDetails.otherExpenses
            : parseInt("0");

        tempData.otherExpensesHourly =
          candidateDetails.candidateTypeOfResource === "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.otherExpensesHourly &&
              candidateDetails.otherExpensesHourly !== "" &&
              candidateDetails.otherExpensesHourly >= 0
              ? parseInt(candidateDetails.otherExpensesHourly)
              : 0
            : candidateDetails.otherExpensesHourly !== ""
            ? candidateDetails.otherExpensesHourly
            : 0;

        tempData.refferalAmount =
          candidateDetails.candidateTypeOfResource === "C2C"
            ? candidateDetails.refferalAmount &&
              candidateDetails.refferalAmount !== "" &&
              candidateDetails.refferalAmount >= 0
              ? parseInt(candidateDetails.refferalAmount)
              : 0
            : candidateDetails.refferalAmount !== ""
            ? candidateDetails.refferalAmount
            : 0;
        tempData.perDiemCount =
          candidateDetails.perDiemCount !== ""
            ? candidateDetails.perDiemCount
            : 0;

        tempData["recruiterCountry"] =
          candidateList.loginData.roleCd === "RCTR" ||
          candidateList.loginData.roleCd === "ACCMNGR" ||
          candidateList.loginData.country === "India"
            ? candidateList.loginData.country
            : "";

        tempData["loginUserId"] = candidateList.loginData.id;
        showLoader();

        let response;
        // if (!candidateDetails.hasOwnProperty("candidateDetailId")) {
        setCalculatorOnce(true);
        await calculateData(tempData).then((res) => {
          dispatch(updateCandidateDetails(res.data));
          dispatch(
            setDashboardChild({
              id: res.data.candidateDetailId,
              userAction: "EditedRc",
              userName: res.data.candidateName,
            })
          );
          convertToLables(VMSFee.VMS, lookupGroup, res.data);
        });
        // } else {
        //   await updateCandidateById(tempData.candidateDetailId, tempData).then(
        //     (res) => {
        //       dispatch(updateCandidateDetails(res.data));
        //       setCandidateDetails(res.data);
        //       dispatch(
        //         setDashboardChild({
        //           id: res.data.candidateDetailId,
        //           userAction: "EditedRc",
        //           userName: res.data.candidateName,
        //         })
        //       );
        //       convertToLables(VMSFee.VMS, lookupGroup, res.data);
        //     }
        //   );
        // const activitylogModel = {
        //   applicationId: candidateDetails.applicationId,
        //   screenId: 2,
        //   actionType: "Edit",
        // };
        // await activityDataSave(activitylogModel);
        // setMarkupVisible(true);
        // }

        const responseData = response.data;
        setErrorText({});
      } else if (!isAllValid) {
        let scrollGetElement = document.getElementById(targetDiv);

        scrollGetElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setErrorText(errorList);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const toCheckValidityByTypeofResource = (name) => {
    return (
      [
        "vacationNoOfDays",
        "sickLeaveNoOfDays",
        "holidays",
        "candidateInsurenceType",
        "refferalAmount",
        "immgirationType",
        "otherExpenses",
        "otherExpensesHourly",
      ].includes(name) && candidateDetails.candidateTypeOfResource === "C2C"
    );
  };

  const toCheckValidityByCalculate = (name) => {
    return (
      [
        "candidateName",
        "candidateJobTitle",
        "candidateStartDt",
        "jobId",
        "leaveStateCd",
        "provinceState",
      ].includes(name) && validate === false
    );
  };

  const finalSubmit = async () => {
    try {
      let targetDiv = "";

      let isAllValid = true;

      let errorList = { ...dashboard_Errormsg_submit };
      for (let identity in errorList) {
        if (
          !(
            [
              "vacationNoOfDays",
              "sickLeaveNoOfDays",
              "holidays",
              "candidateInsurenceType",
              "refferalAmount",
              "immgirationType",
              "otherExpenses",
              "otherExpensesHourly",
            ].includes(identity) &&
            candidateDetails.candidateTypeOfResource == "C2C"
          )
        ) {
          let isValidRes = isValid(
            identity,
            candidateDetails[identity],
            candidateDetails
          ); //returns errors list withrespected error msg
          errorList[identity] = isValidRes;

          if (isValidRes) {
            targetDiv = targetDiv === "" ? identity : targetDiv;
            isAllValid = false;
          }
        }
      }

      //  isAllValid = true;
      if (isAllValid) {
        let tempData = {
          ...candidateDetails,
        };

        // tempData.vmsFee = pairWithLabels(VMSFee.VMS, tempData.vmsFee);
        // if (tempData.candidateCountry === "CANADA")
        //   tempData.provinceState = pairWithLabels(
        //     lookupGroup.PROVINCES_STRY_HLDY,
        //     tempData.provinceState
        //   );
        // tempData.candidateCountry = pairWithLabels(
        //   lookupGroup.COUNTRY,
        //   tempData.candidateCountry
        // );
        tempData.calculationCountry =
          candidateList.loginData.roleCd === "RCTR" ||
          candidateList.loginData.roleCd === "ACCMNGR" ||
          candidateList.loginData.country === "India"
            ? candidateList.loginData.country
            : tempData.calculationCountry;

        tempData.candidateBusinessUnit =
          candidateDetails.candidateTypeOfResource == "C2C"
            ? tempData.candidateBusinessUnit
              ? tempData.candidateBusinessUnit
              : null
            : tempData.candidateBusinessUnit;

        // tempData.preId = pairWithLabels(lookupGroup.YESNO, tempData.preId);

        tempData.holidays =
          candidateDetails.candidateTypeOfResource == "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? tempData.holidays
              ? tempData.holidays
              : "NO"
            : tempData.holidays;

        tempData.candidateInsurenceType =
          candidateDetails.candidateTypeOfResource == "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.candidateInsurenceType
              ? tempData.candidateInsurenceType
              : "NON"
            : tempData.candidateInsurenceType;

        tempData.immgirationType =
          candidateDetails.candidateTypeOfResource == "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.immgirationType
              ? tempData.immgirationType
              : "NO"
            : tempData.immgirationType;

        tempData.vacationNoOfDays =
          candidateDetails.candidateTypeOfResource === "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.vacationNoOfDays &&
              candidateDetails.vacationNoOfDays !== "" &&
              candidateDetails.vacationNoOfDays >= 0
              ? parseInt(candidateDetails.vacationNoOfDays)
              : 0
            : candidateDetails.vacationNoOfDays !== ""
            ? candidateDetails.vacationNoOfDays
            : 0;

        tempData.sickLeaveNoOfDays =
          candidateDetails.candidateTypeOfResource === "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.sickLeaveNoOfDays &&
              candidateDetails.sickLeaveNoOfDays !== "" &&
              candidateDetails.sickLeaveNoOfDays >= 0
              ? parseInt(candidateDetails.sickLeaveNoOfDays)
              : 0
            : candidateDetails.sickLeaveNoOfDays !== ""
            ? candidateDetails.sickLeaveNoOfDays
            : 0;

        tempData.otherExpenses =
          candidateDetails.candidateTypeOfResource === "C2C"
            ? candidateDetails.otherExpenses &&
              candidateDetails.otherExpenses !== "" &&
              candidateDetails.otherExpenses >= 0
              ? parseInt(candidateDetails.otherExpenses)
              : parseInt("0")
            : candidateDetails.otherExpenses !== ""
            ? candidateDetails.otherExpenses
            : parseInt("0");

        tempData.otherExpensesHourly =
          candidateDetails.candidateTypeOfResource === "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.otherExpensesHourly &&
              candidateDetails.otherExpensesHourly !== "" &&
              candidateDetails.otherExpensesHourly >= 0
              ? parseInt(candidateDetails.otherExpensesHourly)
              : 0
            : candidateDetails.otherExpensesHourly !== ""
            ? candidateDetails.otherExpensesHourly
            : 0;

        tempData.refferalAmount =
          candidateDetails.candidateTypeOfResource === "C2C"
            ? candidateDetails.refferalAmount &&
              candidateDetails.refferalAmount !== "" &&
              candidateDetails.refferalAmount >= 0
              ? parseInt(candidateDetails.refferalAmount)
              : 0
            : candidateDetails.refferalAmount !== ""
            ? candidateDetails.refferalAmount
            : 0;
        tempData.perDiemCount =
          candidateDetails.perDiemCount !== ""
            ? candidateDetails.perDiemCount
            : 0;

        tempData["recruiterCountry"] = candidateList.loginData.country;
        tempData["loginUserId"] = candidateList.loginData.id;
        showLoader();
        if (tempData.candidateDetailId != null) {
          const response = await submitMyrecord(
            tempData.candidateDetailId,
            tempData
          );
          if (response.status === 200) {
            Navigate(Routepath.DashboardTabsAll);
            dispatch(
              setDashboardChild({
                id: "",
                userAction: "",
                userName: "",
              })
            );
            dispatch(setMatchedLookupobj({}));
            setErrorText({});
          }
        } else {
          toast.error("Please click save button before submit");
        }
      } else if (!isAllValid) {
        let scrollGetElement = document.getElementById(targetDiv);

        scrollGetElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setErrorText(errorList);
      }
    } catch (error) {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
  };
  const classes = useStyles();
  const locataion = useLocation();
  const userDatas = locataion.state;

  function HandleChange(e, name, Vmsvalue) {
    if (name === "calculationCountry") {
      setCalcCountry(e);
    }
    if (name === "onboardingDt") {
      setBordingDate(e);
    }
    setDataChanged(true);
    let wholeFields = {
      ...candidateDetails,
      [name]: name === "candidateStartDt" ? dayjs(e).format("YYYY-MM-DD") : e,
    };
    errorText.hasOwnProperty(name) &&
      name !== "candidateStartDt" &&
      setErrorText({
        ...errorText,
        [name]: e === "" ? errorMsg[name] : "",
      });
    if (name === "candidateTypeOfResource") {
      if (e === "C2C") {
        wholeFields["vacationNoOfDays"] = 0;
        wholeFields["otherExpenses"] = 0;
        wholeFields["otherExpensesHourly"] = 0;
      } else {
        wholeFields["vacationNoOfDays"] = "";
        wholeFields["otherExpenses"] = "";
        wholeFields["otherExpensesHourly"] = "";
      }
    }
    if (name === "immgirationType") {
      if (e !== "4000") {
        wholeFields["tnVisaPremiumYN"] = null;
        wholeFields["tnVisaCountry"] = null;
      }
      wholeFields[name] = e;
    }
    if (name === "candidateCountry") {
      wholeFields["candidateBusinessUnit"] = "";
      candidateDetails.candidateTypeOfResource != "C2C"
        ? (wholeFields["candidateTypeOfResource"] = "")
        : (wholeFields["candidateTypeOfResource"] =
            candidateDetails.candidateTypeOfResource);
    }

    if (name === "leaveStateCd" && e && candidateDetails.leaveStateCd !== e) {
      setHoliddayToggle(true);
      wholeFields.leaveStateCd = e;
      const constFindObj = wholeRawlookup.find((obj) => obj.lookupCd === e);
      setHolidayDataTggle(constFindObj);
    }
    if (name === "provinceState" && e) {
      const defineStatotoryLeave = lookupGroup["PROVINCES_STRY_HLDY"].find(
        (key) => e === key.value
      );
      const defineSickLeave = lookupGroup["PROVINCES_STRY_SICK"].find(
        (key) => e === key.value
      );

      wholeFields["sickLeaveNoOfDays"] = parseInt(
        defineSickLeave?.leaveValue ?? " "
      );
      wholeFields["statutoryHolidays"] = parseInt(
        defineStatotoryLeave?.leaveValue ?? " "
      );
    }

    if (name === "vmsFee") {
      const getMarkup = vmsForBu[candidateDetails.candidateBusinessUnit]?.find(
        (obj) => obj.value === e
      );
      const markupValue = getMarkup?.markup;
      wholeFields["allowedMarkupPct"] = markupValue
        ? markupValue === "NA"
          ? "No Markup"
          : markupValue
        : "--";
    }

    if (name === "candidateBusinessUnit") {
      const vmsFor = vmsForBu[e] ? vmsForBu[e] : [];
      wholeFields.vmsFee = "";
      wholeFields["allowedMarkupPct"] = "";
      setVmsFee({ VMS: vmsFor });
    }
    if (name === "candidateCountry") {
      setAlignment(e);
    }

    setCandidateDetails(wholeFields);
    if (name === "candidateTypeOfResource") setTypeOfResource(e);
  }

  const createCheckbox = () => {
    return (
      <>
        <RadioGroup
          id="tnVisaCountry"
          row
          name="tnVisaCountry"
          value={candidateDetails.tnVisaCountry}
          onChange={(e) => {
            setCandidateDetails({
              ...candidateDetails,
              tnVisaCountry: e.target.value,
            });
            setErrorText({ ...errorText, tnVisaCountry: "" });
          }}
          aria-labelledby="demo-row-radio-buttons-group-label"
        >
          <FormControlLabel
            label="Canada"
            value="CA"
            control={
              <Checkbox
                checked={candidateDetails.tnVisaCountry === "CA"}
                onChange={(e) => {
                  setCandidateDetails({
                    ...candidateDetails,
                    tnVisaCountry: e.target.value,
                  });
                  setErrorText({ ...errorText, tnVisaCountry: "" });
                }}
              />
            }
          />
          <FormControlLabel
            label="Mexico"
            value="MX"
            control={
              <Checkbox
                checked={candidateDetails.tnVisaCountry === "MX"}
                onChange={(e) => {
                  setCandidateDetails({
                    ...candidateDetails,
                    tnVisaCountry: e.target.value,
                  });
                  setErrorText({ ...errorText, tnVisaCountry: "" });
                }}
              />
            }
          />
        </RadioGroup>
        <p style={{ color: "#d32f2f", fontSize: "0.8rem" }}>
          {errorText?.tnVisaCountry ?? ""}
        </p>
        {candidateDetails.tnVisaCountry && (
          <>
            <RadioGroup
              id="tnVisaPremiumYN"
              row
              onChange={(e) => {
                setCandidateDetails({
                  ...candidateDetails,
                  tnVisaPremiumYN: e.target.value,
                });
                setErrorText({ ...errorText, tnVisaPremiumYN: "" });
              }}
              value={candidateDetails.tnVisaPremiumYN}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="tnVisaPremiumYN"
            >
              <FormControlLabel
                label="Consulate"
                value="NO"
                control={<SquareRadio />}
              />
              <FormControlLabel
                label="Petition"
                value="YES"
                control={<SquareRadio />}
              />
            </RadioGroup>
            <p style={{ color: "#d32f2f", fontSize: "0.8rem" }}>
              {errorText?.tnVisaPremiumYN ?? ""}
            </p>
          </>
        )}
      </>
    );
  };

  const ClearEvent = () => {
    let originalPayloard = { ...dashboard_api };
    if (candidateDetails.hasOwnProperty("candidateDetailId")) {
      originalPayloard = {
        ...originalPayloard,
        candidateDetailId: candidateDetails.candidateDetailId,
        recuiterFirstName: candidateDetails.recuiterFirstName,
        recuiterLastName: candidateDetails.recuiterLastName,
        applicationId: candidateDetails.applicationId,
      };
    }
    setCandidateDetails(originalPayloard);
  };

  const displayRules = (name) => {
    let BooleanResult = true;
    if (["Allowance"].includes(name)) {
      candidateDetails.candidateCountry === "CAD"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (["Total Commission for # months"].includes(name)) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (name === "leaveStateCd") {
      candidateDetails.candidateCountry === "USA"
        ? (BooleanResult = true)
        : (BooleanResult = false);
    }
    if (
      [
        "provinceState",
        "statutoryHolidays",
        "vacationPayHourly",
        "vacationPayAnnual",
        "optionalHolidays",
      ].includes(name)
    ) {
      candidateDetails.candidateCountry === "CAD"
        ? (BooleanResult = true)
        : (BooleanResult = false);
    }
    if (
      [
        "vacationNoOfDays",
        "candidateInsurenceType",
        "holidays",
        "immgirationType",
        "otherExpensesHourly",
        "totalCommissionForMonthPro",
        "recruiterMonthlyCommissionPro",
        "hourlyRate",
        "perDiemCount",
        "perDiem",
        "payRatePerDiem",
        // "grossMarginPercentage",
        "recuiterMonthlyCommissionPerHour",
      ].includes(name)
    ) {
      candidateDetails.candidateCountry === "CAD"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (
      ["totalCommissionForMonthPro", "recruiterMonthlyCommissionPro"].includes(
        name
      )
    ) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (["calculationCountry"].includes(name)) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (["recMnthlyCommissionBonus"].includes(name)) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = true)
        : (BooleanResult = false);
    }
    if (
      [
        "ttlMonthlyCommissionUSDVal",
        "recruiterMonthlyCommissionUSDVal",
      ].includes(name)
    ) {
      if (
        candidateDetails.candidateCountry === "CAD" &&
        candidateList.loginData.country === "USA"
      ) {
        BooleanResult = true;
      } else BooleanResult = false;
    }
    if (
      [
        "ttlMonthlyCommissionUSDVal",
        "recruiterMonthlyCommissionUSDVal",
      ].includes(name)
    ) {
      if (
        candidateDetails.candidateCountry === "CAD" &&
        candidateList.loginData.country === "India"
      ) {
        BooleanResult = false;
      }
    }
    if (
      ["recruiterMonthlyCommission", "recruiterMonthlyCommission"].includes(
        name
      )
    ) {
      if (
        candidateDetails.candidateCountry == "CAD" &&
        candidateList.loginData.country == "Canada"
      ) {
        BooleanResult = true;
      } else BooleanResult = false;
    }
    return BooleanResult;
  };

  const chooseDropdoenOptions = (type) => {
    let filteredOption = lookupGroup[type];
    if (type === "BUSINESSUNIT") {
      if (candidateDetails.candidateCountry === "CAD") {
        const filteredOptions = lookupGroup["BUSINESSUNIT"].filter((key) =>
          ["Canada", "BU07", "BU01"].includes(key.value)
        );
        filteredOption = filteredOptions;
      } else {
        const filteredOptions = lookupGroup["BUSINESSUNIT"].filter(
          (key) => !["Canada"].includes(key.value)
        );
        filteredOption = filteredOptions;
      }
    }
    if (type === "RESOURCETYPE") {
      if (candidateDetails.candidateCountry === "CAD") {
        const filteredOptions = lookupGroup["RESOURCETYPE"].filter(
          (key) => key.value === "T4" || key.value === "C2C"
        );

        filteredOption = filteredOptions;
      } else {
        const filteredOptions = lookupGroup["RESOURCETYPE"].filter(
          (key) => key.value !== "T4"
        );

        filteredOption = filteredOptions;
      }
    }
    return filteredOption;
  };

  return (
    <>
      {candidateDetails.applicationId && (
        <Grid
          container
          sx={{
            background: "#44A0E3",
            p: 1,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            position: "sticky",
            overflow: "auto",
            right: "10px",
            top: "100px",
            zIndex: 1,
          }}
        >
          <Grid item sx={{ display: "flex" }} xs={3}>
            <Typography variant="h7" sx={{ color: "#FFF" }}>
              Application Id:
            </Typography>
            <Typography variant="h7" sx={{ color: "#FFF" }}>
              {candidateDetails.applicationId}
            </Typography>
          </Grid>
          <Grid item sx={{ display: "flex" }} xs={3}>
            <Typography variant="h7" sx={{ color: "#FFF" }}>
              Candidate Name:
            </Typography>
            <Typography variant="h7" sx={{ color: "#FFF" }}>
              {candidateDetails.candidateName}
            </Typography>
          </Grid>
          <Grid item sx={{ display: "flex" }} xs={3}>
            <Typography variant="h7" sx={{ color: "#FFF" }}>
              Recruiter Name:
            </Typography>
            <Typography variant="h7" sx={{ color: "#FFF" }}>
              {candidateDetails.recuiterFirstName}{" "}
              {candidateDetails.recuiterLastName}
            </Typography>
          </Grid>
          <Grid item sx={{ display: "flex", gap: "2px" }} xs={3}>
            <Typography variant="h7" sx={{ color: "#FFF" }}>
              Candidate Status:
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CircleIcon
                sx={{
                  color:
                    renderCandidateStatus?.[
                      findFullform(candidateDetails?.candidateStatus)
                    ] ?? "white",
                  width: "15px",
                }}
              />
              <Typography variant="h7" sx={{ color: "#FFF" }}>
                {findFullform(candidateDetails?.candidateStatus)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",

          minHeight: "90vh",
          paddingTop: "3%",
          alignItems: "top",
          gap: "130px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            width: "600px",
            //  marginLeft: "-10%",
            rowGap: "20px",
            padding: "0px 0px 19px 0px",
          }}
        >
          {!isEmpty(lookupGroup) ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "75%",
                  justifyContent: "center",
                }}
              >
                <ToggleButtonGroup
                  sx={{
                    backgroundColor: "#EEEEEE",
                    borderRadius: "15px ",
                    border: "solid 0.5px #616161",
                  }}
                  value={alignment}
                  exclusive
                  //  onChange={handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton
                    value="USA"
                    // selectedColor="rgba(0, 0, 0, 0)"
                    sx={{
                      display: "flex",
                      gap: "10px",
                      textTransform: "capitalize",
                      "&.Mui-selected, &.Mui-selected:hover": {
                        backgroundColor: "#616161",
                      },
                      borderRadius: "14px",
                      backgroundColor: "white",
                    }}
                  >
                    <div className={classes.usFlag}></div>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "black",
                        // fontSize: alignment === 'USA' ? 15 : 10,
                        fontWeight: alignment === "USA" ? "bold" : 500,
                        color: alignment === "USA" ? "#FFFF" : "#6c6c6c",
                      }}
                    >
                      United State
                    </Typography>
                  </ToggleButton>

                  {/* <ToggleButton
              value="India"
              sx={{
                display: "flex",
                gap: "10px",
                textTransform: "capitalize",
                "&.Mui-selected, &.Mui-selected:hover": {
                  color: "white",
                  backgroundColor: "white",
                },
                borderRight: "none",
                borderLeft: "none",
                borderRadius: "15px",
              }}
            >
              <div className={classes.india}></div>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: 15,
                  fontWeight: "bold",
                  color: "black",
                  // backgroundColor: alignment === 'India' ? '#FFFFFF' : '#FFFFF',
                }}
              >
                India
              </Typography>
            </ToggleButton> */}

                  <ToggleButton
                    value="CAD"
                    sx={{
                      display: "flex",
                      gap: "10px",
                      textTransform: "capitalize",
                      "&.Mui-selected, &.Mui-selected:hover": {
                        color: "#FFFF",
                        backgroundColor: "#616161",
                      },
                      borderRadius: "14px",
                      outline: alignment === "CAD" ? "solid 1px gray" : "none",
                      backgroundColor: "white",
                    }}
                  >
                    <div className={classes.CANADAflag}></div>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        // fontSize: alignment === 'CANADA' ? 15 : 15,
                        fontWeight: alignment === "CAD" ? "bold" : 500,
                        color: "black",
                        color: alignment === "CAD" ? "#FFFF" : "#6c6c6c",

                        // backgroundColor: alignment === 'CANADA' ? '#FFFFFF' : '#FFFFF',
                      }}
                    >
                      Canada
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {database_List.map((item, key) => {
                if (item.field === "Textfield") {
                  return (
                    <TextFields
                      displayRule={displayRules(item.name)}
                      currency={
                        [
                          // "recruiterMonthlyCommissionPro",
                          // "recuiterMonthlyCommissionPerHour",
                          // "totalCommissionForMonthPro",
                          // "hourlyRate",
                          "recMnthlyCommissionBonus",
                          "recuiterMonthlyCommission",
                        ].includes(item.name)
                          ? candidateList.loginData.roleCd != "RCTR" ||
                            candidateList.loginData.roleCd != "ACCMNGR"
                            ? calCountry === "India"
                              ? "IND"
                              : calCountry === "USA"
                              ? "USA"
                              : candidateList.loginData.country === "India"
                              ? "IND"
                              : candidateList.loginData.country === "USA"
                              ? "USA"
                              : "CAD"
                            : ""
                          : "USA"
                      }
                      corticonField={item?.corticonField ?? false}
                      defaultValue={candidateDetails[item.name]}
                      isRequired={
                        toCheckValidityByCalculate(item.name) === true
                          ? false
                          : toCheckValidityByTypeofResource(item.name) === true
                          ? false
                          : item?.mandatory ?? false
                      }
                      id={`C${key}`}
                      names={item.name}
                      display={
                        [
                          "recuiterMonthlyCommissionPerHour",
                          "totalCommissionForMonthPro",
                          "recruiterMonthlyCommissionPro",
                          "hourlyRate",
                        ].includes(item.name)
                          ? !visibleAccess[item.name] &&
                            candidateDetails.candidateCountry != "CAD"
                          : true
                      }
                      onChange={HandleChange}
                      errorText={
                        toCheckValidityByTypeofResource(item.name) === true
                          ? ""
                          : errorText.hasOwnProperty(item.name)
                          ? errorText[item.name]
                          : ""
                      }
                      fieldName={setMydivName(item.name, item.divName)}
                      colorind={candidateDetails.colorInd}
                      isAccess={candidateDetails.isAccess}
                    />
                  );
                } else if (item.field === "dropDown") {
                  return (
                    <Dropdown
                      displayRule={displayRules(item.name)}
                      onChange={HandleChange}
                      corticonField={item?.corticonField ?? false}
                      isRequired={
                        toCheckValidityByTypeofResource(item.name) === true
                          ? false
                          : item?.mandatory ?? false
                      }
                      defaultVlues={item.defaultVlues}
                      childCheckbox={
                        item.name === "immgirationType" &&
                        candidateDetails["immgirationType"] === "4000"
                      }
                      child={createCheckbox()}
                      id={`C${key}`}
                      action="Edit"
                      defaultValue={candidateDetails[item.name]}
                      fieldName={item.divName}
                      name={item.name}
                      errorText={
                        toCheckValidityByTypeofResource(item.name) === true
                          ? ""
                          : errorText.hasOwnProperty(item.name)
                          ? errorText[item.name]
                          : ""
                      }
                      options={
                        item.lookupType === "VMS"
                          ? VMSFee.VMS
                          : chooseDropdoenOptions(item.lookupType)
                      }
                      display={
                        ["calculationCountry"].includes(item.name)
                          ? !visibleAccess[item.name]
                          : true
                      }
                    />
                  );
                } else if (item.field === "Datefield") {
                  return (
                    <DateField
                      onChange={HandleChange}
                      corticonField={item?.corticonField ?? false}
                      defaultValue={candidateDetails[item.date]}
                      isRequired={item?.mandatory ?? false}
                      id={`C${key}`}
                      errorText={
                        errorText[item.name] ? errorText[item.name] : ""
                      }
                      option={item}
                      fieldName={item.divName}
                      name={item.name}
                      minDate={
                        ["onboardingDt", "terminationDt"].includes(item.name)
                          ? dayjs(candidateDetails?.candidateStartDt)
                          : null
                      }
                      display={
                        ["onboardingDt", "terminationDt"].includes(item.name)
                          ? !visibleAccess[item.name]
                          : true
                      }
                    />
                  );
                } else if (item.field === "TwoText") {
                  return (
                    <TwoTextFields
                      onChange={HandleChange}
                      displayRule={
                        displayRules(item.divName)
                        // candidateDetails.candidateCountry === "CANADA"
                        //   ? item.divName === "Allowance"
                        //     ? false
                        //     : true
                        //   : candidateList.loginData.country === "India"
                        //   ? item.divName === "Total Commission for # months"
                        //     ? false
                        //     : true
                        //   : true
                      }
                      FistcorticonField={item?.FistcorticonField ?? false}
                      LastcorticonField={item?.LastcorticonField ?? false}
                      country={calCountry ?? candidateList.loginData.country}
                      isRequired={false}
                      id={`C${key}`}
                      errorTextFirst={
                        errorText[item.fName] ? errorText[item.fName] : ""
                      }
                      errorTextLast={errorText[item.lName]}
                      fieldName={item.divName}
                      RFname={candidateDetails[item.fName]}
                      RLname={candidateDetails[item.lName]}
                      fName={item.fName}
                      lName={item.lName}
                    />
                  );
                }
              })}

              <div className={classes.button} style={{ padding: "0px" }}>
                <Button
                  variant="outlined"
                  onClick={ClearEvent}
                  sx={{
                    height: "40px",
                    padding: "0px 10px",
                    color: "black",
                    textTransform: "capitalize",
                    borderRadius: "4px",
                    border: "1px solid var(--Prim-1-N-L-Black, #6C6C6C)",
                    "&:hover": {
                      border: "1px solid  #6C6C6C",
                      background: "var(--Secon-2-L-White, #F7F7F7)",
                      boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
                    },
                    background: "var(--Secon-2-L-White, #F7F7F7)",
                    boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  Clear
                </Button>
                <Button
                  variant="outlined"
                  onClick={calculate}
                  sx={{
                    height: "40px",
                    padding: "0px 10px",
                    color: "black",
                    textTransform: "capitalize",
                    borderRadius: "4px",
                    border: "1px solid var(--Prim-1-N-L-Black, #6C6C6C)",
                    "&:hover": {
                      border: "1px solid  #6C6C6C",
                      background: "var(--Secon-2-L-White, #F7F7F7)",
                      boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
                    },
                    background: "var(--Secon-2-L-White, #F7F7F7)",
                    boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  Calculate
                </Button>
                <Button
                  variant="contained"
                  className={classes.save}
                  style={{
                    //  color: !dataChanged && "#44a0e3 -50%",
                    color: !dataChanged && "grey",
                    pointerEvents: !dataChanged ? "none" : "auto",
                    backgroundColor: !dataChanged ? "gainsboro" : "#44a0e3",
                  }}
                  sx={{
                    textTransform: "capitalize",
                    height: "40px",
                    padding: "0px 20px",
                    borderRadius: "4px",
                    "&:hover": {
                      background: "var(--Secon-1-D-Blue, #44A0E3)",
                    },
                    background: "var(--Secon-1-D-Blue, #44A0E3)",
                  }}
                  onClick={saveData}
                >
                  Save
                </Button>
                {calculatedOnce &&
                  candidateDetails.hasOwnProperty("candidateDetailId") && (
                    <Button
                      variant="contained"
                      className={classes.save}
                      sx={{
                        height: "40px",
                        padding: "0px 20px",
                        textTransform: "capitalize",
                        borderRadius: "4px",
                        "&:hover": {
                          background: "var(--Secon-1-D-Blue, #44A0E3)",
                        },
                        background: "var(--Secon-1-D-Blue, #44A0E3)",
                      }}
                      onClick={finalSubmit}
                    >
                      Submit
                    </Button>
                  )}
              </div>
            </>
          ) : (
            <div style={{ alignItems: "center" }}> </div>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            position: "sticky",
            height: "96vh",
            overflow: "auto",
            right: "10px",
            top: "100px",
          }}
        >
          <Box sx={{ border: "1px solid gray", borderRadius: " 0 0 4px 4px" }}>
            <Accordion
              sx={{
                display:
                  candidateDetails?.rtMarkUpModel == null ? "none" : "block",

                // paddingTop: "30px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ background: "#a3a3a39c" }}
              >
                <Typography sx={{ fontWeight: "600", color: "black" }}>
                  Mark Up List
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: "flex" }}>
                  <MarkUpTable value={candidateDetails?.rtMarkUpModel} />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                display:
                  candidateDetails?.prFinalRateModel == null ? "none" : "block",
                // paddingTop: "30px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ background: "#a3a3a39c" }}
              >
                <Typography sx={{ fontWeight: "600", color: "black" }}>
                  Pay Benefits
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: "flex" }}>
                  <PayBenefits value={candidateDetails?.prFinalRateModel} />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                display:
                  candidateDetails?.rtMarkUpModel == null ? "none" : "block",
                // paddingTop: "30px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ background: "#a3a3a39c", borderRadius: " 0 0 4px 4px" }}
              >
                <Typography sx={{ fontWeight: "600", color: "black" }}>
                  B2BI Margin Limit
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!isEmpty(lookupGroup) && (
                  <div style={{ display: "flex" }}>
                    <B2BIMariginLimits value={lookupGroup} />
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
      {holidayToggle && (
        <Dialog open={holidayToggle}>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "1rem",
                borderRadius: "0.2rem",
                border: "1px solid grey",
              }}
            >
              <WarningAmberRoundedIcon
                sx={{ width: "5rem", height: "5rem", color: "#FF6D00" }}
              />
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                }}
              >
                Alert
              </Typography>
              <Typography sx={{ marginTop: "0.5rem" }}>
                There is mandatory paid leave in this state{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {holidayDataToggle?.lookupLabel ?? ""}
                </span>
                . Please add{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {holidayDataToggle?.lookupVal ?? ""}{" "}
                </span>
                days in the vacation field
              </Typography>
              <Button
                sx={{ width: "6rem", marginTop: "1rem" }}
                variant="contained"
                onClick={() => setHoliddayToggle(false)}
              >
                Ok
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default RateCalculator;
