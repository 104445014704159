import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import { Routepath } from "../../../../routing/routpath";
import TextFields from "../../../../CommonContainer/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Dropdown } from "../../../../CommonContainer/DropDown";
import DateField from "../../../../CommonContainer/DateField";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { groupLookup } from "../../../../utils/helpers";
import TwoTextFields from "../../../../CommonContainer/TwoTextField";
import {
  getLookup,
  PostComment,
  submitAeNotification,
} from "../../../../services/apiRequests";
import {
  getVmsDataByPagesandRows,
  getCandidateById,
} from "../../../../services/apiRequests";
import useAuth from "../../../../hooks/useAuth";
import { dashboard_Errormsg_submit } from "../../../../constance/validityItems";
import {
  dashboard_api,
  renderCandidateStatus,
} from "../../../../constance/DashboardJson";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty, find } from "lodash";
import dayjs from "dayjs";
import { database_List } from "../../../../constance/DashboardJson";
import CircleIcon from "@mui/icons-material/Circle";
import { errorMsg } from "../../../../constance/validityItems";
import {
  clearScreenId,
  setNotificationDetails,
  setscreenId,
} from "../../../../reduxApp/fieldStore";
import useLoader from "../../../../Loader/useLoader";
import NumberTextFields from "../../../../CommonContainer/numberTextField";
import {
  Box,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Icon,
} from "@mui/material";
import { Textarea } from "@mui/joy";
import { MarkUpTable } from "../../../../CommonContainer/MarkUpTable";
import { B2BIMariginLimits } from "../../../../CommonContainer/B2BIMariginLimits";
import { PayBenefits } from "../../../../CommonContainer/PayBenefits";
import toast from "react-hot-toast";
import useMount from "../../../../hooks/useMount";

const OnBoarding = () => {
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState({});
  const [candidateDetails, setCandidateDetails] = useState({});
  const candidateList = useSelector((state) => state.dropedField);
  const employIntegration = useSelector((state) => {
    return state.dropedField;
  });
  const { getAccess } = useAuth();
  const { noAccess: totalComoer24 } = getAccess("10");
  const { noAccess: reMntlyCmsPH } = getAccess("11");
  const { noAccess: tcmnsPH } = getAccess("12");
  const { noAccess: hrlyRate } = getAccess("13");
  const { noAccess: Boarding } = getAccess("15");
  const { noAccess: termination } = getAccess("16");
  const { noAccess: calcCountry } = getAccess("17");
  const [calCountry, setCalcCountry] = useState();
  const [vmsForBu, setvmsForBu] = useState();
  const [lookupGroup, setLookupGroup] = useState({});
  const [VMSFee, setVmsFee] = useState([]);
  const [apiIntegrated, setApiIntegrated] = useState(false);
  const dispatch = useDispatch();
  const [showLoader, hideLoader] = useLoader();
  const [approved, setApproved] = useState(false);
  const [returned, setReturned] = useState(false);
  const visibleAccess = {
    recuiterMonthlyCommissionPerHour: totalComoer24,
    totalCommissionForMonthPro: reMntlyCmsPH,
    recruiterMonthlyCommissionPro: tcmnsPH,
    hourlyRate: hrlyRate,
    onboardingDt: Boarding,
    terminationDt: termination,
    calculationCountry: calcCountry,
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const findFullform = (shortHand) => {
    const status = lookupGroup["CANDIDATE_STATUS"];
    const longHand = find(status, (key) => key.value === shortHand);
    return longHand?.label;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseReturn = () => {
    setReturned(false);
  };
  const handleCloseApp = () => {
    setApproved(false);
  };

  const denide = () => {
    saveAndpost("Denied");
  };
  const returndata = () => {
    saveAndpost("Returned");
  };

  useMount(() => {
    dispatch(setscreenId(6));
    return () => {
      clearScreenId();
    };
  });
  useEffect(
    () => {
      getNotificationTables();
    },
    [],
    [apiIntegrated]
  );

  const getNotificationTables = async () => {
    try {
      showLoader();
      const response = await getCandidateById(
        "getbycandidateid",
        employIntegration.AeNotification.id
      );
      const res = await getLookup();
      const vmsCompany = (await getVmsDataByPagesandRows(0, 1000)).data;
      const extractedData = map(
        vmsCompany.content,
        ({ customerNameWithFee, feePercent, vmsId }) => ({
          label: customerNameWithFee,
          value: vmsId,
          // id: vmsId,
        })
      );
      const BuforVms = vmsCompany.content.reduce((acc, current) => {
        if (acc[current.bussinessUnit]) {
          acc[current.bussinessUnit].push({
            label: current.customerNameWithFee,
            value: current.vmsId,
            markup: current.allowedMarkupPct,
          });
        } else {
          acc[current.bussinessUnit] = [
            {
              label: current.customerNameWithFee,
              value: current.vmsId,
              markup: current.allowedMarkupPct,
            },
          ];
        }
        return acc;
      }, {});

      setvmsForBu(BuforVms);
      const vmsArray = { VMS: extractedData };
      if (res.status === 200) {
        const groupedLookup = groupLookup(res.data);
        const vmsLookup = groupLookup(extractedData);
        setLookupGroup(groupedLookup);
        const vmsFor = response.hasOwnProperty("candidateDetailId")
          ? BuforVms[response.candidateBusinessUnit]
            ? BuforVms[response.candidateBusinessUnit]
            : []
          : extractedData;
        setVmsFee({ VMS: vmsFor });
        // setVmsFee(vmsArray);
        matchWithValues(response.data, groupedLookup, vmsArray);
      }
    } catch (error) {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
  };

  const checkMyvalidity = () => {
    let isAllValid = true;
    let targetDiv = "";
    let errorList = { ...dashboard_Errormsg_submit, comments: "" };
    if (!candidateDetails.comments) {
      targetDiv = "comments";
      isAllValid = false;
      errorList = { comments: "Please add comments" };
    }
    return {
      isAllValid: isAllValid,
      targetDiv: targetDiv,
      errorList: errorList,
    };
  };

  const saveComment = async (data) => {
    try {
      showLoader();
      const payload = {
        commentDesc: data.comments,
        applicationId: data.applicationId,
      };
      const commentRes = await PostComment(payload);

      if (commentRes.status === 200) {
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const saveAndpost = async (userActions) => {
    try {
      showLoader();

      let tempData = {
        ...candidateDetails,
      };

      tempData["country"] = employIntegration.loginData.country;
      tempData["loginUserId"] = employIntegration.loginData.id;
      const { isAllValid, targetDiv, errorList } = checkMyvalidity();

      if (isAllValid) {
        tempData.candidateStatus = userActions;

        const res = await submitAeNotification(
          candidateDetails.candidateDetailId,
          tempData
        );
        if (res.status === 200) {
          if (userActions === "Onborded") {
            // handleOpenApp();
            toast.success("The Candidate has been Onborded");
            // setApproved(true);
          }
          saveComment(tempData);
          setApiIntegrated(!apiIntegrated); //settttt notification,check changes
          setBackTodashboard();
        }

        setCandidateDetails({ ...dashboard_api });

        setErrorText({});
      } else if (!isAllValid) {
        let scrollGetElement = document.getElementById(targetDiv);
        scrollGetElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setErrorText(errorList);
        handleClose();
      }
    } catch (error) {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
  };

  function setBackTodashboard() {
    setCandidateDetails({ ...dashboard_api });
    navigate(Routepath.AeNotification);
    dispatch(
      setNotificationDetails({
        Event: "",
        id: "",
      })
    );
  }

  function matchWithValues(tempData, lookupGroup, extractedData) {
    tempData["country"] = employIntegration.loginData.country;
    tempData["recuriterId"] = employIntegration.loginData.id;
    setCandidateDetails({ ...tempData, comments: "" });
  }

  useMemo(() => {
    setCalcCountry(candidateDetails.calculationCountry);
  }, [candidateDetails]);

  function HandleChange(e, name, Vmsvalue) {
    if (name === "calculationCountry") {
      setCalcCountry(e);
    }
    let wholeFields = {
      ...candidateDetails,
      [name]: name === "candidateStartDt" ? dayjs(e).format("YYYY-MM-DD") : e,
      [name]: name === "comments" ? e : e,
    };
    errorText.hasOwnProperty(name) &&
      name !== "candidateStartDt" &&
      setErrorText({
        ...errorText,
        [name]: e === "" ? errorMsg[name] : "",
      });
    if (name === "vmsFee" && candidateDetails.candidateBusinessUnit) {
      const getMarkup = vmsForBu[candidateDetails.candidateBusinessUnit].find(
        (obj) => obj.label === e
      );
      const markupValue = getMarkup?.markup;
      wholeFields["allowedMarkupPct"] = markupValue
        ? markupValue === "NA"
          ? "No Markup"
          : markupValue
        : "--";
    }

    if (name === "candidateBusinessUnit") {
      const vmsFor = vmsForBu[e] ? vmsForBu[e] : [];
      wholeFields.vmsFee = "";
      wholeFields["allowedMarkupPct"] = "";
      setVmsFee({ VMS: vmsFor });
    }
    setCandidateDetails(wholeFields);
    // if (name === "candidateCountry") {
    //   setAlignment(e.target.value);
    // }
  }

  const displayRules = (name) => {
    let BooleanResult = true;

    if (["Allowance"].includes(name)) {
      candidateDetails.candidateCountry === "CAD"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (["Total Commission for # months"].includes(name)) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }

    if (["calculationCountry"].includes(name)) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (["recMnthlyCommissionBonus"].includes(name)) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = true)
        : (BooleanResult = false);
    }
    if (
      [
        "provinceState",
        "statutoryHolidays",
        "vacationPayHourly",
        "vacationPayAnnual",
        "optionalHolidays",
        "ttlMonthlyCommissionUSDVal",
        "recruiterMonthlyCommissionUSDVal",
      ].includes(name)
    ) {
      candidateDetails.candidateCountry === "CAD"
        ? (BooleanResult = true)
        : (BooleanResult = false);
    }
    if (
      ["totalCommissionForMonthPro", "recruiterMonthlyCommissionPro"].includes(
        name
      )
    ) {
      candidateList.loginData.country == "India"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (
      [
        "ttlMonthlyCommissionUSDVal",
        "recruiterMonthlyCommissionUSDVal",
      ].includes(name)
    ) {
      if (
        candidateDetails.candidateCountry === "CAD" &&
        candidateList.loginData.country === "USA"
      ) {
        BooleanResult = true;
      } else BooleanResult = false;
    }
    if (
      [
        "ttlMonthlyCommissionUSDVal",
        "recruiterMonthlyCommissionUSDVal",
      ].includes(name)
    ) {
      if (
        candidateDetails.candidateCountry === "CAD" &&
        candidateList.loginData.country === "India"
      ) {
        BooleanResult = false;
      }
    }
    if (
      [
        "vacationNoOfDays",
        "candidateInsurenceType",
        "holidays",
        "immgirationType",
        "otherExpensesHourly",
        "totalCommissionForMonthPro",
        "recruiterMonthlyCommissionPro",
        "hourlyRate",
        "perDiemCount",

        "perDiem",
        "payRatePerDiem",
        // "grossMarginPercentage",
        "recruiterMonthlyCommission",
        "recuiterMonthlyCommissionPerHour",
      ].includes(name)
    ) {
      candidateDetails.candidateCountry === "CAD"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    return BooleanResult;
  };
  const setMydivName = (name, divName) => {
    let myDivName = "";
    if (candidateDetails.candidateCountry === "CAD") {
      switch (name) {
        case "recuiterMonthlyCommission": {
          myDivName = "Recruiter Monthly Commission(CAD)";
          break;
        }
        case "totalCommissionFor24Months": {
          myDivName = "Total Commission for # months (CAD)";
          break;
        }
        default:
          myDivName = divName;
      }
    } else myDivName = divName;
    return myDivName;
  };
  const chooseDropdoenOptions = (type) => {
    let filteredOption = lookupGroup[type];
    if (type === "RESOURCETYPE") {
      if (candidateDetails.candidateCountry === "CAD") {
        const filteredOptions = lookupGroup["RESOURCETYPE"].filter(
          (key) => key.value === "T4"
        );

        filteredOption = filteredOptions;
      } else {
        const filteredOptions = lookupGroup["RESOURCETYPE"].filter(
          (key) => key.value !== "T4"
        );

        filteredOption = filteredOptions;
      }
    }
    return filteredOption;
  };

  return (
    <>
      <Grid
        container
        sx={{
          background: "#44A0E3",
          p: 1,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          position: "sticky",
          overflow: "auto",
          top: "50px",
          zIndex: 1,
        }}
      >
        <Grid item sx={{ display: "flex" }} xs={3}>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            Application Id:
          </Typography>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            {candidateDetails.applicationId}
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex" }} xs={3}>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            Candidate Name:
          </Typography>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            {candidateDetails.candidateName}
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex" }} xs={3}>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            Recruiter Name:
          </Typography>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            {candidateDetails.recuiterFirstName}{" "}
            {candidateDetails.recuiterLastName}
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex", gap: "2px" }} xs={3}>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            Candidate Status:
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CircleIcon
              sx={{
                color:
                  renderCandidateStatus?.[
                    findFullform(candidateDetails?.candidateStatus)
                  ] ?? "white",
                width: "15px",
              }}
            />
            <Typography variant="h7" sx={{ color: "#FFF" }}>
              {findFullform(candidateDetails?.candidateStatus)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
          minHeight: "90vh",
          paddingTop: "3%",
          alignItems: "top",
          //gap: "100px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            width: "630px",
            rowGap: "10px",
            padding: "0px 0px 19px 0px",
          }}
        >
          {!isEmpty(candidateDetails) && !isEmpty(lookupGroup) && (
            <>
              {database_List.map((item, key) => {
                if (item.field === "Textfield") {
                  return (
                    <TextFields
                      id={`C${key}`}
                      displayRule={displayRules(item.name)}
                      currency={
                        [
                          // "recruiterMonthlyCommissionPro",
                          // "recuiterMonthlyCommissionPerHour",
                          // "totalCommissionForMonthPro",
                          // "hourlyRate",
                          "recMnthlyCommissionBonus",
                          "recuiterMonthlyCommission",
                        ].includes(item.name)
                          ? candidateList.loginData.roleCd != "RCTR" ||
                            candidateList.loginData.roleCd != "ACCMNGR"
                            ? calCountry === "India"
                              ? "IND"
                              : calCountry === "USA"
                              ? "USA"
                              : candidateList.loginData.country === "India"
                              ? "IND"
                              : candidateList.loginData.country === "USA"
                              ? "USA"
                              : "CAD"
                            : ""
                          : "USA"
                      }
                      display={
                        // candidateDetails.recruiterCountry === "India"
                        //   ? [
                        //       "recuiterMonthlyCommissionPerHour",
                        //       "payRatePerDiem",
                        //       "blendedGrossMarginPercentage",
                        //       "recuiterMonthlyCommission",
                        //     ].includes(item.name)
                        //     ? false
                        //     : true
                        //   :
                        [
                          "recruiterMonthlyCommissionPro",
                          "recuiterMonthlyCommissionPerHour",
                          "totalCommissionForMonthPro",
                          "hourlyRate",
                        ].includes(item.name)
                          ? !visibleAccess[item.name]
                          : true
                      }
                      isRequired={item?.mandatory ?? false}
                      defaultValue={candidateDetails[item.name]}
                      onChange={HandleChange}
                      names={item.name}
                      defaultVlues={item.defaultVlues}
                      corticonField={true}
                      errorText={
                        errorText.hasOwnProperty(item.name)
                          ? errorText[item.name]
                          : ""
                      }
                      Action={employIntegration.Notification.Event}
                      fieldName={setMydivName(item.name, item.divName)}
                      colorind={candidateDetails.colorInd}
                    />
                  );
                } else if (item.field === "dropDown") {
                  return (
                    <Dropdown
                      displayRule={displayRules(item.name)}
                      defaultValue={candidateDetails[item.name]}
                      isRequired={item?.mandatory ?? false}
                      onChange={HandleChange}
                      id={`C${key}`}
                      defaultVlues={item.defaultVlues}
                      fieldName={item.divName}
                      name={item.name}
                      errorText={
                        errorText.hasOwnProperty(item.name)
                          ? errorText[item.name]
                          : ""
                      }
                      corticonField={item?.corticonField ?? false}
                      options={
                        item.lookupType === "VMS"
                          ? VMSFee[item.lookupType]
                          : chooseDropdoenOptions([item.lookupType])
                      }
                      action={"View"}
                      display={
                        ["calculationCountry"].includes(item.name)
                          ? !visibleAccess[item.name]
                          : true
                      }
                    ></Dropdown>
                  );
                } else if (item.field === "Datefield") {
                  return (
                    <DateField
                      defaultValue={candidateDetails[item.name]}
                      isRequired={item?.mandatory ?? false}
                      id={`C${key}`}
                      Action={employIntegration.AeNotification.Event}
                      onChange={HandleChange}
                      errorText={
                        errorText[item.name] ? errorText[item.name] : ""
                      }
                      option={item}
                      fieldName={item.divName}
                      name={item.name}
                      display={
                        ["onboardingDt", "terminationDt"].includes(item.name)
                          ? !visibleAccess[item.name]
                          : true
                      }
                      minDate={
                        ["onboardingDt", "terminationDt"].includes(item.name)
                          ? dayjs(candidateDetails?.candidateStartDt)
                          : null
                      }
                    ></DateField>
                  );
                } else if (item.field === "TwoText") {
                  return (
                    <TwoTextFields
                      displayRule={displayRules(item.divName)}
                      isRequired={
                        item.divName === "Recruiter Name" ? false : true
                      }
                      id={`C${key}`}
                      Action={"View"}
                      // defaultVlues={item.defaultVlues}
                      onChange={HandleChange}
                      country={calCountry ?? candidateList.loginData.country}
                      errorTextFirst={
                        errorText[item.fName] ? errorText[item.fName] : ""
                      }
                      errorTextLast={
                        errorText[item.lName] ? errorText[item.lName] : ""
                      }
                      // display={
                      //   candidateDetails.recruiterCountry === "India"
                      //     ? [
                      //         "Allowance",
                      //         "Total Commission for # months",
                      //       ].includes(item.divName)
                      //       ? false
                      //       : true
                      //     : true
                      // }
                      FistcorticonField={item?.FistcorticonField ?? false}
                      LastcorticonField={item?.LastcorticonField ?? false}
                      fieldName={item.divName}
                      RFname={candidateDetails[item.fName]}
                      RLname={candidateDetails[item.lName]}
                      fName={item.fName}
                      lName={item.lName}
                    />
                  );
                } else if (item.field === "numberField") {
                  return (
                    <NumberTextFields
                      corticonField={item?.corticonField ?? false}
                      defaultValue={candidateDetails[item.name]}
                      isRequired={item?.mandatory ?? false}
                      id={`C${key}`}
                      names={item.name}
                      onChange={HandleChange}
                      errorText={
                        errorText.hasOwnProperty(item.name)
                          ? errorText[item.name]
                          : ""
                      }
                      Action={employIntegration.Notification.Event}
                      fieldName={item.divName}
                      currency={candidateDetails.candidateCountry}
                    />
                  );
                }
              })}
              {/* {employIntegration.Notification.Event !== "View" && ( */}
              <Box id="comments">
                <Grid
                  container
                  sx={{
                    lineHeight: "3.5",
                    alignItems: "center",
                    marginBottom: "5px",
                    fontWeight: "600",
                    wrap: "noWrap",
                    fontSize: "14px",
                  }}
                >
                  <Box
                    sx={{
                      color: "var(--Black, var(--Text, #000))",
                      textAlign: "right",
                      fontSize: "14px",
                      padding: "20px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                      width: "195px",
                    }}
                    id={`c${database_List.length}`}
                  >
                    Comments<span style={{ color: "red" }}>*</span>
                  </Box>

                  <Textarea
                    className="defaultFieldStruc"
                    minRows={3}
                    placeholder={
                      errorText.hasOwnProperty("comments")
                        ? errorText.comments
                        : ""
                    }
                    error={
                      errorText.hasOwnProperty("comments")
                        ? errorText.comments
                        : ""
                    }
                    names="comments"
                    onChange={(e) => HandleChange(e.target.value, "comments")}
                  />
                </Grid>
              </Box>
              {/* )} */}
              <Box style={{ display: "flex", justifyContent: "end" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "170px",
                  }}
                >
                  <>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <Button
                        variant="contained"
                        className="btnLH declineBtn"
                        disabled={
                          employIntegration.notoficatioActionAcs === "RO"
                        }
                        onClick={
                          // saveAndpost("Denied")
                          handleClickOpen
                        }
                      >
                        <icon
                          style={{
                            marginRight: "6px",
                            verticalAlign: "bottom",
                          }}
                        >
                          <ThumbDownAltOutlinedIcon />
                        </icon>
                        Decline
                      </Button>
                      <Button
                        variant="contained"
                        className="btnLH acceptBtn"
                        disabled={
                          employIntegration.notoficatioActionAcs === "RO"
                        }
                        onClick={() => saveAndpost("Onborded")}
                      >
                        <Icon style={{ marginRight: "6px" }}>
                          <ThumbUpAltOutlinedIcon />
                        </Icon>
                        On Board
                      </Button>
                    </div>
                  </>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            position: "sticky",
            height: "96vh",
            overflow: "auto",
            right: "10px",
            top: "100px",
          }}
        >
          <Box sx={{ border: "1px solid gray", borderRadius: " 0 0 4px 4px" }}>
            <Accordion
              sx={{
                display:
                  candidateDetails?.rtMarkUpModel == null ? "none" : "block",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ background: "#a3a3a39c" }}
              >
                <Typography sx={{ fontWeight: "600", color: "black" }}>
                  Mark Up List
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: "flex" }}>
                  <MarkUpTable value={candidateDetails?.rtMarkUpModel} />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                display:
                  candidateDetails?.prFinalRateModel == null ? "none" : "block",
                // paddingTop: "30px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ background: "#a3a3a39c" }}
              >
                <Typography sx={{ fontWeight: "600", color: "black" }}>
                  Pay Benefits
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: "flex" }}>
                  <PayBenefits value={candidateDetails?.prFinalRateModel} />
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                display:
                  candidateDetails?.rtMarkUpModel == null ? "none" : "block",
                // paddingTop: "30px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ background: "#a3a3a39c", borderRadius: " 0 0 4px 4px" }}
              >
                <Typography sx={{ fontWeight: "600", color: "black" }}>
                  B2BI Margin Limit
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!isEmpty(lookupGroup) && (
                  <div style={{ display: "flex" }}>
                    <B2BIMariginLimits value={lookupGroup} />
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Dialog
          open={approved}
          onClose={handleCloseApp}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Approved Application"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The Application has been Approved
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Decline Application"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do You want to Decline this Application?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              No
            </Button>
            <Button onClick={denide}>Yes</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={returned}
          onClose={handleCloseReturn}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Return Application"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do You want to Return this Application?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseReturn} autoFocus>
              No
            </Button>
            <Button onClick={returndata}>Yes</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default OnBoarding;
