import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Box, Tooltip, Collapse, IconButton, Stack } from "@mui/material";
import {
  getCandidateById,
  getApiResponse,
  changeStatusInfo,
} from "../../../services/apiRequests";

import React, { useEffect, useState } from "react";
import {
  getDashboardbyPageandRows,
  getDashboardByNameOrder,
  getDashboardDataByNames,
  getDashboardByIdOrder,
  deleteDashboardItem,
  getLookup,
} from "../../../services/apiRequests";
import { updateCandidateDetails } from "../../../reduxApp/fieldStore";
import CommonContainer from "../../../CommonContainer/CommonContainer";
import { Outlet, useNavigate } from "react-router-dom";
import { Routepath } from "../../../routing/routpath";
import { dashboard_api } from "../../../constance/DashboardJson";
import { Grid } from "@mui/material";
import { styled } from "@mui/styles";
import CreatedataTable from "./CreatedataTable";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardChild, setscreenId } from "../../../reduxApp/fieldStore";
import useLoader from "../../../Loader/useLoader";
import useAuth from "../../../hooks/useAuth";
import { groupLookup } from "../../../utils/helpers";
import toast from "react-hot-toast";

const Dashboard = () => {
  const [openDelete, setOpenDelete] = useState({
    screenId: -1,
    openDelete: false,
  });
  const dispatch = useDispatch();
  const userAction = useSelector((state) => state.userAction);
  const { getAccess } = useAuth();
  const { noAccess: noAccessRC } = getAccess("2");
  // const { noAccess: noAccessBRtoPR } = getAccess("5");
  const [candidateList, setCandidateList] = useState([]);
  const [totalpages, setTotalPages] = useState(0);
  const [dataChanged, setDataChanged] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showLoader, hideLoader] = useLoader();
  const [lookupGroup, setLookupGroup] = useState();
  const [roleLookup, setRoleLookup] = useState();
  const [pageState, setPageState] = useState({
    page: 0,
    pageSize: 10,
  });

  const handlePagination = (pages) => {
    setPageState({
      page: pages.page,
      pageSize: pages.pageSize,
    });
  };
  // const getDashBoard = async () => {
  //   try {
  //     showLoader();
  //     await getApiResponse("getdashboarddata").then((res) => {
  //       if (res.status === 200) {
  //         setCandidateList(res.data.content);
  //         setTotalPages(res.data.totalElements);
  //       }
  //     });
  //   } catch (error) {
  //   } finally {
  //     hideLoader();
  //   }
  // };
  // useScreenId(1, () => {
  //   getDashBoard();
  // });

  const getDashBoardData = async () => {
    try {
      showLoader();
      if (openDelete.openDelete !== true) {
        await getDashboardbyPageandRows(
          pageState.page,
          pageState.pageSize
        ).then((res) => {
          if (res.status === 200) {
            setCandidateList(res.data.content);
            setTotalPages(res.data.totalElements);
          }
        });
        const res = await getLookup();
        if (res.status === 200) {
          const groupedLookup = groupLookup(res.data);
          const myLookup = groupedLookup["CANDIDATE_STATUS"];
          const myLookupRole = groupedLookup["ROLE"];
          setLookupGroup(myLookup);
          setRoleLookup(myLookupRole);
        }
      }
    } catch (error) {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getDashBoardData();
  }, [pageState, openDelete, userAction]);

  const navigate = useNavigate();
  const HandleFilter = async (value) => {
    try {
      showLoader();
      const dataList = await getDashboardDataByNames(value);
      if (dataList.status === 200) {
        if (!dataList.data.length) {
          alert(`No candidate with ${value} `);
        } else {
          setCandidateList(dataList.data);
        }
      }
    } catch (error) {
      toast.error("Server Error");
    }finally{
      hideLoader()
    }
  };
  const routeToEV = (userAction, id, component) => {
    dispatch(updateCandidateDetails({ ...dashboard_api }));
    dispatch(
      setDashboardChild({
        userAction: "Post",
        id: "",
        userName: "",
      })
    );
    navigate(Routepath.RateCalculatorTabView);
  };
  const sortToaccending = (title) => {
    if (title === "CANDIDATE NAME") {
      getDashboardByNameOrder(page, candidateList.length, "asc").then((res) =>
        setCandidateList(res.data.content)
      );
    } else if (title === "ID") {
      getDashboardByIdOrder(page, candidateList.length, "asc").then((res) =>
        setCandidateList(res.data.content)
      );
    }
  };
  const sortTodecending = (title) => {
    if (title === "CANDIDATE NAME") {
      getDashboardByNameOrder(page, candidateList.length, "decs").then((res) =>
        setCandidateList(res.data.content)
      );
    } else if (title === "ID") {
      getDashboardByIdOrder(page, candidateList.length, "decs").then((res) =>
        setCandidateList(res.data.content)
      );
    }
  };
  const viewMydetails = (userAction, id, userName) => {
    dispatch(setscreenId(3));
    dispatch(
      setDashboardChild({
        userAction: userAction,
        id: id,
        userName: userName,
      })
    );
    // if (userAction === "View") {
    //   navigate(Routepath.ViewMyDb);
    // } else {
    navigate(Routepath.RateCalculatorTabView);
    // }
  };

  const changeStatus = async (candidateId, appID, status) => {
    const payload = {
      candidateDetailId: candidateId,
      applicationId: appID,
      candidateStatus: status,
    };
    try {
      showLoader();
      const dataList = await changeStatusInfo(payload);
      if (dataList.status === 200) {
        getDashBoardData();
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const Title = styled(Box)({
    width: "100%",
    padding: "15px",
    fontWeight: "400",
    minHeight: "90vh",
    backgroundColor: "white",
  });

  return (
    <>
      <CommonContainer
        DisplayAddnew={true}
        DisplaySearch={true}
        handleChange={routeToEV}
        HandleFilter={HandleFilter}
        header="Rate Calculator Dashboard"
        addCalc="Add Calc"
      />
      <Title>
        <Grid direction={"column"} justifyContent={"center"}>
          <CreatedataTable
            rowsPerPage={rowsPerPage}
            sortToaccending={sortToaccending}
            sortTodecending={sortTodecending}
            lookupGroup={lookupGroup}
            setRowsPerPage={setRowsPerPage}
            candidateList={candidateList}
            handleAction={viewMydetails}
            changeStatus={changeStatus}
            totalpages={totalpages}
            openDelete={openDelete}
            setOpenDelete={setOpenDelete}
            handlePagination={handlePagination}
            pageState={pageState}
            roleLookup={roleLookup}
          />
        </Grid>
        <Outlet />
      </Title>
    </>
  );
};

export default Dashboard;
