import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import useLoader from "../../../../Loader/useLoader";
import toast from "react-hot-toast";
import { ApprovedData, getLookup } from "../../../../services/apiRequests";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { find } from "lodash";
import CircleIcon from "@mui/icons-material/Circle";
import { groupLookup } from "../../../../utils/helpers";

const AeNotificationTable = ({ pageState, setPageState, handleAction }) => {
  const navigate = useNavigate();
  const [showLoader, hideLoader] = useLoader();
  const [rowData, setRowData] = useState();
  const [pageCount, setPageCount] = useState(1);
  const dispatch = useDispatch();
  const [lookupGroup, setLookupGroup] = useState();

  const onPageChange = (pages) => {
    setPageState({
      page: pages.page,
      pageSize: pages.pageSize,
    });
  };

  const findFullform = (shortHand) => {
    const longHand = find(
      lookupGroup,
      (key) => key.value === shortHand.candidateStatus
    );
    return longHand?.label;
  };

  const renderCandidateStatus = (status) => {
    switch (status) {
      case "Pending":
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CircleIcon sx={{ color: "#F3C858", width: "15px" }} />
            <Typography variant="subtitle1">{status}</Typography>
          </Box>
        );
      case "Submited":
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CircleIcon sx={{ color: "#61B0E9", width: "15px" }} />
            <Typography variant="subtitle1">{status}</Typography>
          </Box>
        );
      case "Approved":
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CircleIcon sx={{ color: "#58F35F", width: "15px" }} />
            <Typography variant="subtitle1">{status}</Typography>
          </Box>
        );
      case "Denied":
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CircleIcon sx={{ color: "#D81414", width: "15px" }} />
            <Typography variant="subtitle1">{status}</Typography>
          </Box>
        );
      case "Returned":
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CircleIcon sx={{ color: "#9D50AF", width: "15px" }} />
            <Typography variant="subtitle1">{status}</Typography>
          </Box>
        );
      case "ReSubmitted":
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CircleIcon sx={{ color: "#6C6C6C", width: "15px" }} />
            <Typography variant="subtitle1">{status}</Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  const columns = [
    { field: "applicationId", headerName: "Application Id", flex: 1 },
    { field: "candidateName", headerName: "Candidate Name", flex: 1 },
    { field: "candidateJobTitle", headerName: "Job Title", flex: 1 },
    {
      field: "candidateTypeOfResource",
      headerName: "Resource Type",
      flex: 1,
    },
    {
      field: "candidateStatus",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => renderCandidateStatus(findFullform(params.row)),
    },
    {
      field: "accessType",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => action(params.row),
    },
  ];

  const action = (data) => {
    return (
      <Grid sx={{ cursor: "pointer" }}>
        <IconButton onClick={() => handleAction("Edit", data)}>
          <Tooltip title="View Case" placement="right">
            <EditIcon />
          </Tooltip>
        </IconButton>
      </Grid>
    );
  };

  const getAeData = async () => {
    try {
      showLoader();
      const res = await ApprovedData(pageState);
      if (res.status === 200) {
        setRowData(res.data?.content);
        setPageCount(res?.data?.totalElements);
        try {
          showLoader();
          const resLokup = await getLookup();
          if (resLokup.status === 200) {
            const groupedLookup = groupLookup(resLokup.data);
            const myLookup = groupedLookup["CANDIDATE_STATUS"];
            setLookupGroup(myLookup);
          }
        } catch (error) {
          toast.error("Server Error");
        } finally {
          hideLoader();
        }
      }
    } catch (error) {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getAeData();
  }, [pageState]);

  return (
    <>
      <DataGrid
        sx={{
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bolder",
          },
        }}
        getRowId={(row) => row.candidateDetailId}
        columns={columns}
        rows={rowData ?? []}
        rowCount={pageCount}
        pageSizeOptions={[10, 20, 30]}
        paginationMode="server"
        onPaginationModelChange={onPageChange}
        paginationModel={pageState}
      />
    </>
  );
};

export default AeNotificationTable;
