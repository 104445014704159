import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { useLocation } from "react-router";
import { getBidBreakDownById } from "../../services/apiRequests";
import Typography from "@mui/material/Typography";
import useLoader from "../../Loader/useLoader";
import { clearScreenId, setscreenId } from "../../reduxApp/fieldStore";
import useMount from "../../hooks/useMount";
const useStyles = makeStyles((theme) => ({
  BidBreakDown: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "50px",
    padding: "27px 24px 32px",
    flexShrink: "0",
  },
  cover: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "50px",
  },
  BidBreakDownContainer: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    display: "flex",
    height: "56px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    borderBottom: "1px solid var(--Secon-2-D-Grey, #D9D9D9)",
    background: "var(--Secon-1-D-Blue, #44A0E3)",
    color: "var(--Background, #FFF)",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.5px",
  },
  subtitle: {
    display: "flex",
    height: "56px",
    alignItems: "center",
    alignSelf: "stretch",
    background: "var(--Secon-2-L-White, #F7F7F7)",
    color: "#282828",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    padding: "16px",
  },
  textformat: {
    color: "var(--Text, #000)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    textTransform: "capitalize",
  },
}));

const BidBreakDown = () => {
  const fromRedux = useSelector((state) => state.dropedField);
  const name = fromRedux.userName;
  const id = fromRedux.id;
  const [showLoader, hideLoader] = useLoader();
  const [bid, setBid] = useState({
    bdDirectCompensation: {},
    bdvariableExpenses: {},
    bdSemiVarirableExpenses: {},
    bdAllocatedOperationExpenses: {},
    bdTotal: {},
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const { candidateIdFromState, candidateName } = location.state || {};

  const fetchBid = async () => {
    try {
      showLoader();
      let response = await getBidBreakDownById(id);
      setBid(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      hideLoader();
    }
  };

  useMount(() => {
    dispatch(setscreenId(4));
    return () => {
      clearScreenId();
    };
  });
  useEffect(() => {
    fetchBid();
  }, []);

  const directComponent = [
    {
      field: "Straight time",
      amount: `$${bid.bdDirectCompensation.straightTime || "0.00"}`,
      percentage: "100%",
    },
    {
      field: "Wavier",
      amount: `$${bid.bdDirectCompensation.waiver || "0.00"}`,
      percentage: "-",
    },
    {
      field: "Annualized Merit",
      amount: `$${bid.bdDirectCompensation.annualizedMerit || "0.00"}`,
      percentage: "-",
    },
    {
      field: "Annualized Direct Comp",
      amount: `$${bid.bdDirectCompensation.adjustedDirectComp || "0.00"}`,
      percentage: "100%",
    },
    {
      field: "Target Compensation",
      amount: `$${bid.bdDirectCompensation.targetCompensation || "0.00"}`,
    },
  ];
  const variableExpenses = [
    {
      field: "FICA",
      amount: `$${bid.bdvariableExpenses.fica || "0.00"}`,
      percentage: "7.65%",
    },
    {
      field: "FUTA",
      amount: `$${bid.bdvariableExpenses.futa || "0.00"}`,
      percentage: "1.00%",
    },
    {
      field: "SUTA",
      amount: `$${bid.bdvariableExpenses.suta || "0.00"}`,
      percentage: "1.25%",
    },
    {
      field: "Worker's Comp",
      amount: `$${bid.bdvariableExpenses.workerComp || "0.00"}`,
      percentage: "0.75%",
    },
    {
      field: "General/Professional Liablity",
      amount: `$${
        bid.bdvariableExpenses.generalProfessionalLiablity || "0.00"
      }`,
      percentage: "0.25%",
    },
    {
      field: "Sub-Total",
      amount: `$${bid.bdvariableExpenses.subTotal || "0.00"}`,
      percentage: "10.90%",
    },
  ];
  const semiVariableExpenses = [
    {
      field: "Vacation + Sick Days(# of Days)",
      amount: `${
        parseInt(bid.bdSemiVarirableExpenses.vacationSickDays) || "0"
      }`,
      percentage: "-",
    },
    {
      field: "Paid Time Off- Holiday",
      amount: `$${
        bid.bdSemiVarirableExpenses.paidTimeOffHolidaySubTotal || "0.00"
      }`,
      percentage: `${bid.bdSemiVarirableExpenses.paidTimeOffHolidayPct}%`,
    },
    {
      field: "Health Insurance",
      amount: `$${bid.bdSemiVarirableExpenses.healthInsurance || "0.00"}`,
      percentage: `${bid.bdSemiVarirableExpenses.healthInsurancePct}%`,
    },
    {
      field: "Life insurance",
      amount: `$${bid.bdSemiVarirableExpenses.lifeInsurance || "0.00"}`,
      percentage: `${bid.bdSemiVarirableExpenses.lifeInsurancePct}%`,
    },
    {
      field: "Other Expense( bonus etc)",
      amount: `$${bid.bdSemiVarirableExpenses.otherExpense || "0.00"}`,
      percentage: `${bid.bdSemiVarirableExpenses.otherExpensePct}%`,
    },
    {
      field: "Non-billable Work (Breavement Fee)",
      amount: `$${bid.bdSemiVarirableExpenses.nonBillableWork || "0.00"}`,
      percentage: `${bid.bdSemiVarirableExpenses.nonBillableWorkPct}%`,
    },
    {
      field: "Immigration Fees",
      amount: `$${bid.bdSemiVarirableExpenses.immigrationFees || "0.00"}`,
      percentage: `${bid.bdSemiVarirableExpenses.immigrationFeesPct}%`,
    },
    {
      field: "Other (Benefits)",
      amount: `$${bid.bdSemiVarirableExpenses.otherBenefits || "0.00"}`,
      percentage: `${bid.bdSemiVarirableExpenses.otherBenefitsPct}%`,
    },
    {
      field: "Sub-Total",
      amount: `$${bid.bdSemiVarirableExpenses.subTotal || "0.00"}`,
      percentage: `${bid.bdSemiVarirableExpenses.subTotalPct}%`,
    },
  ];

  const allocatedOperatingExpenses = [
    {
      field: "Selling",
      amount: `$${bid.bdAllocatedOperationExpenses.selling || "0.00"}`,
      percentage: "0.75%",
    },
    {
      field: "General management",
      amount: `$${
        bid.bdAllocatedOperationExpenses.generalManagement || "0.00"
      }`,
      percentage: "0.88%",
    },
    {
      field: "Administration",
      amount: `$${bid.bdAllocatedOperationExpenses.administration || "0.00"}`,
      percentage: "2.94%",
    },
    {
      field: "Recruiting",
      amount: `$${bid.bdAllocatedOperationExpenses.recruiting || "0.00"}`,
      percentage: "7.44%",
    },
    {
      field: "Facilities",
      amount: `$${bid.bdAllocatedOperationExpenses.facilities || "0.00"}`,
      percentage: "0.93%",
    },
    {
      field: "Other",
      amount: `$${bid.bdAllocatedOperationExpenses?.other || "0.00"}`,
      percentage: "3.96%",
    },
    {
      field: "Sub-Total",
      amount: `$${bid.bdAllocatedOperationExpenses?.subTotal || "0.00"}`,
      percentage: "16.91%",
    },
  ];

  const total = [
    {
      field: "Total Hourly Cost",
      amount: `$${bid.bdTotal.totalHourlyCost || "0.00"}`,
      percentage: "63.1%",
    },
    {
      field: "Total Hourly Bid",
      amount: `$${bid.bdTotal.totalHourlyBid || "0.00"}`,
      // percentage: "0.14%",
    },
    {
      field: "Hourly Profit",
      amount: `$${bid.bdTotal.hourlyProfit || "0.00"}`,
      // percentage: "0.14%",
    },
    {
      field: "ROS",
      amount: `${bid.bdTotal.rso || "0.00"}%`,
      // percentage: "0.14%",
    },
    {
      field: "ROS After tax",
      amount: `${bid.bdTotal.rsoAfterBid || "0.00"}%`,
      // percentage: "",
    },
  ];
  const classes = useStyles();
  return (
    <>
      <Box className={classes.BidBreakDown}>
        <Typography variant="h4" component="h3">
          Candidate Name : {name}
        </Typography>
        <Box className={classes.cover}>
          <Box className={classes.BidBreakDownContainer}>
            <Box className={classes.title}>
              <p>Bid Break Down Worksheet</p>
            </Box>
            <Box className={classes.subtitle}>
              <p>Direct Compensation</p>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 750 }} aria-label="simple table">
                <TableBody className={classes.textformat}>
                  {directComponent.map((row) => (
                    <TableRow
                      sx={{
                        "&:nth-last-child(-n+2) td, &:nth-last-child(-n+2) th":
                          {
                            background: "#D3E8F8",
                          },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "50%" }}
                      >
                        {row.field}
                      </TableCell>
                      <TableCell align="right" sx={{ width: "34%" }}>
                        {row.amount}
                      </TableCell>
                      <TableCell align="right" sx={{ width: "34%" }}>
                        {row.percentage}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.subtitle}>
              <p>Variable Expenses</p>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody className={classes.textformat}>
                  {variableExpenses.map((row) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          background: "#D3E8F8",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "50%" }}
                      >
                        {row.field}
                      </TableCell>
                      <TableCell align="right" sx={{ width: "34%" }}>
                        {row.amount}
                      </TableCell>
                      <TableCell align="right" sx={{ width: "34%" }}>
                        {row.percentage}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.subtitle}>
              <p>Semi-Variable Expenses</p>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody className={classes.textformat}>
                  {semiVariableExpenses.map((row) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          background: "#D3E8F8",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "50%" }}
                      >
                        {row.field}
                      </TableCell>
                      <TableCell align="right" sx={{ width: "34%" }}>
                        {row.amount}
                      </TableCell>
                      <TableCell align="right" sx={{ width: "34%" }}>
                        {row.percentage}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.subtitle}>
              <p>Allocated Operating Expenses</p>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody className={classes.textformat}>
                  {allocatedOperatingExpenses.map((row) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          background: "#D3E8F8",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "50%" }}
                      >
                        {row.field}
                      </TableCell>
                      <TableCell align="right" sx={{ width: "34%" }}>
                        {row.amount}
                      </TableCell>
                      <TableCell align="right" sx={{ width: "34%" }}>
                        {row.percentage}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <TableContainer component={Paper} sx={{ background: "#D3E8F8" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody className={classes.textformat}>
                {total.map((row) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottom:
                          "1px solid var(--Secon-2-D-Grey, #D9D9D9)",
                        width: "50%",
                      }}
                    >
                      {row.field}
                    </TableCell>
                    <TableCell align="right" sx={{ width: "34%" }}>
                      {row.amount}
                    </TableCell>
                    <TableCell align="right" sx={{ width: "34%" }}>
                      {row.percentage}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default BidBreakDown;
