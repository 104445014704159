import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { store } from "./reduxApp/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
  import Loader from "./Loader";
import configStore from "./store/configureStore";
  import { PersistGate } from 'redux-persist/integration/react';
  const root = ReactDOM.createRoot(document.getElementById("root"));
  const { persistor, store } = configStore();
  const LoaderX = () => <div>Loading...</div>;
  root.render(
    <Provider store={store}>
       <PersistGate persistor={persistor} loading={<LoaderX open={true} />}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <App />
          <Loader />
        </BrowserRouter>
      </LocalizationProvider>
      </PersistGate>
    </Provider>
  );
