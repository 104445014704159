import { Paper, Table, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

export const MarkUpTable = (props) => {
  const { value } = props;
  const markUpPct = [
    {
      field: "30%",
      pct: `$${value?.markUp30Pct || "0.00"}`,
    },
    {
      field: "31%",
      pct: `$${value?.markUp31Pct || "0.00"}`,
    },
    {
      field: "32%",
      pct: `$${value?.markUp32Pct || "0.00"}`,
    },
    {
      field: "33%",
      pct: `$${value?.markUp33Pct || "0.00"}`,
    },
    {
      field: "34%",
      pct: `$${value?.markUp34Pct || "0.00"}`,
    },
    {
      field: "35%",
      pct: `$${value?.markUp35Pct || "0.00"}`,
    },
    {
      field: "36%",
      pct: `$${value?.markUp36Pct || "0.00"}`,
    },
    {
      field: "37%",
      pct: `$${value?.markUp37Pct || "0.00"}`,
    },
    {
      field: "42%",
      pct: `$${value?.markUp42Pct || "0.00"}`,
    },
    {
      field: "43%",
      pct: `$${value?.markUp43Pct || "0.00"}`,
    },
    {
      field: "44%",
      pct: `$${value?.markUp44Pct || "0.00"}`,
    },
    {
      field: "45%",
      pct: `$${value?.markUp45Pct || "0.00"}`,
    },
    {
      field: "46%",
      pct: `$${value?.markUp46Pct || "0.00"}`,
    },
    {
      field: "47%",
      pct: `$${value?.markUp47Pct || "0.00"}`,
    },
  ];
  return (
    <Paper sx={{ height: "auto", overflow: "hiden" }}>
      <div style={{ height: "100%", overflow: "auto" }}>
        <Table
          sx={{
            width: "300px",
            //   "& .MuiTableCell-root": {
            //     border: "1px solid #c4c4c4",
            //   },
            position: "relative",
            //   overflowX: "auto",
          }}
        >
          <TableRow sx={{ width: "100%" }}>
            <TableCell
              variant="head"
              sx={{
                fontWeight: 700,
                color: "#44A0E3",
                textAlign: "left",
                padding: "10px",
              }}
            >
              Mark Up %
            </TableCell>

            <TableCell
              variant="head"
              sx={{
                fontWeight: 700,
                color: "#44A0E3",
                textAlign: "left",
                padding: "10px",
              }}
            >
              Amount
            </TableCell>
          </TableRow>
          {markUpPct.map((row) => (
            <TableRow>
              <TableCell sx={{ textAlign: "left", padding: "10px" }}>
                {row.field}
              </TableCell>
              <TableCell sx={{ textAlign: "left", padding: "10px" }}>
                {row.pct}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </div>
    </Paper>
  );
};
