import {
  Box,
  Typography,
  Modal,
  Button,
  Paper,
  TextField,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDashboardItem,
  activityDataSave,
} from "../../../services/apiRequests";
import useLoader from "../../../Loader/useLoader";
//import { setVmsStates, setVmsModal } from "../../../reduxApp/fieldStore";
import React from "react";
import { showLoader, hideLoader } from "../../../Loader/loaderSlice";
import toast from "react-hot-toast";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  display: "flex",
  border: "none",
  flexDirection: "column",
  boxShadow: 24,
  alignItems: "center",
  p: 4,
};
const DeleteModal = (props) => {
  const { openDelete, setOpenDelete } = props;
  const dispatch = useDispatch();
  const employIntegration = useSelector((state) => state.dropedField);
  const [showLoader, hideLoader] = useLoader();
  const handleDelete = async () => {
    try {
      showLoader();
      const deleted = await deleteDashboardItem(openDelete.screenId);
      if (deleted.status === 200)
        setOpenDelete({
          screenId: -1,
          openDelete: false,
        });
    } catch (error) {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
    // const activitylogModel = {
    //   applicationId: openDelete.screenId,
    //   screenId: 0,
    //   actionType: "delete",
    // };
    // await activityDataSave(activitylogModel);
  };

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <span
          class="material-symbols-outlined"
          style={{ fontSize: "80px", color: "#cf4242", fontWeight: "600" }}
        >
          Delete
        </span>

        <Typography
          variant="h4"
          sx={{ fontWeight: "600", color: "#6C6C6C", margin: "10px" }}
        >
          Delete This Record ?
        </Typography>
        <Typography variant="p" sx={{ color: "#6C6C6C" }}>
          Do you really want to delete this record?{" "}
        </Typography>
        <Typography variant="p" sx={{ color: "#6C6C6C" }}>
          You will not able to recover it.
        </Typography>
        <Box display={"flex"} sx={{ margin: "25px" }}>
          <button
            onClick={() => {
              setOpenDelete(false);
            }}
            style={{
              color: "black",
              padding: "10px 35px",
              fontSize: "14px",
              border: "1px solid black",
              background: "white",
              borderRadius: "3px",
            }}
          >
            Cancel
          </button>
          <button
            onClick={handleDelete}
            style={{
              background: "#cf4242",
              borderRadius: "3px",
              marginLeft: "7px",
              border: "none",
              padding: "10px 35px",
              color: "white",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            Delete
          </button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default DeleteModal;
