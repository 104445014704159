import React, { useState, useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loginimage from "./Images/login-image.svg";
import "../Login/Login.css";
import { Alert, FormControl, MenuItem, Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import { authApi } from "./index";
import {
  getLookup,
  getSupervisorsData,
  postRegisterData,
  updatePassowrdRed,
} from "../../services/apiRequests";
import useLoader from "../../Loader/useLoader";
import { map } from "lodash";
import { AuthClient } from "../../utils/HttpClient";
import toast from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import { groupLookup } from "../../utils/helpers";

const defaultTheme = createTheme();

function Registration() {
  const [showLoader, hideLoader] = useLoader();
  const [showPassword, setShowPassword] = useState(false);
  const [accessToken, setaccessToken] = useState("");
  const [spData, setSpData] = useState("");
  const [lookup, setLookup] = useState([]);

  useEffect(() => {
    getLookups();
  }, []);

  const getLookups = async () => {
    const res = await getLookup();
    if (res.status === 200) {
      const extractedData = groupLookup(res.data);
      setLookup(extractedData);
    }
  };

  const { watch, control, getValues, setValue, reset, handleSubmit } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      role: "",
      country: "",
      approver: "",
      password: "",
      registerEmail: "",
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (accessToken) fetchLookup();
  }, [accessToken]);

  const fetchLookup = async () => {
    try {
      showLoader();
      const supervisorData = await getSupervisorsData();
      if (supervisorData?.status === 200) {
        setSpData(supervisorData?.data);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  // email or phone validation code

  useEffect(() => {
    adminLogin();
  }, []);

  const adminLogin = async () => {
    try {
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("grant_type", "password");
      bodyFormData.append("client_id", "security-admin-console");
      bodyFormData.append("username", "test@kyyba.com");
      bodyFormData.append("password", "test123");
      bodyFormData.append("scope", "openid");
      const res = await authApi.post(
        "protocol/openid-connect/token",
        bodyFormData
      );
      setaccessToken(res?.data?.access_token);
    } catch (error) {
    } finally {
    }
  };

  useMemo(() => {
    AuthClient.interceptors.request.handlers = [];
    AuthClient.interceptors.request.use(
      (config) => {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        };
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, [accessToken]);

  const updatePass = async (payload) => {
    try {
      showLoader();
      const res = await updatePassowrdRed(payload);
      if (res.status === 200) {
        toast((t) => (
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography variant="subtitle2">
              Please check your mail for update password
            </Typography>
            <Button onClick={() => toast.dismiss(t.id)}>Close</Button>
          </Box>
        ));
      }
    } catch (error) {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
  };

  const register = async () => {
    try {
      if (accessToken !== "") {
        const payload = {
          firstName: getValues("firstName"),
          lastName: getValues("lastName"),
          roles: [getValues("role")],
          country: getValues("country"),
          superviosrId: getValues("supervisor"),
          approverId: getValues("approver"),
          password: getValues("password"),
          email: getValues("registerEmail"),
          userName: getValues("registerEmail"),
          emailTemplateId: 5,
          sourceSystem: "RC",
        };
        const updatePayload = {
          email: getValues("registerEmail"),
          userName: getValues("registerEmail"),
        };
        const res = await postRegisterData(payload);
        if (res.status === 200) {
          toast.success("Candidate is created check your mail");
          reset();
          updatePass(res.data);
        }
      }
    } catch (error) {
      toast.error("Server Error");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <Box sx={{ display: "flex", justifyContent: "center" }}> */}
      <Grid
        container
        component="main"
        sx={{
          height: "auto",
          width: "90%",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className="backimage"
          sx={{
            backgroundImage: `url(${Loginimage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: "#44A0E3",
            borderRadius: "20px 0px 0px 20px",
            backgroundPosition: "right",
          }}
        />
        <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              padding: "2rem",
              display: "flex",

              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5" className="loginheadder">
              Registration Account
            </Typography>
            <Box component="form" sx={{ mt: 1, width: "90%" }}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      margin="normal"
                      required
                      id="firstName"
                      label="First Name"
                      fullWidth
                      placeholder="Enter First Name"
                    />
                  );
                }}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      margin="normal"
                      required
                      id="lastName"
                      label="Last Name"
                      fullWidth
                      placeholder="Enter Last Name"
                    />
                  );
                }}
              />
              <Controller
                name="registerEmail"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      margin="normal"
                      required
                      id="email"
                      label="Email ID"
                      fullWidth
                      placeholder="Enter Email ID"
                      autoComplete="email"
                    />
                  );
                }}
              />

              <Box
                sx={{
                  gap: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1rem",
                }}
              >
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder="Select Role"
                          label="Role"
                        >
                          {map(lookup.ROLE, (item) => {
                            return (
                              <MenuItem
                                value={item.value}
                                disabled={
                                  item.value === "ACCEXCTL1" ||
                                  item.value === "SPADMN" ||
                                  item.value === "ADMN" ||
                                  item.value === "HR"
                                }
                              >
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      );
                    }}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder="Select Country"
                          label="Country"
                        >
                          {map(lookup.CALCOUNTRY, (item) => {
                            return (
                              <MenuItem value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      );
                    }}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Supervisor
                  </InputLabel>
                  <Controller
                    name="supervisor"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder="Select supervisor"
                          label="supervisor"
                          control={control}
                        >
                          {map(spData, (item) => {
                            return (
                              <MenuItem key={item.lookupId} value={item.id}>
                                {item.approverName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      );
                    }}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Approver
                  </InputLabel>
                  <Controller
                    name="approver"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder="Select Approver"
                          label="Approver"
                          control={control}
                        >
                          {map(spData, (item) => {
                            return (
                              <MenuItem key={item.lookupId} value={item.id}>
                                {item.approverName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      );
                    }}
                  />
                </FormControl>
              </Box>
              <FormControl
                sx={{ mt: 3, mb: 1, width: "100%" }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => {
                    return (
                      <OutlinedInput
                        {...field}
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              <Visibility />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        name="password"
                      />
                    );
                  }}
                />
              </FormControl>

              <Button
                className="loginbtn"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                // type="submit"
                onClick={register}
              >
                Register
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* </Box> */}
    </ThemeProvider>
  );
}

export default Registration;
