import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import CommonContainer from "../../CommonContainer/CommonContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { Routepath } from "../../routing/routpath";
import { useNavigate, useLocation } from "react-router";

import {
  getBrtoPrbyId,
  activityDataSave,
  getVmsDataByPagesandRows,
} from "../../services/apiRequests";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import useLoader from "../../Loader/useLoader";
import { useDispatch, useSelector } from "react-redux";
import { useScreenId } from "../../hooks/useScreenId";
import useMount from "../../hooks/useMount";
import { clearScreenId, setscreenId } from "../../reduxApp/fieldStore";
import { find } from "lodash";
const useStyles = makeStyles((theme) => ({
  BidBreakDown: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "50px",

    padding: "27px 24px 32px",
    flexShrink: "0",
  },
  button: {
    display: "flex",

    justifyContent: "space-between",
    width: "240px",
    padding: "20px",
    alignItems: "flex-start",
    gap: "20px",
  },
  clear: {
    borderRadius: "4px",
    border: "1px solid  #6C6C6C",
    background: "var(--Secon-2-L-White, #F7F7F7)",
    boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
    height: "40px",
    padding: "0px 8px",
    "&:hover": {
      border: "1px solid  #6C6C6C",
      background: "var(--Secon-2-L-White, #F7F7F7)",
      boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
    },
  },
  cover: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: "30px",
    alignItems: "end",
  },
  BidBreakDownContainer: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    display: "flex",
    height: "56px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    borderBottom: "1px solid var(--Secon-2-D-Grey, #D9D9D9)",
    background: "var(--Secon-1-D-Blue, #44A0E3)",
    color: "var(--Background, #FFF)",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.5px",
  },
  subtitle: {
    display: "flex",
    height: "56px",
    alignItems: "center",
    alignSelf: "stretch",
    background: "var(--Secon-2-L-White, #F7F7F7)",
    color: "#282828",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    padding: "16px",
  },
  textformat: {
    color: "var(--Text, #000)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    textTransform: "capitalize",
  },
}));

const BrtoPr = () => {
  const fromRedux = useSelector((state) => state.dropedField);
  const name = fromRedux.userName;
  const id = fromRedux.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, hideLoader] = useLoader();
  const [vmsName, setVmsName] = useState();
  // const [id, setId] = useState();
  const [br, setBr] = useState({
    prBillRateModel: {},
    prEmpRelatedCostsModel: {},
    prOtherCostsModel: {},
    prFinalRateModel: {},
  });
  const location = useLocation();

  const { candidateIdFromState, candidateName } = location.state || {};
  const fetchBrtoPr = async () => {
    try {
      showLoader();
      let response = await getBrtoPrbyId(id);
      // const activitylogModel = {
      //   applicationId: response.data.applicationId,
      //   screenId: 5,
      //   actionType: "get",
      // };
      // await activityDataSave(activitylogModel);
      setBr(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      hideLoader();
    }
  };

  // useScreenId(5,() => {
  //   fetchBrtoPr();
  // }, []);

  const getVms = async () => {
    try {
      showLoader();
      const vmsCompany = await getVmsDataByPagesandRows(0, 1000);
      const matchedCustomer = find(
        vmsCompany?.data?.content,
        (item) => item.vmsId === fromRedux.matchedLoookupPayload.vmsFee
      );

      const customerNameWithFee = matchedCustomer
        ? matchedCustomer.customerNameWithFee
        : "-";
      setVmsName(customerNameWithFee);
    } catch (error) {
      //
    } finally {
      hideLoader();
    }
  };

  useMount(() => {
    dispatch(setscreenId(5));
    return () => {
      clearScreenId();
    };
  });
  useEffect(() => {
    fetchBrtoPr();
    getVms();
  }, []);

  const SubmitEvent = () => {
    navigate(Routepath.BidBreakDown, {
      state: {
        candidateIdFromState: candidateIdFromState,
        candidateName: candidateName,
      },
    });
  };
  const classes = useStyles();
  const billRatetoPayRate = [
    {
      field: "Bill rate",
      data: "Regular",
      amount: `$${br.prBillRateModel.billRate || "0.00"}`,
    },
    {
      field: "VMS Fee",
      data: `${vmsName}`,
      amount: `$${br.prBillRateModel.vmsFee || "0.00"}`,
    },
    {
      field: "Rebate Fee",

      amount: `$${br.prFinalRateModel.rebateFee || "0.00"}`,
    },
    {
      field: "BR after VMS fee",

      amount: `$${br.prBillRateModel.brAfterVmsFee || "0.00"}`,
    },
  ];
  const empRelatedCosts = [
    {
      field: "Base Pay Rate",
      data: "70.00%",
      amount: `$${br.prEmpRelatedCostsModel.basePayRate || "0.00"}`,
    },
    {
      field: "Vacation(# of Days)",
      data: `${br.prEmpRelatedCostsModel.totalLeaves}`,
      amount: `$${br.prEmpRelatedCostsModel.vacationDay || "0.00"}`,
    },
    {
      field: "Holidays (6days/9days)(Y or N)",
      data: `${fromRedux.matchedLoookupPayload.holidays}`,
      amount: `$${br.prEmpRelatedCostsModel.holiday || "0.00"}`,
    },
    {
      field: "Medical",
      data: `${fromRedux.matchedLoookupPayload.candidateInsurenceType}`,
      amount: `$${br.prEmpRelatedCostsModel.insurenceType || "0.00"}`,
    },
    {
      field: "Referral Bonus/Sign in bonus",
      data: `${fromRedux.matchedLoookupPayload.refferalAmount}`,
      amount: `$${br.prEmpRelatedCostsModel.referralBonusSignBonus || "0.00"}`,
    },
    {
      field: "Immigration",
      data: `${fromRedux.matchedLoookupPayload.immgirationType}`,
      amount: `$${br.prEmpRelatedCostsModel.immigrationType || "0.00"}`,
    },
    {
      field: "Other Exp-Fixed Dollar",
      data: `${fromRedux.matchedLoookupPayload.otherExpenses}`,
      amount: `$${br.prEmpRelatedCostsModel.otherExpense || "0.00"}`,
    },
    {
      field: "Other Exp-Hourly",
      data: `${fromRedux.matchedLoookupPayload.otherExpensesHourly}`,
      amount: `$${br.prEmpRelatedCostsModel.otherExpensesHourly || "0.00"}`,
    },
    {
      field: "Sum of EmpRelatedCosts",

      amount: `$${br.prEmpRelatedCostsModel.sumOfEmpRelatedCosts || "0.00"}`,
    },
  ];
  const otherCost = [
    {
      field: "Overheads",
      data: "5%",
      amount: `$${br.prOtherCostsModel.overheads || "0.00"}`,
    },
    {
      field: "Taxes",
      data: "0%",
      amount: `$${br.prOtherCostsModel.taxes || "0.00"}`,
    },
    {
      field: "Sales Overhead",
      amount: `$${br.prOtherCostsModel.salesOverhead || "0.00"}`,
    },
    {
      field: "Recruiting Overhead",
      amount: `$${br.prOtherCostsModel.recruitingOverhead || "0.00"}`,
    },
    {
      field: "Interest",
      amount: `$${br.prOtherCostsModel.interest || "0.00"}`,
    },
    {
      field: "Sum of Other Costs",
      amount: `$${br.prOtherCostsModel.subTotal || "0.00"}`,
    },
  ];
  const finalRate = [
    {
      field: "Final Max Payrate",

      amount: `$${br.prFinalRateModel.finalMaxPayrate || "0.00"}`,
    },
    {
      field: "Agreed Payrate",

      amount: `$${br.prFinalRateModel.agreedPayrate || "0.00"}`,
    },
    {
      field: "Total Pay %",

      amount: `${br.prFinalRateModel.totalPayPercentage || "0.00"}%`,
    },
    {
      field: "Total hourly cost",

      amount: `$${br.prFinalRateModel.totalHourlyCost || "0.00"}`,
    },
    {
      field: "Net Margin in $/HR",

      amount: `$${br.prFinalRateModel.netMarginAmount || "0.00"}`,
    },
    {
      field: "Monthly Income",

      amount: `$${br.prFinalRateModel.monthlyIncome || "0.00"}`,
    },
    {
      field: "Net Margin in %",

      amount: `${br.prFinalRateModel.netMarginPercentage || "0.00"}%`,
    },
    {
      field: "Annual Employee Earning",

      amount: `$${br.prFinalRateModel.annualEmployeeEarning || "0.00"}`,
    },
    {
      field: "Total employee Cost/HR (agreed)",

      amount: `$${br.prFinalRateModel.totalHourlyCost || "0.00"}`,
    },
    {
      field: "Total cost after taxes",

      amount: `$${br.prFinalRateModel.totalCostAfterTaxes || "0.00"}`,
    },
    {
      field: "Total employee cost % after payroll taxes",

      amount: `${br.prFinalRateModel.totalEmpCostPrTaxesPercentage || "0.00"}%`,
    },
    {
      field: "Gross Margin",

      amount: `${br.prFinalRateModel.grossMargin || "0.00"}%`,
    },
    {
      field: "Mark up(using PR+benefit cost)",

      amount: `${br.prFinalRateModel.markUpBenefitPercentage || "0.00"}%`,
    },
    {
      field: "mark up(using PR only)",

      amount: `${br.prFinalRateModel.markUpPr || "0.00"}%`,
    },
  ];
  return (
    <>
      <Box className={classes.BidBreakDown}>
        <Typography variant="h4" component="h3">
          Candidate Name : {name}
        </Typography>

        <Box className={classes.cover}>
          <Box className={classes.BidBreakDownContainer}>
            <Box className={classes.title}>
              <p>Bill rate to pay rate calculator</p>
            </Box>
            {/* <Box className={classes.subtitle}>
              <p>Direct Compensation</p>
            </Box> */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 750 }} aria-label="simple table">
                <TableBody className={classes.textformat}>
                  {billRatetoPayRate.map((row) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          background: "#D3E8F8",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "50%" }}
                      >
                        {row.field}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "34%" }}>
                        {row.data}
                      </TableCell>
                      <TableCell align="right" sx={{ width: "34%" }}>
                        {row.amount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.subtitle}>
              <p>EMP RELATED COSTS</p>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody className={classes.textformat}>
                  {empRelatedCosts.map((row) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          background: "#D3E8F8",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "50%" }}
                      >
                        {row.field}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "34%" }}>
                        {row.data}
                      </TableCell>
                      <TableCell align="right" sx={{ width: "34%" }}>
                        {row.amount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.subtitle}>
              <p>Other Costs</p>
            </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody className={classes.textformat}>
                  {otherCost.map((row) => (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          background: "#D3E8F8",
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "50%" }}
                      >
                        {row.field}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "34%" }}>
                        {row.data}
                      </TableCell>
                      <TableCell align="right" sx={{ width: "34%" }}>
                        {row.amount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Box className={classes.subtitle}>
              <p>Allocated Operating Expenses</p>
            </Box> */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody className={classes.textformat}>
                  {finalRate.map((row) => (
                    <TableRow
                      sx={{
                        "&:nth-child(-n+2) td, &:nth-child(-n+2) th": {
                          background: "#D3E8F8",
                        },
                        "&:nth-child(n+9):nth-child(-n+12) td, &:nth-child(n+9):nth-child(-n+12) th":
                          {
                            background: "#D3E8F8", // Replace with your desired background color
                          },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: "50%" }}
                      >
                        {row.field}
                      </TableCell>
                      <TableCell align="center" sx={{ width: "34%" }}>
                        {row.data}
                      </TableCell>
                      <TableCell align="right" sx={{ width: "34%" }}>
                        {row.amount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* <TableContainer component={Paper} sx={{ background: "#D3E8F8" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody className={classes.textformat}>
                {total.map((row) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottom:
                          "1px solid var(--Secon-2-D-Grey, #D9D9D9)",
                      }}
                    >
                      {row.field}
                    </TableCell>
                    <TableCell align="center">{row.amount}</TableCell>
                    <TableCell align="center">{row.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
        </Box>
      </Box>
    </>
  );
};

export default BrtoPr;
