import React, { useState, useEffect } from "react";
import stateHolidays from "../../assets/files/stateHolidays.xlsx";
import userResourec from "../../assets/files/userResourec.pdf";
import { makeStyles } from "@mui/styles";
import "./MenuBar.css";
import Kyyba_logo from "../../assets/Images/Kyyba_Logo.png";
//import Kyyba_logo from "../Menu Bar/Images/Kyyba_Logo.png";
import Button from "@mui/material/Button";
import { setNotificationCount } from "../../reduxApp/fieldStore";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getLookup,
  getNotificationTables,
  updatePassowrd,
  updatePassowrdRed,
} from "../../services/apiRequests";
import { useLocation, useNavigate } from "react-router-dom";
import { Routepath } from "../../routing/routpath";
// import { userData } from "../../reduxApp/fieldStore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { logout } from "../../store/slices/authSlice";
import { removeScreenAccess } from "../../reduxApp/fieldStore";
import { getAuthorization } from "../../selectors/authSelectors";
import CANADAflag from "../../assets/Images/canada.png";
import usflag from "../../assets/Images/us.png";
import indiaflag from "../../assets/Images/india.png";
import { Tooltip } from "@material-ui/core";
import { Height } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import toast, { Toaster } from "react-hot-toast";
import useLoader from "../../Loader/useLoader";
import { groupLookup } from "../../utils/helpers";
import { find, isElement, isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  usFlag: {
    width: "22px",
    height: "16px",
    background: `url(${usflag})`,
    backgroundColor: "lightgray",
    backgroundPosition: "-2.977px -4.849px",
    backgroundSize: "112.59% 140.42%",
    backgroundRepeat: "no-repeat",
  },
  india: {
    width: "22px",
    height: "16px",
    background: `url(${indiaflag})`,
    backgroundColor: "lightgray",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  CANADAflag: {
    width: "22px",
    height: "16px",
    background: `url(${CANADAflag})`,
    backgroundColor: "lightgray",
    backgroundPosition: "-2.977px -4.849px",
    backgroundSize: "112.59% 140.42%",
    backgroundRepeat: "no-repeat",
  },
  covercontainer: {
    display: "flex",
    padding: "0px 24px",
    justifyContent: "space-between", // Corrected property name
    alignItems: "center", // Corrected property name
    background: "var(--Background, #FFF)",
    boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
    position: "sticky",
    top: "0",
    width: "100%",
    zIndex: "2",
  },
  menu: {
    display: "flex",
    height: "56px",
    alignItems: "center",
    gap: "20px",
  },
  image: {
    display: "flex",
    height: "32px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
  },
  img: {
    width: 62.737,
    height: 24,
    flexShrink: 0,
    background: `url(${Kyyba_logo})`,

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  menuItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
  },
  profile: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "10px",
  },
  gray: {
    color: "var(--Dark-Grey, #6C6C6C)",
    width: "20.1px",
    height: "20px",
    flexShrink: "0",
  },
  profileAccount: {
    display: "flex",
    alignItems: "center",
  },
  padding0: {
    padding: "0px",
  },
  textformat: {
    color: "var(--Prim-1-N-Black, #2C2C2C)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px" /* 171.429% */,
    letterSpacing: "0.5px",
  },
  usertextformat: {
    color: "var(--Text, #000)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px" /* 171.429% */,
    letterSpacing: "0.5px",
  },
  icon: {
    height: "24px",
    width: "24px",
  },
}));

const MenuBar = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const selectore = useSelector((state) => state.dropedField);
  const open = Boolean(anchorEl);
  // const token = useSelector(getAuthToken);
  const [showLoader, hideLoader] = useLoader();
  const loginDetials = useSelector(getAuthorization);
  const token = useSelector((state) => state?.auth?.token);
  const dispatch = useDispatch();
  const [totalpages, setTotalPages] = useState();
  const { getAccess } = useAuth();
  //const [notificationCount, setnotificationCount] = useState();
  const { noAccess: noAccessNotify } = getAccess("8");
  const { noAccess: noAccessCreateUser } = getAccess("21");
  const [lookup, setLookup] = useState([]);

  useEffect(() => {
    getCountNotification();
  }, [loginDetials.emailId]);

  const getCountNotification = async () => {
    try {
      showLoader();
      if (loginDetials.emailId) {
        const dataRespond = await getNotificationTables();
        setTotalPages(dataRespond.data.totalElements);
      }
    } catch (error) {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
  };
  const [anchorHelp, setAnchorHelp] = useState(null);
  const opened = Boolean(anchorHelp);
  const handleHelpClicks = (event) => {
    setAnchorHelp(event.currentTarget);
  };
  const navigator = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleHelpClose = () => {
    setAnchorHelp(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updatePass = async () => {
    handleClose();
    try {
      if (loginDetials?.emailId) {
        const payload = {
          email: loginDetials?.emailId,
          userName: loginDetials?.emailId,
        };

        try {
          showLoader();
          const res = await updatePassowrdRed(payload);
          if (res.status === 200) {
            toast((t) => (
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography variant="subtitle2">
                  {" "}
                  Please check your mail for update password
                </Typography>
                <Button onClick={() => toast.dismiss(t.id)}>Close</Button>
              </Box>
            ));
            setAnchorEl(null);
            dispatch(logout());
            dispatch(removeScreenAccess(""));
          }
        } catch (error) {
          toast.error("Server Error");
        }
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const classes = useStyles(); // Call makeStyles to get the generated class names

  function generateAvatarBg(name, s, l) {
    if (!name) return;
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let h = hash % 360;
    return `hsl(${h}, ${s}%, ${l}%)`;
  }

  function stringAvatar(name) {
    if (!name || typeof name !== "string") {
      return {
        sx: {
          bgcolor: generateAvatarBg(name, 71, 70),
          width: 30,
          height: 30,
          fontSize: 14,
        },
        children: "",
      };
    }

    const nameParts = name.split(" ");

    // Check if the name has at least two parts
    if (nameParts.length >= 2) {
      return {
        sx: {
          bgcolor: generateAvatarBg(name, 71, 70),
          width: 30,
          height: 30,
          fontSize: 14,
        },
        children: `${nameParts[0][0]}${nameParts[1][0]}`,
      };
    } else {
      // Handle the case where the name doesn't have enough parts
      return {
        sx: {
          bgcolor: generateAvatarBg(name, 71, 70),
          width: 30,
          height: 30,
          fontSize: 14,
        },
        children: `${name[0]}`,
      };
    }
  }

  const downloadStareAcc = async () => {};

  const onLogout = async () => {
    try {
      dispatch(logout());
      dispatch(removeScreenAccess(""));
      toast.success("Logout Successfully");
      setAnchorEl(null);
      handleClose();
      // navigator(Routepath.Login);
    } catch (error) {}
  };
  const renderCountry = (status) => {
    switch (status) {
      case "USA":
        return (
          <>
            <Box className={classes.usFlag}></Box>
            {status}
          </>
        );
      case "India":
        return (
          <>
            <Box className={classes.india}></Box>
            {status}
          </>
        );
      case "CANADA":
        return (
          <>
            <Box className={classes.CANADAflag}></Box>
            {status}
          </>
        );
      default:
        return;
    }
  };

  const registration = () => {
    handleClose();
    navigator(Routepath.Register);
  };

  const getLookups = async () => {
    try {
      showLoader();
      const res = await getLookup();
      if (res.status === 200) {
        const extractedData = groupLookup(res.data);
        setLookup(extractedData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getLookups();
  }, [loginDetials?.roleCd]);

  const renderRoles = (status) => {
    const data = find(lookup?.ROLE, { value: status });
    return data?.label;
  };
  return (
    <>
      <div className={classes.covercontainer}>
        <div className={classes.menu}>
          <div className={classes.image}>
            <div className={classes.img}></div>
          </div>
        </div>
        <div className={classes.profile}>
          {loginDetials.emailId && (
            <Box>
              <Button
                style={{ color: "black", textTransform: "initial" }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleHelpClicks}
              >
                Help
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorHelp}
                open={Boolean(anchorHelp)}
                onClose={handleHelpClose}
                // MenuListProps={{
                //   "aria-labelledby": "basic-button",
                // }}
              >
                <MenuItem onClick={handleHelpClose}>
                  <a
                    href={userResourec}
                    target="_blank"
                    style={{
                      border: "none",
                      color: "#000",
                      fontSize: "16px",
                    }}
                  >
                    Human Resource
                  </a>
                </MenuItem>
                <MenuItem onClick={handleHelpClose}>
                  <a
                    href={stateHolidays}
                    download="stateHolidays"
                    style={{
                      border: "none",
                      color: "#000",
                      fontSize: "16px",
                    }}
                  >
                    State Holidays
                  </a>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigator(Routepath.ReleaseNote);
                  }}
                >
                  Release Notes
                </MenuItem>
              </Menu>
            </Box>
          )}
          {loginDetials.emailId && (
            <Box sx={{ display: "flex", gap: "10px" }}>
              {noAccessNotify === false ? (
                <Tooltip title={`You have ${totalpages} Notifications`}>
                  <div
                    onClick={() => {
                      navigator(Routepath.NotificationTables);
                    }}
                  >
                    <div className="bellIcon">
                      {totalpages ? (
                        <div
                          style={{
                            width: "17px",
                            height: "17px",
                            position: "absolute",
                            fontSize: "10px",
                            color: "white",
                            lineHeight: "1.2",
                            textAlign: "center",
                            zIndex: 1,
                            borderRadius: "50%",
                            marginLeft: "10px",
                            // paddingLeft: "-20px",
                            backgroundColor: "red",
                          }}
                        >
                          {totalpages}
                        </div>
                      ) : (
                        ""
                      )}
                      <IconButton
                        style={{
                          position: "relative",
                          zIndex: "0",
                          fontSize: "34px",
                        }}
                        className={classes.icon}
                      >
                        <NotificationsOutlinedIcon
                          className={classes.gray}
                          style={{ width: "24px", height: "24px" }}
                        />
                      </IconButton>
                    </div>
                  </div>
                </Tooltip>
              ) : null}
              <div className="setting">
                <IconButton className={classes.icon}>
                  <SettingsOutlinedIcon
                    className={classes.gray}
                    style={{ width: "24px", height: "24px" }}
                  />
                </IconButton>
              </div>
            </Box>
          )}

          <div className={classes.profileAccount}>
            {loginDetials?.firstName && loginDetials.firstName !== "" ? (
              <>
                <Button
                  onClick={handleClick}
                  style={{
                    display: "flex",
                    color: "black",
                    alignItems: "center",
                  }}
                >
                  <IconButton style={{ paddingRight: 8 }}>
                    <Avatar
                      {...stringAvatar(
                        `${loginDetials.firstName} ${
                          loginDetials.lastName || "User Name"
                        }`
                      )}
                    />
                  </IconButton>
                  <Box>
                    <Box
                      sx={{
                        textTransform: "none",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Box
                          className={classes.usertextformat}
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "12px",
                          }}
                        >
                          {loginDetials.firstName} {loginDetials.lastName}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          fontSize: "12px",
                          gap: "8px",
                          justifyContent: "left",
                          alignItems: "center",
                          height: "17px",
                        }}
                      >
                        {renderCountry(loginDetials.country)}
                      </Box>
                    </Box>
                  </Box>
                  <KeyboardArrowDownIcon style={{ color: "#545454" }} />
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    {loginDetials.emailId}
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    {renderRoles(loginDetials.roleCd)}
                  </MenuItem>
                  <MenuItem onClick={updatePass} sx={{ color: "#1C78D3" }}>
                    Update Password
                  </MenuItem>
                  {noAccessCreateUser === false ? (
                    <MenuItem onClick={registration} sx={{ color: "#1C78D3" }}>
                      Create User
                    </MenuItem>
                  ) : null}
                  <MenuItem onClick={onLogout} sx={{ color: "#1C78D3" }}>
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default MenuBar;
