import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Tooltip,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Grid,
} from "@mui/material";
import DeleteModal from "./DeleteModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import styled from "styled-components";
import React from "react";
import useLoader from "../../../Loader/useLoader";
import { GetComments, getLookup } from "../../../services/apiRequests";
import CircleIcon from "@mui/icons-material/Circle";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import UndoIcon from "@mui/icons-material/Undo";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  renderGrossMargincolor,
  renderCandidateStatus,
} from "../../../constance/DashboardJson";
import { keyframes, minWidth } from "@mui/system";
import useAuth from "../../../hooks/useAuth";
import { DataGrid } from "@mui/x-data-grid";
import Comment from "./Comment";
import { isEmpty } from "lodash";
import { makeStyles } from "@mui/styles";

const floatRightToLeft = keyframes`
   0% {
    transform: translateX(0%) translateY(-50%);
  }
  100% {
    transform: translateX(-30%) translateY(-50%);
  }
`;
const floatLeftToRight = keyframes`
   0% {
    transform: translateX(-30%) translateY(-50%);
  }
  100% {
    transform: translateX(50%) translateY(-50%);
  }
`;

const useStyles = makeStyles((theme) => ({
  status: {
    border: "1px solid var(--Secon-3-Grey, #C2C2B7)",
    display: "flex",
    alignItems: "center",
    padding: "5px",
    borderRadius: "6px",
    width: "100%",
    cursor: "pointer",
  },
  statusBox: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const CreatedataTable = ({
  candidateList,
  handleAction,
  setPage,
  changeStatus,
  setRowsPerPage,
  totalpages,
  roleLookup,
  handlePagination,
  lookupGroup,
  pageState,
  openDelete,
  setOpenDelete,
}) => {
  const [showLoader, hideLoader] = useLoader();
  const { getAccess } = useAuth();
  const { canReadWrite: accessForAP } = getAccess("26");
  const classes = useStyles();

  const [change, setChange] = useState(false);
  const [statusParams, setStatusParams] = useState(null);
  const [comment, setComment] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editData, setEditData] = useState({
    action: "",
    id: null,
    name: "",
  });

  const findFullform = (shortHand) => {
    const longHand = lookupGroup?.find((key) => key.value === shortHand);
    return longHand?.label;
  };

  const findRole = (shortHand) => {
    const longHand = roleLookup?.find((key) => key.value === shortHand);
    return longHand?.label;
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleOpenEdit = (action, id, name, status) => {
    setEditData({
      action: action,
      id: id,
      name: name,
      status: findFullform(status),
    });
    setOpenEditDialog(true);
  };
  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const changeSt = (candidateDetailId, applicationId, candidateStatus) => {
    setChange(true);
    setStatusParams({ candidateDetailId, applicationId, candidateStatus });
  };

  const handleOk = () => {
    if (statusParams) {
      changeStatus(
        statusParams.candidateDetailId,
        statusParams.applicationId,
        statusParams.candidateStatus
      );
    }
    setChange(false);
    setStatusParams(null);
  };
  const handleCloase = () => {
    setChange(false);
  };
  const [selectedId, setSelectedId] = useState([]);
  const Title = styled(Box)({
    width: "100%",
    fontWeight: "500",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
    "$ .MuiDataGrid-columnHeaderRow": { backgroundColor: "yellow" },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const getComment = async (id) => {
    try {
      showLoader();
      const res = await GetComments(id);
      if (res.status === 200) {
        setComment(res.data);
        if (!isEmpty(res.data)) {
          // Directly check res.data instead of comment
          handleOpen();
        }
      }
    } catch (error) {
      // Handle error if necessary
    } finally {
      hideLoader();
    }
  };

  const columns = useMemo(() => {
    return [
      {
        headerName: "ID",
        field: "applicationId",
        flex: 1,
        minWidth: 140,
        renderCell: (params) => {
          return (
            <Box sx={{ cursor: "pointer" }}>
              <Tooltip
                InputProps={{ style: { fontSize: "24px" } }}
                onClick={() =>
                  handleAction(
                    "View",
                    params.row.candidateDetailId,
                    params.row.candidateName
                  )
                }
              >
                <Typography variant="h7">{params.row.applicationId}</Typography>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        headerName: "Candidate Name",
        field: "candidateName",
        renderCell: (params) => {
          return (
            <Box sx={{ cursor: "pointer" }}>
              <Tooltip
                InputProps={{ style: { fontSize: "24px" } }}
                onClick={() =>
                  handleAction(
                    "View",
                    params.row.candidateDetailId,
                    params.row.candidateName
                  )
                }
              >
                <Typography>{params.row.candidateName}</Typography>
              </Tooltip>
            </Box>
          );
        },
        flex: 1,
        minWidth: 200,
      },
      {
        headerName: "Job Id",
        field: "jobId",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          return (
            <Box sx={{ cursor: "pointer" }}>
              <Tooltip
                InputProps={{ style: { fontSize: "24px" } }}
                onClick={() =>
                  handleAction(
                    "View",
                    params.row.candidateDetailId,
                    params.row.candidateName
                  )
                }
              >
                <Typography>{params.row.jobId===""?"-":params.row.jobId}</Typography>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        headerName: "Client Name",
        field: "customerName",
        flex: 1,
        minWidth: 220,
        renderCell: (params) => {
          return (
            <Box sx={{ cursor: "pointer" }}>
              <Tooltip
                InputProps={{ style: { fontSize: "24px" } }}
                onClick={() =>
                  handleAction(
                    "View",
                    params.row.candidateDetailId,
                    params.row.candidateName
                  )
                }
              >
                <Typography>{params.row.customerName}</Typography>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        headerName: "Gross Margin %",
        field: "grossMarginPercentage",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          return (
            <Box sx={{ cursor: "pointer" }}>
              <Tooltip
                InputProps={{ style: { fontSize: "24px" } }}
                title={`${params?.row?.grossMarginDoller} $`}
                onClick={() =>
                  handleAction(
                    "View",
                    params.row.candidateDetailId,
                    params.row.candidateName
                  )
                }
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px",
                    borderRadius: "6px",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <CircleIcon
                    sx={{
                      color:
                        renderGrossMargincolor?.[params?.row?.colorInd] ??
                        "white",
                      width: "15px",
                    }}
                  />
                  {params?.row?.grossMarginPercentage}%
                </div>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        headerName: "Start Date",
        field: "candidateStartDt",
        flex: 1,
        minWidth: 120,
        renderCell: (params) => {
          return (
            <Box sx={{ cursor: "pointer" }}>
              <Tooltip
                InputProps={{ style: { fontSize: "24px" } }}
                onClick={() =>
                  handleAction(
                    "View",
                    params.row.candidateDetailId,
                    params.row.candidateName
                  )
                }
              >
                <Typography>{params.row.candidateStartDt}</Typography>
              </Tooltip>
            </Box>
          );
        },
      },
      {
        headerName: "Recruiter Name",
        field: "recruiterName",
        renderCell: (params) => {
          return (
            <Box sx={{ cursor: "pointer" }}>
              <Tooltip
                InputProps={{ style: { fontSize: "24px" } }}
                onClick={() =>
                  handleAction(
                    "View",
                    params.row.candidateDetailId,
                    params.row.candidateName
                  )
                }
              >
                <Typography>
                  {params.row.recuiterFirstName} {params.row.recuiterLastName}
                </Typography>
              </Tooltip>
            </Box>
          );
        },
        flex: 1,
        minWidth: 150,
      },
      {
        headerName: "Status",
        field: "status",
        flex: 1,
        minWidth: 130,
        renderCell: (params) => {
          return (
            <Grid
              className={classes.status}
              onClick={() =>
                handleAction(
                  "View",
                  params.row.candidateDetailId,
                  params.row.candidateName
                )
              }
            >
              <Box className={classes.statusBox}>
                <CircleIcon
                  sx={{
                    color:
                      renderCandidateStatus?.[
                        findFullform(params.row.candidateStatus)
                      ] ?? "white",
                    width: "15px",
                  }}
                />

                <Tooltip title={findFullform(params.row.candidateStatus)}>
                  <span>{findFullform(params.row.candidateStatus)}</span>
                </Tooltip>
              </Box>
            </Grid>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        minWidth: 100,
        align: "right",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {params.row.candidateStatus === "PEN" ||
                params.row.candidateStatus === "DEN" ||
                params.row.candidateStatus === "RET" ||
                params.row.candidateStatus === "NCR" ||
                params.row.candidateStatus === "RES" ? (
                  <>
                    <IconButton
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleAction(
                          "Edit",
                          params.row.candidateDetailId,
                          params.row.candidateName
                        );
                      }}
                    >
                      <Tooltip title="Edit" placement="top">
                        <ModeEditOutlinedIcon
                          fontSize="small"
                          sx={{
                            color: "#6e57a8",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </IconButton>

                    <IconButton
                      onClick={async () => {
                        const deleteData = {
                          screenId: params.row.candidateDetailId,
                          openDelete: true,
                        };
                        setOpenDelete(deleteData);
                      }}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip title="Delete" placement="top">
                        <DeleteForeverOutlinedIcon
                          fontSize="small"
                          sx={{ color: "#cf4242" }}
                        />
                      </Tooltip>
                    </IconButton>
                  </>
                ) : accessForAP === true &&
                  (params.row.candidateStatus === "CMP" ||
                    params.row.candidateStatus === "ONB" ||
                    params.row.candidateStatus === "SUB") ? (
                  <>
                    <IconButton
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleOpenEdit(
                          "Edit",
                          params.row.candidateDetailId,
                          params.row.candidateName,
                          params.row.candidateStatus
                        );
                      }}
                    >
                      <Tooltip title="Edit" placement="top">
                        <ModeEditOutlinedIcon
                          fontSize="small"
                          sx={{
                            color: "#6e57a8",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </IconButton>

                    {/* <IconButton
                      onClick={async () => {
                        const deleteData = {
                          screenId: params.row.candidateDetailId,
                          openDelete: true,
                        };
                        setOpenDelete(deleteData);
                      }}
                      sx={{
                        visibility:
                          selectedId === params.row.candidateDetailId
                            ? "visible"
                            : "hidden",
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip title="Delete" placement="top">
                        <DeleteForeverOutlinedIcon
                          fontSize="small"
                          sx={{ color: "#cf4242" }}
                        />
                      </Tooltip>
                    </IconButton> */}
                  </>
                ) : null}

                {params.row.candidateStatus === "SUB" && (
                  <IconButton
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      changeSt(
                        params.row.candidateDetailId,
                        params.row.applicationId,
                        params.row.candidateStatus
                      )
                    }
                  >
                    <Tooltip title="Undo" placement="top">
                      <UndoIcon
                        fontSize="small"
                        sx={{
                          color: "#6e57a8",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                )}
              </Box>
              {/* <IconButton
                aria-label="edit"
                onClick={() =>
                  handleAction(
                    "Edit",
                    params.row.candidateDetailId,
                    params.row.candidateName
                  )
                }
              >
                <EditIcon sx={{ color: "#6495ED" }} />
              </IconButton> */}

              {/* <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton> */}
            </>
          );
        },
      },
      {
        headerName: "Comment",
        field: "Comment",
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
          return (
            <>
              <IconButton
                onClick={() => getComment(params?.row?.applicationId)}
                disabled={
                  params.row.candidateStatus === "SUB" ||
                  params.row.candidateStatus === "PEN"
                }
              >
                <CommentOutlinedIcon />
              </IconButton>
            </>
          );
        },
      },
    ];
  });

  const getRowId = (row) => {
    return row.applicationId;
  };

  return (
    <>
      <DataGrid
        rows={candidateList ?? []}
        getRowId={getRowId ?? 0}
        columns={columns ?? []}
        rowCount={totalpages ?? 0}
        pageSizeOptions={[5, 10, 20, 30]}
        paginationMode="server"
        onPaginationModelChange={handlePagination}
        paginationModel={pageState}
        disableRowSelectionOnClick={true}
        disableColumnMenu={true}
        disableColumnResize
        // autoHeight
        localeText={{ noRowsLabel: "No data found" }}
        sx={{
          marginTop: "1.8rem",
          backgroundColor: "white",
          "& .MuiDataGrid-cell": {
            "&:first-of-type": {
              fontWeight: "bold",
              fontSize: "1.1em",
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "0.9rem",
            fontWeight: "700",
            color: "#000000",
          },
          "& .MuiDataGrid-row": {
            color: "black",
            textAlign: "center",
          },
        }}
      />

      {openDelete.openDelete && (
        <DeleteModal
          //idTodelete={idTodelete}
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
        />
      )}
      {change && (
        <Dialog open={change}>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "1rem",
                borderRadius: "0.2rem",
                border: "1px solid grey",
              }}
            >
              <WarningAmberRoundedIcon
                sx={{ width: "5rem", height: "5rem", color: "#FF6D00" }}
              />
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                }}
              >
                Alert
              </Typography>
              <Typography sx={{ marginTop: "0.5rem" }} variant="h6">
                Are you sure you want to return the submission status to
                pending?
              </Typography>
              <Typography>
                Note: Returning the submission will move the candidate to the
                recruiter's dashboard who hired the candidate, removing it from
                your dashboard. If you hired the candidate, it will remain in
                your dashboard with a pending status.
              </Typography>

              <DialogActions>
                <Button onClick={handleCloase} variant="outlined">
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleOk} autoFocus>
                  Ok
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      <Dialog open={openEditDialog} onClose={handleCloseEdit}>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              padding: "1rem",
              borderRadius: "0.2rem",
              border: "1px solid grey",
            }}
          >
            <WarningAmberRoundedIcon
              sx={{ width: "5rem", height: "5rem", color: "#FF6D00" }}
            />
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "bolder",
              }}
            >
              Alert
            </Typography>
            <Typography sx={{ marginTop: "0.5rem" }} variant="h6">
              Are you sure you want to Edit {editData.status} Candidate?
            </Typography>

            <DialogActions>
              <Button
                variant="contained"
                onClick={() =>
                  handleAction(editData.action, editData.id, editData.name)
                }
                autoFocus
              >
                Yes
              </Button>
              <Button onClick={handleCloseEdit} variant="outlined">
                No
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          position: "absolute",
          top: "60%",
          display: openDialog === false ? "none" : "flex",
          right: "-7rem",
          animation:
            openDialog === true
              ? `${floatRightToLeft} .3s forwards ease-in-out`
              : `${floatLeftToRight} .3s forwards ease-in-out`,
          width: "40%",
        }}
      >
        <Comment
          handleClose={handleClose}
          findRole={findRole}
          comments={comment}
        />
      </Box>
    </>
  );
};

export default CreatedataTable;
