import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Autocomplete,
  Select,
  TextField,
  Checkbox,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
const useStyles = makeStyles((theme) => ({
  autocompleteInput: {
    fontSize: "14px",
  },
}));

export const Dropdown = (props) => {
  const {
    defaultValue,
    action,
    fieldName,
    onChange,
    options,
    displayRule,
    errorText,
    id,
    name,
    childCheckbox,
    defaultVlues,
    display,
    isRequired,
    child,
  } = props;
  const classes = useStyles();
  const isDisabled = action === "View" ? true : false;

  return (
    <>
      {display && displayRule && (
        <Box id={name}>
          <Grid
            container
            sx={{
              lineHeight: "3.5",
              // height: "56px",
              alignItems: "center",
              // columnGap: "30px",
              marginBottom: "5px",
              fontWeight: "600",
              wrap: "noWrap",
              fontSize: "14px",
            }}
          >
            <Box
              sx={{
                color: "var(--Black, var(--Text, #000))",
                textAlign: "right",
                fontSize: "14px",
                padding: "20px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                width: "195px",
                height: "56px",
                display: "flex",
                alignItems: "flex-start",
                gap: "5px",
                justifyContent: "flex-end",
              }}
              className={isRequired && "mandatoryField"}
            >
              {fieldName}
            </Box>
            <Box>
              {/* <Autocomplete
                disableTyping="true"
                disalbed={action == "View"}
                disabled={isDisabled}
                InputProps={{
                  style: {
                    fontSize: "14px",
                  },
                }}
                labelId="demo-simple-select-label"
                disablePortal
                id={
                  action != "View"
                    ? errorText
                      ? "outlined-error-helper-text"
                      : "combo-box-demo"
                    : "disabled"
                }
                options={options}
                className="defaultFieldStruc"
                onSelect={(e) => {
                  onChange(e.target.value, name);
                }}
                renderInput={(params) => (
                  <TextField
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    disabled={isDisabled}
                    error={errorText}
                    {...params}
                    value={defaultValue}
                    //label={fieldName}
                    className={classes.autocompleteInput}
                    helperText={errorText}
                    sx={{
                      color: action === "View" ? "grey" : "black",
                      fontSize: "14px",
                      backgroundColor: action === "View" ? "#f2f2f2" : "white",
                    }}
                  />
                )}
                value={defaultValue}
              /> */}
              <FormControl disabled={isDisabled} error={!!errorText}>
                <Select
                  disableTyping="true"
                  value={defaultValue}
                  sx={{
                    color: action === "View" ? "grey" : "black",
                    fontSize: "14px",
                    backgroundColor: action === "View" ? "#f2f2f2" : "white",
                  }}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                  //  labelId="demo-simple-select-label"
                  disablePortal
                  id={
                    action != "View"
                      ? errorText
                        ? "outlined-error-helper-text"
                        : "combo-box-demo"
                      : "disabled"
                  }
                  className="defaultFieldStruc"
                  onChange={(e) => {
                    onChange(e.target.value, name);
                  }}
                >
                  {options?.map((obj, key) => {
                    return (
                      <MenuItem key={key} value={obj.value}>
                        {obj.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errorText && <FormHelperText>{errorText}</FormHelperText>}
              </FormControl>
              {childCheckbox && name === "immgirationType" && <>{child}</>}
            </Box>
          </Grid>
        </Box>
      )}
    </>
  );
};
