import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { map } from "lodash";
import dayjs from "dayjs";
import StringAvatar from "../../../../CommonContainer/Avatar";

const Comment = ({ comments, findRole, handleClose }) => {
  return (
    <Box
      sx={{
        background: "#FFF",
        padding: "2rem 2rem 0rem 2rem",
        overflow: "scroll",
        height: "70vh",
        width: "100%",
        overflowX: "hidden",
        scrollbarWidth: "thin",
        scrollbarColor: "#44a0e3 transparent",
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",

        "&::-webkit-scrollbar": {
          width: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "blue",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h5"
          sx={{ paddingBottom: "1rem", fontWeight: 700 }}
        >
          Comments
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ width: "2rem", height: "2rem" }}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Grid
        container
        sx={{ flexDirection: "column", display: "flex", gap: "1rem" }}
      >
        <Divider />
        {comments &&
          map(comments, (item) => {
            if (item.commentDesc != null) {
              return (
                <Grid item key={item?.rcCommentsId}>
                  <Stack
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <Avatar
                        {...StringAvatar(item?.editorName ?? "James Anderson")}
                        sx={{ height: 40, width: 40 }}
                      />
                      <Typography variant="outline">
                        {item?.editorName ?? "James Anderson"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography>Role:</Typography>
                      <Typography>{findRole(item.editorRole)}</Typography>
                    </Box>
                    {/* <Grid sx={{ display: "flex" }}>
                    <ActionButtons
                      onEdit={() => editComments(item)}
                      onDelete={() => handleOpenDelete(item)}
                    />
                  </Grid> */}
                  </Stack>
                  <Stack sx={{ padding: "1rem", gap: "1rem" }}>
                    <Typography>{item.commentDesc}</Typography>
                  </Stack>
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "#767880",
                      padding: "0 1rem 1rem 1rem",
                    }}
                  >
                    {item.createDt && (
                      <>
                        <Typography>
                          {dayjs(item.createDt).format("MM-DD-YYYY")}
                        </Typography>
                        <Typography>
                          {dayjs(item.createDt).format("h:mm A")}
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Divider />
                </Grid>
              );
            }
          })}
      </Grid>
    </Box>
  );
};

export default Comment;
