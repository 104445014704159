import BidBreakDown from "../component/navbar/BidBreakDown";
import Narrative from "../component/navbar/Narrative";
import { Routepath } from "./routpath";
import VMSValue from "../component/navbar/vmsFolder/VMSValue";
import RateCalculator from "../component/navbar/RateCalculator";
import Dashboard from "../component/navbar/dashboard/DashboardTables";
import VMSModal from "../component/navbar/vmsFolder/VMSModal";
import ViewMyDb from "../component/navbar/dashboard/ViewMyDb";
import BrtoPr from "../component/navbar/BrtoPr";
// import RateCalculatorTabView from "../component/navbar/RateCalculatorTabView";
import Login from "../component/Login/Login";
import NotificationRoute from "../component/navbar/Notification/NotificationRoute";
import NotificationTables from "../component/navbar/Notification/NotificationTables";
import AuditingTable from "../component/navbar/Auditing/AuditingTable";
import { lazy } from "react";
import ReportsComp from "../component/navbar/Reports";
import ReleaseNote from "../component/navbar/ReleaseNote";
import Registration from "../component/Login/Registration";
import AeNotification from "../component/navbar/AeNotification";
import OnBoarding from "../component/navbar/AeNotification/OnBoarding";
import AccDashboard from "../component/navbar/accDashboard";
import DashboardTabsAll from "../component/navbar/DashboardTabsAll";
import { AccExedashboardData } from "../services/apiRequests";
import AccountExeContent from "../component/navbar/accDashboard/AccountExeContent";
import Roles from "../component/navbar/Roles";

const RateCalculatorTabView = lazy(() =>
  import("../component/navbar/RateCalculatorTabView")
);

export const subRoutes = [
  {
    path: Routepath.BidBreakDown,
    Element: BidBreakDown,
    screenId: 4,
  },
  { path: Routepath.RolesPage, Element: Roles },
  { path: Routepath.AccountExecutveMain, Element: AccountExeContent },
  { path: Routepath.Narrative, Element: Narrative, screenId: 0 },
  { path: Routepath.DashboardTabsAll, Element: DashboardTabsAll },
  { path: Routepath.Dashboard, Element: Dashboard, screenId: 1 },
  { path: Routepath.VMSValue, Element: VMSValue, screenId: 6 },
  { path: Routepath.BrtoPr, Element: BrtoPr, screenId: 5 },
  { path: Routepath.RateCalculator, Element: RateCalculator, screenId: 2 },
  { path: Routepath.ViewMyDb, Element: ViewMyDb, screenId: 3 },
  { path: Routepath.VMSModal, Element: VMSModal, screenId: 7 },
  { path: Routepath.ReportComp, Element: ReportsComp, screenId: 13 },
  {
    path: Routepath.NotificationTables,
    Element: NotificationTables,
    screenId: 9,
  },
  {
    path: Routepath.NotificationRoute,
    Element: NotificationRoute,
    screenId: 10,
  },
  {
    path: Routepath.RateCalculatorTabView,
    Element: RateCalculatorTabView,
    screenId: 5,
  },
  { path: Routepath.Login, Element: Login, screenId: 11 },
  { path: Routepath.Auditing, Element: AuditingTable, screenId: 12 },
  { path: Routepath.ReleaseNote, Element: ReleaseNote, screenId: 13 },
  { path: Routepath.Register, Element: Registration, screenId: 21 },
  { path: Routepath.AeNotification, Element: AeNotification, screenId: 22 },
  { path: Routepath.OnBording, Element: OnBoarding, screenId: 23 },
  { path: Routepath.AccDashboard, Element: AccDashboard },
];
