import { Paper, Table, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
export const PayBenefits = (props) => {
  const { value } = props;
  const payDetails = [
    {
      field: "Total Value of Pay & Benefits",

      amount: `$${value?.totalHourlyCost || "0.00"}`,
    },
    {
      field: "Total Pay % - Employee Cost",

      amount: `${value?.totalEmpCostPrTaxesPercentage || "0.00"}%`,
    },
    {
      field: "Mark Up(Using PR+Benefit Cost)",
      amount: `${value?.markUpBenefitPercentage || "0.00"}%`,
    },
    {
      field: "Mark Up(Using PR Only)",
      amount: `${value?.markUpPr || "0.00"}%`,
    },
    {
      field: "Net Margin (Valid for C2C Only)",
      amount: `$${value?.netMarginAmount || "0.00"}`,
    },
  ];
  return (
    <Paper sx={{ height: "auto", overflow: "hiden" }}>
      <div style={{ height: "100%", overflow: "auto" }}>
        <Table
          sx={{
            width: "300px",
            //   "& .MuiTableCell-root": {
            //     border: "1px solid #c4c4c4",
            //   },
            position: "relative",
            //   overflowX: "auto",
          }}
        >
          <TableRow sx={{ width: "100%" }}>
            <TableCell
              variant="head"
              sx={{
                fontWeight: 700,
                color: "#44A0E3",
                textAlign: "left",
                padding: "10px",
              }}
            >
              Pay
            </TableCell>

            <TableCell
              variant="head"
              sx={{
                fontWeight: 700,
                color: "#44A0E3",
                textAlign: "left",
                padding: "10px",
              }}
            >
              Value
            </TableCell>
          </TableRow>
          {payDetails.map((row) => (
            <TableRow>
              <TableCell sx={{ textAlign: "left", padding: "10px" }}>
                {row.field}
              </TableCell>
              <TableCell sx={{ textAlign: "left", padding: "10px" }}>
                {row.amount}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </div>
    </Paper>
  );
};
