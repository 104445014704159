import React from "react";
import CommonContainer from "../../CommonContainer/CommonContainer";

const Narrative = () => {
  return (
    <div>
      <CommonContainer />
    </div>
  );
};

export default Narrative;
