import { configureStore } from "@reduxjs/toolkit";
// import createSagaMiddleware from 'redux-saga';
import { persistStore } from "redux-persist";
import { createReducer } from "./slices/createReducer";

function configStore() {
  // const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      // sagaMiddleware,
    ],
  });

  return {
    persistor: persistStore(store),
    store,
  };
}

export default configStore;
