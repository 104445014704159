import * as React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { map } from "lodash";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const ReleaseNote = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const data = [
    {
      version: "Version 1.2.0",
      title: "Managing Status and Notifications",
      date: "May-22-2024",

      description: [
        "The recruiter has submitted an application incorrectly. They can change the status back to pending so the recruiter can edit and submit it again.",
        "If the submitted case is reverted to pending, the notification for the Account Manager or Account Executive will be removed.",
        "The reported calculation issues resolved. Please contact admin team for any further issues.",
        "For the CANADA Calculation Option, the system set the default value for the 'Holiday' field.",
      ],
    },
    {
      version: "Version 1.3.0",
      title: "Reports And Onboarding",
      date: "June-19-2024",
      description: [
        "Reports have been added to our system. Within this section, you can find two tabs: Summary and Monthly Commission.",
        "In the Summary tab, the reports are filtered by insertion date range, country, roles, recruiter, pre ID, and employment type. In this table, you can view reports with applied filters, where you can see the count of candidates and clients, along with the total commission",
        "In the Monthly Commission tab, reports are filtered by insertion date range, country, roles, recruiter, and BU. Here, you can view reports based on monthly commission, including the number of approved candidates, the number of declined candidates, and the total commission.",
        "We have an Onboarding page that is visible only to HR and AE. On this page, approved candidates are displayed, and either HR or AE should be responsible for onboarding the candidate.",
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          padding: "40px 10px 40px 10px",

          background: "#E2E2E4",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Rate Calculator
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: 224,
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRight: "1px solid #D9D9D9",
          }}
        >
          <Typography variant="h6">Release Notes</Typography>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider", paddingLeft: "10px" }}
          >
            {map(data, (item, index) => {
              return (
                <Tab
                  label={item.version + " " + item.date}
                  {...a11yProps(index)}
                  sx={{
                    alignItems: "flex-start",
                    fontSize: "14px",
                    color: "#000",
                    whiteSpace: "nowrap",
                  }}
                />
              );
            })}
          </Tabs>
        </Box>
        {map(data, (item, index) => {
          return (
            <TabPanel value={value} index={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Typography variant="h6" sx={{ color: "#000" }}>
                  {item.version}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: "#000" }}>
                  {item.date}
                </Typography>
                <Typography variant="h6" sx={{ color: "#000" }}>
                  {item.title}
                </Typography>
                <ul
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}
                >
                  {map(item.description, (desc, descIndex) => (
                    <li key={descIndex}>{desc}</li>
                  ))}
                </ul>
              </Box>
            </TabPanel>
          );
        })}
      </Box>
    </>
  );
};

export default ReleaseNote;
