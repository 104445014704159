import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";

const CommissionMonthReport = ({ rows, columns, count }) => {
  const [pageState, setPageState] = useState({
    page: 0,
    pageSize: 10,
  });

  const handlePagination = (pages) => {
    setPageState({
      page: pages.page,
      pageSize: pages.pageSize,
    });
  };

  return (
    <>
      <DataGrid
        rows={rows ?? []}
        key={rows.length}
        getRowId={(row) => row.rcCommissionReportsId}
        columns={columns ?? []}
        rowCount={count}
        pageSizeOptions={[5, 10, 20, 30]}
        paginationMode="server"
        onPaginationModelChange={handlePagination}
        paginationModel={pageState}
        autoHeight
        localeText={{ noRowsLabel: "No data found" }}
        sx={{
          marginTop: "1.8rem",
          backgroundColor: "white",
          "& .MuiDataGrid-cell": {
            "&:first-of-type": {
              fontWeight: "bold",
              fontSize: "1.1em",
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "0.9rem",
            fontWeight: "700",
            color: "#000000",
          },
          "& .MuiDataGrid-row": {
            color: "black",
            textAlign: "center",
          },
        }}
      />
    </>
  );
};

export default CommissionMonthReport;
