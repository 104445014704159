import { dashboard_api } from "../constance/DashboardJson";
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

let initialState = {
  id: -1,
  userAction: "",
  userName: "",
  vmsModal: "",
  vmsStats: {
    vmsId: -1,
    action: "",
  },
  typResouce: "",
  screenId: 0,
  visibleAccess: 0,
  notificationCount: 0,
  rateCalciData: { ...dashboard_api },
  matchedLoookupPayload: {},
  dashbordAccess: true,
  notoficatioActionAcs: "ro",
  bidBrakeDownAcess: true,
  filter: {
    bussinessUnintName: "none",
    clientName: "none",
    country: "none",
    empType: "none",
    recruiterName: "none",
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
    role: "none",
    preId: null,
    candidateStatus: "CMP",
  },
  Notification: {
    event: "",
    id: "",
  },
  AeNotification: {
    event: "",
    id: "",
  },
};
const fieldStore = createSlice({
  name: "dropedField",
  initialState,
  reducers: {
    setDashboardChild: (state, action) => {
      state.userAction = action.payload.userAction;

      state.id = action.payload.id;
      state.userName = action.payload.userName;
    },
    setNotificationActionAcs: (state, action) => {
      state.notoficatioActionAcs = action.payload;
    },
    setVmsModal: (state, action) => {
      state.vmsModal = action.payload;
    },
    setscreenId: (state, action) => {
      state.screenId = action.payload;
    },
    clearScreenId: (state, action) => {
      state.screenId = 0;
    },
    setVmsStates: (state, action) => {
      state.vmsStats = {
        vmsId: action.payload.vmsId,
        action: action.payload.action,
      };
    },
    setTypeOfResource: (state, action) => {
      state.typResouce = action.payload;
    },
    setMatchedLookupobj: (state, action) => {
      state.matchedLoookupPayload = action.payload;
    },
    updateCandidateDetails: (state, action) => {
      state.rateCalciData = action.payload;
    },
    setNotificationDetails: (state, action) => {
      state.Notification = {
        Event: action.payload.Event,
        id: action.payload.id,
      };
    },

    setAeNotificationDetails: (state, action) => {
      state.AeNotification = {
        Event: action.payload.Event,
        id: action.payload.id,
      };
    },

    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    screenAccess: (state, action) => {
      return {
        ...state,
        loginData: action.payload,
      };
    },
    setReportFilters: (state, action) => {
      state.filter = action.payload;
    },
    ClearReportFilters: (state, action) => {
      state.filter = initialState.filter;
    },
    removeScreenAccess: (state, action) => {
      state.loginData = action.payload;
    },
    setVisibleAccess: (state, action) => {
      state.visibleAccess = action.payload;
    },
    clearVisibleAccess: (state, action) => {
      state.visibleAccess = initialState.visibleAccess;
    },
  },
});
export const {
  setDashboardChild,
  setscreenId,
  clearScreenId,
  setVmsModal,
  setVmsStates,
  setNotificationDetails,
  setAeNotificationDetails,
  updateCandidateDetails,
  setMatchedLookupobj,
  setNotificationCount,
  setReportFilters,
  ClearReportFilters,
  setVisibleAccess,
  clearVisibleAccess,
  setNotificationActionAcs,
  screenAccess,
  setTypeOfResource,
  removeScreenAccess,
} = fieldStore.actions;
export default fieldStore.reducer;
