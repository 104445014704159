import React from "react";
import { useState, useEffect } from "react";
import {
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableBody,
  Table,
  Box,
  Button,
  TablePagination,
  Icon,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
// import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { getAuditingTable } from "../../../services/apiRequests";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";
import useLoader from "../../../Loader/useLoader";
import { useScreenId } from "../../../hooks/useScreenId";
import toast from "react-hot-toast";
const columns = [
  "Application Id",
  "User Name",
  "Country",
  "Screen Name",
  "Action Type",
  "Action Date",
];

const AuditingTable = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalpages, setTotalPages] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [candidateList, setCandidateDetails] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  useScreenId(
    14,
    () => {
      getAuditing();
    },
    []
  );
  const getAuditing = async () => {
    try {
      showLoader();
      const dataRespond = await getAuditingTable();
      setCandidateDetails(dataRespond.data.content);
      setTotalPages(dataRespond.data.totalElements);
      // dispatch(setNotificationCount(dataRespond.data.totalElements));
    } catch {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
  };

  const [selectedId, setSelectedId] = useState([]);

  const Title = styled(Box)({
    width: "100%",
    fontWeight: "500",
    backgroundColor: "white",
    display: "flex",
    // position: "sticky",
    flexDirection: "column",

    // top: "0px",
    //WebkitJustifyContent: "flex-start",
    //justifyContent: "flex-start",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "20px",
    "$ .MuiDataGrid-columnHeaderRow": { backgroundColor: "yellow" },
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const accessType = ["Submited", "Create", "Edit", "View", "Delete"];
  const onChange = (e) => {
    dayjs(e).format("YYYY-MM-DD");
  };

  return (
    <Title>
      <h3 style={{ marginBottom: "20px" }}>Audit Table</h3>
      {/* <Box sx={{ display: "flex", gap: "30px", flexDirection: "column" }}> */}
      {/* <Paper
          sx={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "row",
            justifyContent: "center",
            gap: "30px",
            padding: "15px",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "700",
              }}
            >
              User Name
            </Typography>
            <TextField sx={{ width: "210px" }} />
          </Box>
          <Box>
            <Typography
              sx={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "700",
              }}
            >
              Action Type
            </Typography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={accessType}
              sx={{ width: "210px" }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "700",
              }}
            >
              From Date
            </Typography>
            <DatePicker
              sx={{
                color: "black",
                "& .MuiInputBase-input": {
                  fontSize: "14px",
                },
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: "blue !important", // Replace with your desired color
                },
                width: "210px",
              }}
              id="outlined-basic !important"
              variant="outlined !important"
              onChange={(e) => {
                if (dayjs(e).isValid()) {
                  onChange(e);
                }
              }}
              maxDate={dayjs()}
              className="defaultFieldStruc"
            ></DatePicker>
          </Box>
          <Box>
            <Typography
              sx={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "700",
              }}
            >
              To Date
            </Typography>
            <DatePicker
              sx={{
                color: "black",
                "& .MuiInputBase-input": {
                  fontSize: "14px",
                },
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: "blue !important", // Replace with your desired color
                },
                width: "210px",
              }}
              id="outlined-basic !important"
              variant="outlined !important"
              onChange={(e) => {
                if (dayjs(e).isValid()) {
                  onChange(e);
                }
              }}
              maxDate={dayjs()}
              className="defaultFieldStruc"
            ></DatePicker>
          </Box>
          <Button variant="contained">Search</Button>
        </Paper> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {candidateList && (
          <TableContainer sx={{ width: "90%" }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: "1px solid  #D9D9D9",
                    background: "#F7F7F7",
                  }}
                >
                  {columns.map((rowItem) => (
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {rowItem}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {candidateList.map((objItem) => {
                  return (
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          cursor: "pointer",
                          padding: "8px 16px 8px 16px",
                        }}
                      >
                        {objItem.applicationId}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          cursor: "pointer",
                          padding: "8px 16px 8px 16px",
                        }}
                      >
                        {objItem.recruiterName}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          cursor: "pointer",
                          padding: "8px 16px 8px 16px",
                        }}
                      >
                        {objItem.country}
                      </TableCell>

                      <TableCell
                        sx={{
                          fontSize: "14px",
                          cursor: "pointer",
                          padding: "8px 16px 8px 16px",
                        }}
                      >
                        {objItem.screenDefn}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          cursor: "pointer",
                          padding: "8px 16px 8px 16px",
                        }}
                      >
                        {objItem.actionType}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "14px",
                          cursor: "pointer",
                          padding: "8px 16px 8px 16px",
                        }}
                      >
                        {objItem.actionDate}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              component="div"
              count={totalpages}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </Box>
      {/* </Box> */}
    </Title>
  );
};

export default AuditingTable;
