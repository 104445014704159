import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TextFields from "../../../CommonContainer/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  setDashboardChild,
  updateCandidateDetails,
} from "../../../reduxApp/fieldStore";
import { styled } from "@mui/material/styles";
import DateField from "../../../CommonContainer/DateField";
import {
  getCandidateById,
  updateCandidateById,
  getLookup,
  submitMyrecord,
  getVmsDataByPagesandRows,
  PostComment,
  terminationCandidate,
  calculateData,
  cancelCandidate,
} from "../../../services/apiRequests";
import { Dropdown } from "../../../CommonContainer/DropDown";
import { groupLookup } from "../../../utils/helpers";
import {
  dashboard_api,
  database_List,
  renderCandidateStatus,
} from "../../../constance/DashboardJson";
import TwoTextFields from "../../../CommonContainer/TwoTextField";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty, find } from "lodash";
import {
  calculate_Errormsg,
  dashboard_Errormsg,
  dashboard_Errormsg_submit,
  errorMsg,
} from "../../../constance/validityItems";
import { isValid } from "../../../utils/validationField";
import dayjs from "dayjs";
import useLoader from "../../../Loader/useLoader";
import { MarkUpTable } from "../../../CommonContainer/MarkUpTable";
import useAuth from "../../../hooks/useAuth";
import { B2BIMariginLimits } from "../../../CommonContainer/B2BIMariginLimits";
import { PayBenefits } from "../../../CommonContainer/PayBenefits";
import { useScreenId } from "../../../hooks/useScreenId";
import { Textarea } from "@mui/joy";
import CircleIcon from "@mui/icons-material/Circle";
import toast from "react-hot-toast";

const SquareRadio = styled(Radio)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    borderRadius: "0%",
    width: "20px",
    marginLeft: "3px",
    height: "20px",
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    borderRadius: "0%",
    width: "20px",
    height: "20px",
  },
}));

const ViewMyDb = ({ onValueChange }) => {
  const { getAccess } = useAuth();
  const { noAccess: totalComoer24 } = getAccess("10");
  const { noAccess: reMntlyCmsPH } = getAccess("11");
  const { noAccess: tcmnsPH } = getAccess("12");
  const { noAccess: hrlyRate } = getAccess("13");
  const { noAccess: Boarding } = getAccess("15");
  const { noAccess: termination } = getAccess("16");
  const { noAccess: calcCountry } = getAccess("17");
  const { canReadWrite: accessForAP } = getAccess("26");
  const { canReadWrite: calcelButton } = getAccess("28");

  const visibleAccess = {
    recuiterMonthlyCommissionPerHour: totalComoer24,
    totalCommissionForMonthPro: reMntlyCmsPH,
    recruiterMonthlyCommissionPro: tcmnsPH,
    hourlyRate: hrlyRate,
    onboardingDt: Boarding,
    terminationDt: termination,
    calculationCountry: calcCountry,
  };

  const [apiTrigered, setApitrigered] = useState(3);
  const navigate = useNavigate();
  const [calculatedOnce, setCalculatorOnce] = useState(false);
  const [dataChanged, setDataChanged] = useState();
  const [errorText, setErrorText] = useState({});
  const [candidateDetails, setCandidateDetails] = useState({});
  const [holidayToggle, setHoliddayToggle] = useState(false);
  const [terminateDialog, setTerminateDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [holidayDataToggle, setHolidayDataTggle] = useState({});
  const [calCountry, setCalcCountry] = useState();
  const employIntegration = useSelector((state) => {
    return state.dropedField;
  });
  const candidateList = useSelector((state) => state.dropedField);
  const [vmsForBu, setvmsForBu] = useState();
  const authRedux = useSelector((state) => state.auth);
  const [lookupGroup, setLookupGroup] = useState({});
  const [wholeRawlookup, setWholeRawlookup] = useState([]);
  const [VMSFee, setVmsFee] = useState([]);
  const [apiIntegrated, setApiIntegrated] = useState(false);
  const dispatch = useDispatch();
  const [showLoader, hideLoader] = useLoader();

  useScreenId(
    3,
    () => {
      vmsValue();
    },
    [],
    [apiIntegrated],
    [employIntegration]
  );

  const findFullform = (shortHand) => {
    const status = lookupGroup["CANDIDATE_STATUS"];
    const longHand = find(status, (key) => key.value === shortHand);
    return longHand?.label;
  };

  const openTermDilog = () => {
    setTerminateDialog(true);
  };

  const closeTermDilog = () => {
    setTerminateDialog(false);
  };

  const openCancelDilog = () => {
    setCancelDialog(true);
  };

  const closeCancelDilog = () => {
    setCancelDialog(false);
  };

  const vmsValue = async () => {
    try {
      showLoader();
      if (
        employIntegration.userAction === "View" ||
        employIntegration.userAction === "Edit"
      ) {
        let response = await getCandidateById(
          "getbycandidateid",
          employIntegration.id
        );

        const res = await getLookup();
        if (response.data.hasOwnProperty("candidateDetailId")) {
          setCalculatorOnce(true);
          setDataChanged(true);
        }

        if (response.data.hasOwnProperty("candidateDetailId")) {
          setCalculatorOnce(true);
          setDataChanged(true);
        }

        if (res.status === 200) {
          setWholeRawlookup(res.data);
          const vmsCompany = (await getVmsDataByPagesandRows(0, 1000)).data;
          const BuforVms = vmsCompany.content.reduce((acc, current) => {
            if (acc[current.bussinessUnit]) {
              acc[current.bussinessUnit].push({
                label: current.customerNameWithFee,
                value: current.vmsId,
                markup: current.allowedMarkupPct,
              });
            } else {
              acc[current.bussinessUnit] = [
                {
                  label: current.customerNameWithFee,
                  value: current.vmsId,
                  markup: current.allowedMarkupPct,
                },
              ];
            }

            return acc;
          }, {});
          setvmsForBu(BuforVms);
          const vmsFor = BuforVms[response.data.candidateBusinessUnit]
            ? BuforVms[response.data.candidateBusinessUnit]
            : [];
          setVmsFee({ VMS: vmsFor });
          const groupedLookup = groupLookup(res.data);
          //   matchWithValues(response.data, groupedLookup, vmsArray);
          setCandidateDetails({ ...response.data });
          setLookupGroup(groupedLookup);
        }
      } else if (employIntegration.userAction === "Post") {
        setCandidateDetails({ ...dashboard_api });
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const checkMyvalidity = () => {
    let isAllValid = true;
    let targetDiv = "";
    let errorList = { ...dashboard_Errormsg };
    for (let identity in errorList) {
      if (
        !(
          [
            "vacationNoOfDays",
            "sickLeaveNoOfDays",
            "holidays",
            "candidateInsurenceType",
            "refferalAmount",
            "immgirationType",
            "otherExpenses",
            "otherExpensesHourly",
          ].includes(identity) &&
          candidateDetails.candidateTypeOfResource == "C2C"
        )
      ) {
        if (
          !(
            [
              "candidateInsurenceType",
              "holidays",
              "immgirationType",
              "otherExpensesHourly",
              "vacationNoOfDays",
            ].includes(identity) && candidateDetails.candidateCountry === "CAD"
          )
        ) {
          let isValidRes = isValid(
            identity,
            candidateDetails[identity],
            candidateDetails
          ); //returns errors list withrespected error msg
          errorList[identity] = isValidRes;

          if (isValidRes) {
            targetDiv = targetDiv === "" ? identity : targetDiv;
            isAllValid = false;
          }
        }
      }
    }
    if (
      !candidateDetails.comments &&
      (candidateDetails.candidateStatus === "CMP" ||
        candidateDetails.candidateStatus === "ONB")
    ) {
      targetDiv = "comments";
      isAllValid = false;
      errorList = { comments: "Please add comments" };
    }
    return {
      isAllValid: isAllValid,
      targetDiv: targetDiv,
      errorList: errorList,
    };
  };

  function HandleChange(e, name, value, Vmsvalue) {
    if (name === "calculationCountry") {
      setCalcCountry(e);
    }
    setDataChanged(true);
    const wholeFields = {
      ...candidateDetails,
      [name]: name === "candidateStartDt" ? dayjs(e).format("YYYY-MM-DD") : e,
      [name]: name === "terminationDt" ? dayjs(e).format("YYYY-MM-DD") : e,
      [name]: name === "onboardingDt" ? dayjs(e).format("YYYY-MM-DD") : e,
      // [name]: name === "comments" ? e : e,
    };

    if (name === "comments") {
      wholeFields["comments"] = e === "" ? "" : e; // Clears the comments when empty
    }

    errorText.hasOwnProperty(name) &&
      setErrorText({
        ...errorText,
        [name]: e === "" ? errorMsg[name] : "",
      });
    if (name === "immgirationType") {
      if (e !== "4000") {
        wholeFields["tnVisaPremiumYN"] = null;
        wholeFields["tnVisaCountry"] = null;
      }
      wholeFields["immgirationType"] = e;
    }
    if (name === "candidateCountry") {
      wholeFields["leaveStateCd"] =
        candidateDetails.candidateCountry === "USA"
          ? wholeFields.leaveStateCd
          : "";
      wholeFields["candidateDetails"] = e;
    }
    if (name === "leaveStateCd" && e && candidateDetails.leaveStateCd !== e) {
      setHoliddayToggle(true);
      wholeFields.leaveStateCd = e;
      const constFindObj = wholeRawlookup.find((obj) => obj.lookupCd === e);
      setHolidayDataTggle(constFindObj);
    }
    if (name === "candidateBusinessUnit") {
      const vmsFor = e ? (vmsForBu[e] ? vmsForBu[e] : []) : VMSFee.VMS;
      wholeFields.vmsFee = "";
      wholeFields["allowedMarkupPct"] = "";
      setVmsFee({ VMS: vmsFor });
    }
    if (name === "provinceState" && e) {
      const defineStatotoryLeave = lookupGroup["PROVINCES_STRY_HLDY"].find(
        (key) => e === key.value
      );
      const defineSickLeave = lookupGroup["PROVINCES_STRY_SICK"].find(
        (key) => e === key.value
      );

      wholeFields["sickLeaveNoOfDays"] = defineSickLeave?.leaveValue;
      wholeFields["statutoryHolidays"] = defineStatotoryLeave?.leaveValue;
    }
    if (name === "vmsFee") {
      const getMarkup = vmsForBu[candidateDetails.candidateBusinessUnit].find(
        (obj) => obj.value === e
      );
      const markupValue = getMarkup?.markup;
      wholeFields["allowedMarkupPct"] = markupValue
        ? markupValue === "NA"
          ? "No Markup"
          : markupValue
        : "--";
    }
    if (name === "candidateCountry") {
      wholeFields["candidateBusinessUnit"] = "";
      candidateDetails.candidateTypeOfResource != "C2C"
        ? (wholeFields["candidateTypeOfResource"] = "")
        : (wholeFields["candidateTypeOfResource"] =
            candidateDetails.candidateTypeOfResource);
    }

    setCandidateDetails(wholeFields);
  }

  const createCheckbox = useMemo(() => {
    return (
      <>
        <RadioGroup
          id="tnVisaCountry"
          row
          name="tnVisaCountry"
          value={candidateDetails.tnVisaCountry}
          onChange={(e) => {
            setCandidateDetails({
              ...candidateDetails,
              tnVisaCountry: e.target.value,
            });
            setErrorText({ ...errorText, tnVisaCountry: "" });
          }}
          aria-labelledby="demo-row-radio-buttons-group-label"
        >
          <FormControlLabel
            label="Canada"
            value="CA"
            control={
              <Checkbox
                name="CA"
                checked={candidateDetails.tnVisaCountry === "CA"}
                onChange={(e) => {
                  setCandidateDetails({
                    ...candidateDetails,
                    tnVisaCountry: e.target.value,
                  });
                  setErrorText({ ...errorText, tnVisaCountry: "" });
                }}
              />
            }
          />
          <FormControlLabel
            label="Mexico"
            value="MX"
            control={
              <Checkbox
                name="MX"
                checked={candidateDetails.tnVisaCountry === "MX"}
                onChange={(e) => {
                  setCandidateDetails({
                    ...candidateDetails,
                    tnVisaCountry: e.target.value,
                  });
                  setErrorText({ ...errorText, tnVisaCountry: "" });
                }}
              />
            }
          />
        </RadioGroup>
        <p style={{ color: "#d32f2f", fontSize: "0.8rem" }}>
          {errorText?.tnVisaCountry ?? ""}
        </p>
        {candidateDetails.tnVisaCountry && (
          <>
            <RadioGroup
              id="tnVisaPremiumYN"
              row
              onChange={(e) => {
                setCandidateDetails({
                  ...candidateDetails,
                  tnVisaPremiumYN: e.target.value,
                });
                setErrorText({ ...errorText, tnVisaPremiumYN: "" });
              }}
              value={candidateDetails.tnVisaPremiumYN}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="tnVisaPremiumYN"
            >
              <FormControlLabel
                label="Consulate"
                value="NO"
                control={<SquareRadio />}
              />
              <FormControlLabel
                label="Petition"
                value="YES"
                control={<SquareRadio />}
              />
            </RadioGroup>
            <p style={{ color: "#d32f2f", fontSize: "0.8rem" }}>
              {errorText?.tnVisaPremiumYN ?? ""}
            </p>
          </>
        )}
      </>
    );
  }, [errorText, candidateDetails]);

  const checkMyvalidityOnSubmit = () => {
    let isAllValid = true;
    let targetDiv = "";
    let errorList = { ...dashboard_Errormsg_submit };

    for (let identity in errorList) {
      if (
        !(
          [
            "vacationNoOfDays",
            "sickLeaveNoOfDays",
            "holidays",
            "candidateInsurenceType",
            "refferalAmount",
            "immgirationType",
            "otherExpenses",
            "otherExpensesHourly",
          ].includes(identity) &&
          candidateDetails.candidateTypeOfResource == "C2C"
        )
      ) {
        if (
          !(
            [
              "candidateInsurenceType",
              "holidays",
              "immgirationType",
              "otherExpensesHourly",
              "vacationNoOfDays",
            ].includes(identity) && candidateDetails.candidateCountry === "CAD"
          )
        ) {
          let isValidRes = isValid(
            identity,
            candidateDetails[identity],
            candidateDetails
          ); //returns errors list withrespected error msg
          errorList[identity] = isValidRes;
          if (isValidRes) {
            targetDiv = targetDiv === "" ? identity : targetDiv;
            isAllValid = false;
          }
        }
      }
    }

    return {
      isAllValid: isAllValid,
      targetDiv: targetDiv,
      errorList: errorList,
    };
  };

  const toCheckValidityByTypeofResource = (name) => {
    return (
      [
        "vacationNoOfDays",
        "sickLeaveNoOfDays",
        "holidays",
        "candidateInsurenceType",
        "refferalAmount",
        "immgirationType",
        "otherExpenses",
        "otherExpensesHourly",
      ].includes(name) && candidateDetails.candidateTypeOfResource === "C2C"
    );
  };

  const terminationCnd = async () => {
    try {
      showLoader();

      const payload = {
        applicationId: candidateDetails.applicationId,
        terminationDt: candidateDetails.terminationDt,
        candidateStatus: "Terminated",
        candidateDetailId: candidateDetails.candidateDetailId,
      };
      const res = await terminationCandidate(payload);
      if (res.status === 200) {
        toast.success(`${candidateDetails.candidateName} is Terminated`);
        closeTermDilog();
        setBackTodashboard();
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const cancelCnd = async (id) => {
    try {
      showLoader();
      const payload = {
        commentDesc: candidateDetails.comments,
        applicationId: candidateDetails.applicationId,
      };
      const commentRes = await PostComment(payload);
      if (commentRes.status === 200) {
        setCandidateDetails((prevDetails) => ({
          ...prevDetails,
          comments: "",
        }));
        const res = await cancelCandidate(payload.applicationId);
        if (res?.status === 200) {
          toast.success(`canceled successfully `);
          closeCancelDilog();
          setBackTodashboard();
        }
      }
    } catch (error) {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
  };

  const saveComment = async (data) => {
    try {
      showLoader();
      const payload = {
        commentDesc: data.comments,
        applicationId: data.applicationId,
      };
      const commentRes = await PostComment(payload);
      if (commentRes.status === 200) {
        setCandidateDetails((prevDetails) => ({
          ...prevDetails,
          comments: "",
        }));
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const saveAndpost = async () => {
    try {
      showLoader();
      const { isAllValid, targetDiv, errorList } = checkMyvalidity();

      if (isAllValid) {
        let tempData = {
          ...candidateDetails,
        };

        tempData.vacationNoOfDays =
          candidateDetails.candidateTypeOfResource === "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.vacationNoOfDays &&
              candidateDetails.vacationNoOfDays !== "" &&
              candidateDetails.vacationNoOfDays >= 0
              ? parseInt(candidateDetails.vacationNoOfDays)
              : 0
            : candidateDetails.vacationNoOfDays !== ""
            ? candidateDetails.vacationNoOfDays
            : 0;

        tempData.sickLeaveNoOfDays =
          candidateDetails.candidateTypeOfResource === "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.sickLeaveNoOfDays &&
              candidateDetails.sickLeaveNoOfDays !== "" &&
              candidateDetails.sickLeaveNoOfDays >= 0
              ? parseInt(candidateDetails.sickLeaveNoOfDays)
              : 0
            : candidateDetails.sickLeaveNoOfDays !== ""
            ? candidateDetails.sickLeaveNoOfDays
            : 0;

        tempData.otherExpenses =
          candidateDetails.candidateTypeOfResource === "C2C"
            ? candidateDetails.otherExpenses &&
              candidateDetails.otherExpenses !== "" &&
              candidateDetails.otherExpenses >= 0
              ? parseInt(candidateDetails.otherExpenses)
              : parseInt("0")
            : candidateDetails.otherExpenses !== ""
            ? candidateDetails.otherExpenses
            : parseInt("0");

        tempData.otherExpensesHourly =
          candidateDetails.candidateTypeOfResource === "C2C" ||
          candidateDetails.candidateCountry === "CAD"
            ? candidateDetails.otherExpensesHourly &&
              candidateDetails.otherExpensesHourly !== "" &&
              candidateDetails.otherExpensesHourly >= 0
              ? parseInt(candidateDetails.otherExpensesHourly)
              : 0
            : candidateDetails.otherExpensesHourly !== ""
            ? candidateDetails.otherExpensesHourly
            : 0;

        tempData.refferalAmount =
          candidateDetails.candidateTypeOfResource === "C2C"
            ? candidateDetails.refferalAmount &&
              candidateDetails.refferalAmount !== "" &&
              candidateDetails.refferalAmount >= 0
              ? parseInt(candidateDetails.refferalAmount)
              : 0
            : candidateDetails.refferalAmount !== ""
            ? candidateDetails.refferalAmount
            : 0;
        tempData.perDiemCount =
          candidateDetails.perDiemCount !== ""
            ? candidateDetails.perDiemCount
            : 0;
        tempData.candidateStatus =
          tempData.candidateStatus === "NCR" ? "PEN" : tempData.candidateStatus;

        tempData["recruiterCountry"] = employIntegration.loginData.country;

        tempData["loginUserId"] = employIntegration.loginData.id;
        setApitrigered(3);

        try {
          const dataRes = await updateCandidateById(
            tempData.candidateDetailId,
            tempData
          );

          if (dataRes.status === 200) {
            setCalculatorOnce(true);
            dispatch(
              setDashboardChild({
                id: dataRes.data.candidateDetailId,
                userAction: "Edit",
                userName: dataRes.data.candidateName,
              })
            );
            //   matchWithValues(dataRes.data, lookupGroup, VMSFee);

            setApiIntegrated(!apiIntegrated);
            setCandidateDetails(dataRes.data);
            if (
              tempData.candidateStatus === "CMP" ||
              // tempData.candidateStatus === "SUB" ||
              tempData.candidateStatus === "ONB"
            ) {
              saveComment(tempData);
            }
          }

          setErrorText({});
        } catch {}
      } else if (!isAllValid) {
        let scrollGetElement = document.getElementById(targetDiv);
        scrollGetElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        setErrorText(errorList);
      }
    } catch (error) {
      toast.error("Server Error");
    } finally {
      hideLoader();
    }
  };

  // const calculate = async () => {
  //   try {
  //     showLoader();
  //     let targetDiv = "";
  //     let isAllValid = true;
  //     let errorList = { ...calculate_Errormsg };
  //     for (let identity in errorList) {
  //       if (
  //         !(
  //           [
  //             "vacationNoOfDays",
  //             "sickLeaveNoOfDays",
  //             "holidays",
  //             "candidateInsurenceType",
  //             "refferalAmount",
  //             "immgirationType",
  //             "otherExpenses",
  //             "otherExpensesHourly",
  //           ].includes(identity) &&
  //           candidateDetails.candidateTypeOfResource == "C2C"
  //         )
  //       ) {
  //         if (
  //           !(
  //             [
  //               "candidateInsurenceType",
  //               "holidays",
  //               "immgirationType",
  //               "otherExpensesHourly",

  //               "vacationNoOfDays",
  //             ].includes(identity) &&
  //             candidateDetails.candidateCountry === "CAD"
  //           )
  //         ) {
  //           let isValidRes = isValid(
  //             identity,
  //             candidateDetails[identity],
  //             candidateDetails
  //           ); //returns errors list withrespected error msg
  //           errorList[identity] = isValidRes;

  //           if (isValidRes) {
  //             targetDiv = targetDiv === "" ? identity : targetDiv;
  //             isAllValid = false;
  //           }
  //         }
  //       }
  //     }

  //     if (isAllValid) {
  //       let tempData = {
  //         ...candidateDetails,
  //       };

  //       tempData.vacationNoOfDays =
  //         candidateDetails.candidateTypeOfResource == "C2C" ||
  //         candidateDetails.candidateCountry === "CAD"
  //           ? candidateDetails.vacationNoOfDays &&
  //             candidateDetails.vacationNoOfDays >= 0
  //             ? candidateDetails.vacationNoOfDays
  //             : parseInt("0")
  //           : candidateDetails.vacationNoOfDays;

  //       tempData.sickLeaveNoOfDays =
  //         candidateDetails.candidateTypeOfResource == "C2C" ||
  //         candidateDetails.candidateCountry === "CAD"
  //           ? candidateDetails.sickLeaveNoOfDays &&
  //             candidateDetails.sickLeaveNoOfDays >= 0
  //             ? candidateDetails.sickLeaveNoOfDays
  //             : parseInt("0")
  //           : candidateDetails.sickLeaveNoOfDays;

  //       tempData.otherExpenses =
  //         candidateDetails.candidateTypeOfResource == "C2C"
  //           ? candidateDetails.otherExpenses &&
  //             candidateDetails.otherExpenses >= 0
  //             ? candidateDetails.otherExpenses
  //             : parseInt("0")
  //           : candidateDetails.otherExpenses;

  //       tempData.otherExpensesHourly =
  //         candidateDetails.candidateTypeOfResource == "C2C" ||
  //         candidateDetails.candidateCountry === "CAD"
  //           ? candidateDetails.otherExpensesHourly &&
  //             candidateDetails.otherExpensesHourly >= 0
  //             ? candidateDetails.otherExpensesHourly
  //             : parseInt("0")
  //           : candidateDetails.otherExpensesHourly;

  //       tempData.refferalAmount =
  //         candidateDetails.candidateTypeOfResource == "C2C"
  //           ? candidateDetails.refferalAmount &&
  //             candidateDetails.refferalAmount >= 0
  //             ? candidateDetails.refferalAmount
  //             : parseInt("0")
  //           : candidateDetails.refferalAmount;

  //       tempData["recruiterCountry"] = employIntegration.loginData.country;

  //       tempData["loginUserId"] = employIntegration.loginData.id;
  //       setApitrigered(3);

  //       try {
  //         const dataRes = await calculateData(tempData);

  //         if (dataRes.status === 200) {
  //           setCalculatorOnce(true);
  //           dispatch(updateCandidateDetails(dataRes.data));

  //           dispatch(
  //             setDashboardChild({
  //               id: dataRes.data.candidateDetailId,
  //               userAction: "Edit",
  //               userName: dataRes.data.candidateName,
  //             })
  //           );
  //           //   matchWithValues(dataRes.data, lookupGroup, VMSFee);

  //           setApiIntegrated(!apiIntegrated);
  //           setCandidateDetails(dataRes.data);
  //           if (
  //             tempData.candidateStatus === "CMP" ||
  //             tempData.candidateStatus === "SUB" ||
  //             tempData.candidateStatus === "ONB"
  //           ) {
  //             saveComment(tempData);
  //           }
  //         }

  //         setErrorText({});
  //       } catch {}
  //     } else if (!isAllValid) {
  //       let scrollGetElement = document.getElementById(targetDiv);
  //       scrollGetElement.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });

  //       setErrorText(errorList);
  //     }
  //   } catch (error) {
  //     toast.error("Server Error");
  //   } finally {
  //     hideLoader();
  //   }
  // };

  function setBackTodashboard() {
    setCandidateDetails({ ...dashboard_api });
    // navigate(Routepath.DashboardTabsAll);
    navigate(-1);
    dispatch(
      setDashboardChild({
        userAction: "",
        id: "",
        userName: "",
      })
    );
  }

  useMemo(() => {
    setCalcCountry(candidateDetails.calculationCountry);
  }, [candidateDetails]);

  const ClearEvent = () => {
    vmsValue();
  };

  const submitmyEdit = async () => {
    const { isAllValid, targetDiv, errorList } = checkMyvalidityOnSubmit();

    let tempData = {
      ...candidateDetails,
    };
    tempData.calculationCountry =
      candidateList.loginData.roleCd === "RCTR" ||
      candidateList.loginData.roleCd === "ACCMNGR" ||
      candidateList.loginData.country === "India"
        ? candidateList.loginData.country
        : tempData.calculationCountry;

    tempData.candidateBusinessUnit =
      candidateDetails.candidateTypeOfResource == "C2C"
        ? tempData.candidateBusinessUnit
          ? tempData.candidateBusinessUnit
          : "NON"
        : tempData.candidateBusinessUnit;

    tempData.vacationNoOfDays =
      candidateDetails.candidateTypeOfResource === "C2C" ||
      candidateDetails.candidateCountry === "CAD"
        ? candidateDetails.vacationNoOfDays &&
          candidateDetails.vacationNoOfDays !== "" &&
          candidateDetails.vacationNoOfDays >= 0
          ? parseInt(candidateDetails.vacationNoOfDays)
          : 0
        : candidateDetails.vacationNoOfDays !== ""
        ? candidateDetails.vacationNoOfDays
        : 0;

    tempData.sickLeaveNoOfDays =
      candidateDetails.candidateTypeOfResource === "C2C" ||
      candidateDetails.candidateCountry === "CAD"
        ? candidateDetails.sickLeaveNoOfDays &&
          candidateDetails.sickLeaveNoOfDays !== "" &&
          candidateDetails.sickLeaveNoOfDays >= 0
          ? parseInt(candidateDetails.sickLeaveNoOfDays)
          : 0
        : candidateDetails.sickLeaveNoOfDays !== ""
        ? candidateDetails.sickLeaveNoOfDays
        : 0;

    tempData.otherExpenses =
      candidateDetails.candidateTypeOfResource === "C2C"
        ? candidateDetails.otherExpenses &&
          candidateDetails.otherExpenses !== "" &&
          candidateDetails.otherExpenses >= 0
          ? parseInt(candidateDetails.otherExpenses)
          : parseInt("0")
        : candidateDetails.otherExpenses !== ""
        ? candidateDetails.otherExpenses
        : parseInt("0");

    tempData.otherExpensesHourly =
      candidateDetails.candidateTypeOfResource === "C2C" ||
      candidateDetails.candidateCountry === "CAD"
        ? candidateDetails.otherExpensesHourly &&
          candidateDetails.otherExpensesHourly !== "" &&
          candidateDetails.otherExpensesHourly >= 0
          ? parseInt(candidateDetails.otherExpensesHourly)
          : 0
        : candidateDetails.otherExpensesHourly !== ""
        ? candidateDetails.otherExpensesHourly
        : 0;

    tempData.refferalAmount =
      candidateDetails.candidateTypeOfResource === "C2C"
        ? candidateDetails.refferalAmount &&
          candidateDetails.refferalAmount !== "" &&
          candidateDetails.refferalAmount >= 0
          ? parseInt(candidateDetails.refferalAmount)
          : 0
        : candidateDetails.refferalAmount !== ""
        ? candidateDetails.refferalAmount
        : 0;
    tempData.perDiemCount =
      candidateDetails.perDiemCount !== "" ? candidateDetails.perDiemCount : 0;

    tempData["recruiterCountry"] = employIntegration.loginData.country;
    tempData["loginUserId"] = employIntegration.loginData.id;

    if (isAllValid) {
      setApitrigered(3);
      try {
        showLoader();
        const respondse = await submitMyrecord(
          candidateDetails.candidateDetailId,
          tempData
        );
        if (respondse.status === 200) {
          setBackTodashboard();
        }
      } catch {
        // setApitrigered(0);
      } finally {
        hideLoader();
      }
    } else {
      let scrollGetElement = document.getElementById(targetDiv);

      scrollGetElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setErrorText(errorList);
    }
  };

  const displayRules = (name) => {
    let BooleanResult = true;

    if (["Allowance"].includes(name)) {
      candidateDetails.candidateCountry === "CAD"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (["Total Commission for # months"].includes(name)) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }

    if (["calculationCountry"].includes(name)) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }
    if (["recMnthlyCommissionBonus"].includes(name)) {
      candidateList.loginData.country === "India"
        ? (BooleanResult = true)
        : (BooleanResult = false);
    }
    if (name === "leaveStateCd") {
      candidateDetails.candidateCountry === "USA"
        ? (BooleanResult = true)
        : (BooleanResult = false);
    }
    if (
      [
        "provinceState",
        "statutoryHolidays",
        "vacationPayHourly",
        "vacationPayAnnual",
        "optionalHolidays",
        "ttlMonthlyCommissionUSDVal",
        "recruiterMonthlyCommissionUSDVal",
      ].includes(name)
    ) {
      candidateDetails.candidateCountry === "CAD"
        ? (BooleanResult = true)
        : (BooleanResult = false);
    }
    //  if (
    //   ["totalCommissionForMonthPro", "recruiterMonthlyCommissionPro"].includes(
    //     name
    //   )
    // ) {
    //   candidateList.loginData.country == "India"
    //     ? (BooleanResult = false)
    //     : (BooleanResult = true);
    // }
    if (
      [
        "ttlMonthlyCommissionUSDVal",
        "recruiterMonthlyCommissionUSDVal",
      ].includes(name)
    ) {
      if (
        candidateDetails.candidateCountry === "CAD" &&
        candidateList.loginData.country === "USA"
      ) {
        BooleanResult = true;
      } else BooleanResult = false;
    }
    if (
      [
        "ttlMonthlyCommissionUSDVal",
        "recruiterMonthlyCommissionUSDVal",
      ].includes(name)
    ) {
      if (
        candidateDetails.candidateCountry === "CAD" &&
        candidateList.loginData.country === "India"
      ) {
        BooleanResult = false;
      }
    }
    if (
      [
        "vacationNoOfDays",
        "candidateInsurenceType",
        "holidays",
        "immgirationType",
        "otherExpensesHourly",
        "totalCommissionForMonthPro",
        "recruiterMonthlyCommissionPro",
        "hourlyRate",
        "perDiemCount",

        "perDiem",
        "payRatePerDiem",
        // "grossMarginPercentage",
        "recruiterMonthlyCommission",
        "recuiterMonthlyCommissionPerHour",
      ].includes(name)
    ) {
      candidateDetails.candidateCountry === "CAD"
        ? (BooleanResult = false)
        : (BooleanResult = true);
    }

    return BooleanResult;
  };

  const setMydivName = (name, divName) => {
    let myDivName = "";

    if (candidateDetails.candidateCountry === "CAD") {
      switch (name) {
        case "recuiterMonthlyCommission": {
          myDivName = "Recruiter Monthly Commission(CAD)";
          break;
        }
        case "totalCommissionFor24Months": {
          myDivName = "Total Commission for # months (CAD)";
          break;
        }
        default:
          myDivName = divName;
      }
    } else myDivName = divName;

    return myDivName;
  };

  const chooseDropdoenOptions = (type) => {
    let filteredOption = lookupGroup[type];
    if (type === "BUSINESSUNIT") {
      if (candidateDetails.candidateCountry === "CAD") {
        const filteredOptions = lookupGroup["BUSINESSUNIT"].filter((key) =>
          ["Canada", "BU07", "BU01"].includes(key.value)
        );
        filteredOption = filteredOptions;
      } else {
        const filteredOptions = lookupGroup["BUSINESSUNIT"].filter(
          (key) => !["Canada"].includes(key.value)
        );
        filteredOption = filteredOptions;
      }
    }
    if (type === "RESOURCETYPE") {
      if (candidateDetails.candidateCountry === "CAD") {
        const filteredOptions = lookupGroup["RESOURCETYPE"].filter(
          (key) => key.value === "T4" || key.value === "C2C"
        );

        filteredOption = filteredOptions;
      } else {
        const filteredOptions = lookupGroup["RESOURCETYPE"].filter(
          (key) => key.value !== "T4"
        );

        filteredOption = filteredOptions;
      }
    }
    return filteredOption;
  };

  return (
    <>
      <Grid
        container
        sx={{
          background: "#44A0E3",
          p: 1,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          position: "sticky",
          overflow: "auto",
          right: "10px",
          top: "100px",
          zIndex: 1,
        }}
      >
        <Grid item sx={{ display: "flex" }} xs={3}>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            Application Id:
          </Typography>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            {candidateDetails.applicationId}
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex" }} xs={3}>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            Candidate Name:
          </Typography>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            {candidateDetails.candidateName}
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex" }} xs={3}>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            Recruiter Name:
          </Typography>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            {candidateDetails.recuiterFirstName}{" "}
            {candidateDetails.recuiterLastName}
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex", gap: "2px" }} xs={3}>
          <Typography variant="h7" sx={{ color: "#FFF" }}>
            Candidate Status:
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CircleIcon
              sx={{
                color:
                  renderCandidateStatus?.[
                    findFullform(candidateDetails?.candidateStatus)
                  ] ?? "white",
                width: "15px",
              }}
            />
            <Typography variant="h7" sx={{ color: "#FFF" }}>
              {findFullform(candidateDetails?.candidateStatus)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          minHeight: "90vh",
          paddingTop: "3%",
          position: "relative",
          flexDirection: "column",
        }}
      >
        <Grid sx={{ display: "flex", justifyContent: "space-around" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              width: "600px",
              // marginLeft: "-10%",
              rowGap: "10px",
              padding: "0px 0px 19px 0px",
            }}
          >
            {!isEmpty(candidateDetails) && !isEmpty(lookupGroup) && (
              <>
                {database_List.map((item, key) => {
                  if (item.field === "Textfield") {
                    return (
                      <TextFields
                        id={`C${key}`}
                        //  isAccess={item.name === "allowedMarkupPct" ? false : true}
                        displayRule={displayRules(item.name)}
                        currency={
                          [
                            // "recuiterMonthlyCommissionPerHour",
                            // "totalCommissionForMonthPro",
                            // "recruiterMonthlyCommissionPro",
                            // "hourlyRate",
                            "recMnthlyCommissionBonus",
                            "recuiterMonthlyCommission",
                          ].includes(item.name)
                            ? candidateList.loginData.roleCd != "RCTR" ||
                              candidateList.loginData.roleCd != "ACCMNGR"
                              ? calCountry === "India"
                                ? "IND"
                                : calCountry === "USA"
                                ? "USA"
                                : candidateList.loginData.country === "India"
                                ? "IND"
                                : candidateList.loginData.country === "USA"
                                ? "USA"
                                : "CAD"
                              : ""
                            : "USA"
                        }
                        display={
                          [
                            "recruiterMonthlyCommissionPro",
                            "recuiterMonthlyCommissionPerHour",
                            "totalCommissionForMonthPro",
                            "hourlyRate",
                          ].includes(item.name)
                            ? !visibleAccess[item.name]
                            : true
                        }
                        // currency={item.currency}
                        isRequired={
                          toCheckValidityByTypeofResource(item.name) === true
                            ? false
                            : item?.mandatory ?? false
                        }
                        defaultValue={candidateDetails[item.name]}
                        onChange={HandleChange}
                        names={item.name}
                        defaultVlues={item.defaultVlues}
                        corticonField={item?.corticonField ?? false}
                        errorText={
                          errorText.hasOwnProperty(item.name)
                            ? errorText[item.name]
                            : ""
                        }
                        Action={employIntegration.userAction}
                        fieldName={setMydivName(item.name, item.divName)}
                        colorind={candidateDetails.colorInd}
                      />
                    );
                  } else if (item.field === "dropDown") {
                    return (
                      <Dropdown
                        displayRule={displayRules(item.name)}
                        defaultValue={candidateDetails[item.name]}
                        isRequired={
                          toCheckValidityByTypeofResource(item.name) === true
                            ? false
                            : item?.mandatory ?? false
                        }
                        onChange={HandleChange}
                        child={createCheckbox}
                        id={`C${key}`}
                        defaultVlues={item.defaultVlues}
                        fieldName={item.divName}
                        name={item.name}
                        childCheckbox={
                          item.name === "immgirationType" &&
                          candidateDetails["immgirationType"] === "4000"
                        }
                        errorText={
                          toCheckValidityByTypeofResource(item.name) === true
                            ? ""
                            : errorText.hasOwnProperty(item.name)
                            ? errorText[item.name]
                            : ""
                        }
                        corticonField={item?.corticonField ?? false}
                        options={
                          item.lookupType === "VMS"
                            ? VMSFee.VMS
                            : chooseDropdoenOptions(item.lookupType)
                        }
                        action={employIntegration.userAction}
                        display={
                          ["calculationCountry"].includes(item.name)
                            ? candidateDetails?.candidateStatus === "CMP"
                              ? false
                              : !visibleAccess[item.name]
                            : true
                        }
                      />
                    );
                  } else if (item.field === "Datefield") {
                    return (
                      <DateField
                        defaultValue={candidateDetails[item.name]}
                        isRequired={item?.mandatory ?? false}
                        id={`C${key}`}
                        Action={employIntegration.userAction}
                        onChange={HandleChange}
                        errorText={
                          errorText[item.name] ? errorText[item.name] : ""
                        }
                        option={item}
                        fieldName={item.divName}
                        name={item.name}
                        minDate={
                          ["onboardingDt"].includes(item.name)
                            ? dayjs(candidateDetails?.candidateStartDt)
                            : ["terminationDt"].includes(item.name)
                            ? dayjs().subtract(1, "week")
                            : null
                        }
                        maxDate={
                          ["terminationDt"].includes(item.name)
                            ? dayjs().add(1, "week")
                            : null
                        }
                        display={
                          ["onboardingDt", "terminationDt"].includes(item.name)
                            ? !visibleAccess[item.name]
                            : true
                        }
                      ></DateField>
                    );
                  } else if (item.field === "TwoText") {
                    return (
                      <TwoTextFields
                        id={`C${key}`}
                        displayRule={displayRules(item.divName)}
                        Action={employIntegration.userAction}
                        // defaultVlues={item.defaultVlues}
                        onChange={HandleChange}
                        errorTextFirst={
                          errorText[item.fName] ? errorText[item.fName] : ""
                        }
                        country={calCountry ?? candidateList.loginData.country}
                        errorTextLast={
                          errorText[item.lName] ? errorText[item.lName] : ""
                        }
                        isRequired={false}
                        FistcorticonField={item?.FistcorticonField ?? false}
                        LastcorticonField={item?.LastcorticonField ?? false}
                        fieldName={item.divName}
                        RFname={candidateDetails[item.fName]}
                        RLname={candidateDetails[item.lName]}
                        fName={item.fName}
                        lName={item.lName}
                      />
                    );
                  }
                })}
                {accessForAP &&
                  (candidateDetails?.candidateStatus === "CMP" ||
                    // candidateDetails?.candidateStatus === "SUB" ||
                    candidateDetails?.candidateStatus === "ONB") && (
                    <Box id="comments">
                      <Grid
                        container
                        sx={{
                          lineHeight: "3.5",
                          alignItems: "center",
                          marginBottom: "5px",
                          fontWeight: "600",
                          wrap: "noWrap",
                          fontSize: "14px",
                        }}
                      >
                        <Box
                          sx={{
                            color: "var(--Black, var(--Text, #000))",
                            textAlign: "right",
                            fontSize: "14px",
                            padding: "20px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "normal",
                            width: "195px",
                          }}
                          id={`c${database_List.length}`}
                        >
                          Comments<span style={{ color: "red" }}>*</span>
                        </Box>

                        <Textarea
                          className="defaultFieldStruc"
                          minRows={3}
                          disabled={
                            employIntegration.userAction == "View" &&
                            candidateList.visibleAccess === 1 &&
                            calcelButton
                              ? false
                              : employIntegration.userAction === "View"
                              ? true
                              : false
                          }
                          defaultValue={candidateDetails.comments ?? ""}
                          placeholder={
                            errorText.hasOwnProperty("comments")
                              ? errorText.comments
                              : ""
                          }
                          error={
                            errorText.hasOwnProperty("comments")
                              ? errorText.comments
                              : ""
                          }
                          names="comments"
                          onChange={(e) =>
                            HandleChange(e.target.value, "comments")
                          }
                        />
                      </Grid>
                    </Box>
                  )}

                <Box
                  sx={{ display: "flex", justifyContent: "end", gap: "30px" }}
                >
                  {employIntegration.userAction != "View" && (
                    <>
                      <Button
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          border: "1px solid black",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                        onClick={ClearEvent}
                        value="Clear"
                      >
                        Clear
                      </Button>
                      <Button
                        style={{
                          //  color: !dataChanged && "#44a0e3 -50%",
                          color: !dataChanged && "grey",
                          pointerEvents: !dataChanged ? "none" : "auto",
                          backgroundColor: !dataChanged
                            ? "gainsboro"
                            : "#44a0e3",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                        onClick={saveAndpost}
                      >
                        Update
                      </Button>
                      {candidateDetails?.candidateStatus !== "CMP" &&
                        candidateDetails?.candidateStatus !== "SUB" &&
                        candidateDetails?.candidateStatus !== "ONB" &&
                        calculatedOnce && (
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "capitalize",
                            }}
                            onClick={submitmyEdit}
                          >
                            Submit
                          </Button>
                        )}
                      {candidateDetails?.candidateStatus === "ONB" && (
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#f5291b",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "#d92016",
                            },
                          }}
                          onClick={openTermDilog}
                        >
                          Terminate
                        </Button>
                      )}
                    </>
                  )}

                  {employIntegration.userAction == "View" && (
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                      }}
                      onClick={setBackTodashboard}
                    >
                      Back
                    </Button>
                  )}
                  {employIntegration.userAction == "View" &&
                    candidateList.visibleAccess === 1 &&
                    calcelButton && (
                      <Button
                        sx={{
                          textTransform: "capitalize",
                        }}
                        onClick={openCancelDilog}
                        color="error"
                        variant="contained"
                      >
                        Need Correction
                      </Button>
                    )}
                </Box>
              </>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              position: "sticky",
              height: "96vh",
              overflow: "auto",
              right: "10px",
              top: "100px",
            }}
          >
            <Box
              sx={{ border: "1px solid gray", borderRadius: " 0 0 4px 4px" }}
            >
              <Accordion
                sx={{
                  display:
                    candidateDetails?.rtMarkUpModel == null ? "none" : "block",

                  // paddingTop: "30px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ background: "#a3a3a39c" }}
                >
                  <Typography sx={{ fontWeight: "600", color: "black" }}>
                    Mark Up List
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: "flex" }}>
                    <MarkUpTable value={candidateDetails?.rtMarkUpModel} />
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  display:
                    candidateDetails?.prFinalRateModel == null
                      ? "none"
                      : "block",
                  // paddingTop: "30px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  sx={{ background: "#a3a3a39c" }}
                >
                  <Typography sx={{ fontWeight: "600", color: "black" }}>
                    Pay Benefits
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: "flex" }}>
                    <PayBenefits value={candidateDetails?.prFinalRateModel} />
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  display:
                    candidateDetails?.rtMarkUpModel == null ? "none" : "block",
                  // paddingTop: "30px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  sx={{ background: "#a3a3a39c", borderRadius: " 0 0 4px 4px" }}
                >
                  <Typography sx={{ fontWeight: "600", color: "black" }}>
                    B2BI Margin Limit
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!isEmpty(lookupGroup) && (
                    <div style={{ display: "flex" }}>
                      <B2BIMariginLimits value={lookupGroup} />
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Grid>
      </Box>
      {holidayToggle && (
        <Dialog open={holidayToggle}>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "1rem",
                borderRadius: "0.2rem",
                border: "1px solid grey",
              }}
            >
              <WarningAmberRoundedIcon
                sx={{ width: "5rem", height: "5rem", color: "#FF6D00" }}
              />
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                }}
              ></Typography>
              <Typography sx={{ marginTop: "0.5rem" }}>
                There is mandatory paid leave in this state{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {holidayDataToggle?.lookupLabel ?? " "}
                </span>
                .{"  "} Please add{" "}
                <span style={{ fontWeight: "bolder" }}>
                  {holidayDataToggle?.lookupVal ?? ""}
                </span>{" "}
                days in the vacation field
              </Typography>
              <Button
                sx={{ width: "6rem", marginTop: "1rem" }}
                variant="contained"
                onClick={() => setHoliddayToggle(false)}
              >
                Ok
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {terminateDialog && (
        <Dialog open={terminateDialog} onClose={closeTermDilog}>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "1rem",
                borderRadius: "0.2rem",
                border: "1px solid grey",
              }}
            >
              <WarningAmberRoundedIcon
                sx={{ width: "5rem", height: "5rem", color: "#FF6D00" }}
              />
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                }}
              ></Typography>
              <Typography sx={{ marginTop: "0.5rem" }}>
                Do you want to terminate this candidate
              </Typography>
              <DialogActions>
                <Button onClick={terminationCnd} variant="outlined">
                  Yes
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#f5291b",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#d92016",
                    },
                  }}
                  variant="contained"
                  onClick={closeTermDilog}
                >
                  No
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {cancelDialog && (
        <Dialog open={cancelDialog} onClose={closeCancelDilog}>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "1rem",
                borderRadius: "0.2rem",
                border: "1px solid grey",
              }}
            >
              <WarningAmberRoundedIcon
                sx={{ width: "5rem", height: "5rem", color: "#FF6D00" }}
              />
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                }}
              ></Typography>
              <Typography sx={{ marginTop: "0.5rem" }}>
                Do you want to cancel this candidate
              </Typography>
              <DialogActions>
                <Button onClick={cancelCnd} variant="outlined">
                  Yes
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#f5291b",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#d92016",
                    },
                  }}
                  variant="contained"
                  onClick={closeCancelDilog}
                >
                  No
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ViewMyDb;
