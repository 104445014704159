import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { TextField, Grid, Box, colors } from "@mui/material";

const TwoTextFields = (props) => {
  const {
    defaultValue,
    Action,
    fieldName,
    onChange,
    fName,
    lName,
    RFname,
    id,
    display,
    RLname,
    errorTextLast,
    FistcorticonField,
    LastcorticonField,
    displayRule,
    defaultVlues,
    errorTextFirst,
    isRequired,
    isDisabled,
    country,
  } = props;

  return (
    <>
      {displayRule && (
        <Box id={fName}>
          <Grid
            container
            sx={{
              lineHeight: "3.5",
              // height: "56px",
              alignItems: "center",
              // columnGap: "30px",
              marginBottom: "5px",
              fontWeight: "600",
              wrap: "noWrap",
              fontSize: "14px",
              //display: "flex",
              // flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                color: "var(--Black, var(--Text, #000))",
                textAlign: "right",
                fontSize: "14px",
                padding: "20px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                width: "195px",
                height: "56px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              className={isRequired && "mandatoryField"}
              // className={isRequired ? "mandatoryField" : "fieldName"}
            >
              {fieldName}
            </Box>

            {Action === "View" ? (
              <Box
                sx={{
                  width: "49.5%",
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <TextField
                  disabled
                  InputProps={{
                    style: {
                      fontSize: "14px",
                      borderColor: "rgba(0,0,0,0.9)",
                      backgroundColor: "#f2f2f2",
                      color: "grey",
                    },
                  }}
                  //  sx={{ width: "49%", paddingRight: "2%" }}
                  id="outlined-disabled"
                  className="defaultFieldStruc"
                  value={RFname}
                  name={fName}
                />
                <TextField
                  disabled
                  InputProps={{
                    style: {
                      fontSize: "14px",
                      borderColor: "rgba(0,0,0,0.9)",
                      backgroundColor: "#f2f2f2",
                      color: "grey",
                    },
                  }}
                  inputProps={{
                    onInput: (event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                    },

                    startAdornment: lName ===
                      "recuiterTotCommissionForMonths" && (
                      <InputAdornment position="start">
                        {country === "India"
                          ? "₹"
                          : country === "USA"
                          ? "$"
                          : "$"}
                      </InputAdornment>
                    ),
                  }}
                  //  sx={{ width: "49%" }}
                  id="outlined-disabled"
                  className="defaultFieldStruc"
                  value={RLname}
                  name={lName}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  width: "49.5%",
                  display: "flex",
                  gap: "8px",
                }}
              >
                <TextField
                  InputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                  //onkeydown="return /[a-z]/i.test(event.key)"
                  disabled={isDisabled || FistcorticonField}
                  value={RFname}
                  // value={RFname}
                  onInput={(e) => {
                    if (fName === "perDiemCount") {
                      e.target.value &&
                        (e.target.value = e.target.value
                          .toString()
                          .slice(0, 9));
                    }
                  }}
                  onChange={(e) => {
                    if (
                      fName === "recuiterFirstName" ||
                      /^(\d+)?(\.\d{0,2})?$/.test(e.target.value)
                    ) {
                      onChange(e.target.value, fName);
                    }
                  }}
                  error={errorTextFirst}
                  id={
                    FistcorticonField
                      ? "outlined-disabled"
                      : errorTextFirst
                      ? "outlined-error-helper-text"
                      : "outlined-basic"
                  }
                  variant="outlined"
                  helperText={errorTextFirst}
                  sx={{
                    fontSize: "14px !important",
                    backgroundColor: FistcorticonField ? "#f2f2f2" : "white",
                  }}
                  className="defaultFieldStruc"
                  name={fName}
                  // label={RFname == "" ? "" : fieldName}
                />
                <TextField
                  // onkeydown="return /[a-z]/i.test(event.key)"
                  InputProps={{
                    style: {
                      fontSize: "14px",
                    },
                    startAdornment: lName ===
                      "recuiterTotCommissionForMonths" && (
                      <InputAdornment position="start">
                        {country === "India"
                          ? "₹"
                          : country === "USA"
                          ? "$"
                          : "$"}
                      </InputAdornment>
                    ),
                  }}
                  disabled={isDisabled || LastcorticonField}
                  value={RLname}
                  onChange={(e) => {
                    if (
                      fName === "recuiterFirstName" ||
                      /^(\d+)?(\.\d{0,2})?$/.test(e.target.value)
                    ) {
                      onChange(e.target.value, fName);
                    }
                    onChange(e.target.value, lName);
                  }}
                  variant="outlined"
                  helperText={errorTextLast}
                  error={errorTextLast}
                  id={
                    LastcorticonField
                      ? "outlined-disabled"
                      : errorTextLast
                      ? "outlined-error-helper-text"
                      : "outlined-basic"
                  }
                  sx={{
                    fontSize: "14px !important",
                    backgroundColor: LastcorticonField ? "#f2f2f2" : "white",
                  }}
                  className="defaultFieldStruc"
                  name={lName}
                  //label={RLname == "" ? "" : fieldName}
                />
              </Box>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default TwoTextFields;
