const header = "/component/navbar";

export const Routepath = {
  BidBreakDown: `${header}/BidBreakDown`,
  NotificationRoute: `${header}/NotificationRoute`,
  NotificationTables: `${header}/NotificationTables`,
  RolesPage: `${header}/Roles`,
  DashboardTabsAll: "/",
  BrtoPr: `${header}/BrtoPr`,
  RcGraphs: `${header}/graph/RcGraphs`,
  //Dashboard: "/",
  Narrative: `${header}/Narrative`,
  RateCalculatorTabView: `${header}RC/`,
  RateCalculator: `${header}/RateCalculator`,
  VMSValue: `${header}/vmsFolder/VMSValue`,
  Footer: "/component/MenuBar/MenuBar.js",
  ViewMyDb: `${header}/dashboard/ViewMyDb`,
  AccountExecutveMain: `${header}/accDashboard/AccountExeContent`,
  Dashboard: `${header}/dashboard/Dashboard`,
  MenuBar: "/component/footer/Footer.js",
  DbChildComponents: `${header}/dashboard/Dashboard`,
  VMSModal: `${header}/dashboard/VMSModal`,
  Login: "/Login",
  Auditing: `${header}/Auditing`,
  Register: "/registration_admin_12",
  ReportComp: `${header}/Reports`,
  ReleaseNote: "/releaseNote",
  AeNotification: `${header}/AeNotification`,
  OnBording: `${header}/OnBording`,
  AccDashboard: `${header}/accDashboard`,
};
