import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginImage from "./Images/login-image.svg";
import "../Login/Login.css";
import { Routepath } from "../../routing/routpath";
import { Alert, FormControl, MenuItem, Select, Stack } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authApi } from "./index";
import { decodeToken } from "react-jwt";
import { userData } from "../../store/slices/authSlice";
import { Navigate } from "react-router-dom";
import { updatePassowrdRed } from "../../services/apiRequests";
import toast, { Toaster } from "react-hot-toast";
import useLoader from "../../Loader/useLoader";
import { Controller, useForm } from "react-hook-form";
import { logDOM } from "@testing-library/react";

const defaultTheme = createTheme();

function Login(props) {
  const token = useSelector((state) => state?.auth?.token);
  const [showPassword, setShowPassword] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const { watch, control, getValues } = useForm({
    defaultValues: {
      email: "",
      pass: "",
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onLogin = async () => {
    try {
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("grant_type", "password");
      bodyFormData.append("client_id", "security-admin-console");
      bodyFormData.append("username", getValues("email"));
      bodyFormData.append("password", getValues("pass"));
      bodyFormData.append("scope", "openid");
      const res = await authApi.post(
        // "/auth/realms/krc/protocol/openid-connect/token",
        // "/auth/realms/qakrc/protocol/openid-connect/token",
        `${process.env.REACT_APP_SERVICES_KEY_CLOCK}/protocol/openid-connect/token`,
        bodyFormData
      );
      if (res.status === 200) {
        const { access_token, refresh_token } = res.data ?? {};
        const test = res.data.access_token;
        const myDecodedToken = decodeToken(test);
        dispatch(
          userData({
            ...myDecodedToken,
            accessToken: res.data?.access_token,
            refreshToken: refresh_token,
          })
        );
      } else {
        toast.error("Username and Pasword Missmatch");
      }
    } catch (error) {
      toast.error("Invalid User Name Or Password");
      return <Navigate to="/Login" replace />;
    }
  };

  const forgotPassowrd = async () => {
    const payload = {
      email: getValues("email"),
      username: getValues("email"),
    };
    if (payload.username) {
      try {
        showLoader();
        const res = await updatePassowrdRed(payload);
        if (res.status === 200) {
          toast((t) => (
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography variant="subtitle2">
                Please check your mail for update password
              </Typography>
              <Button onClick={() => toast.dismiss(t.id)}>Close</Button>
            </Box>
          ));
        }
      } catch (error) {
        toast.error("Server Error");
      } finally {
        hideLoader();
      }
    } else {
      toast.error("Enter Mail ID");
    }
  };

  if (token?.accessToken) {
    navigate(Routepath.DashboardTabsAll);
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={12} sm={4} md={4}>
            <Box
              sx={{
                backgroundColor: "#44A0E3",
                borderRadius: "20px 0px 0px 20px",
                backgroundImage: `url(${LoginImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "bottom",
                height: "100%",
                WebkitBackgroundSize: "auto",
              }}
            >
              <Box
                sx={{
                  paddingTop: "1rem",
                  paddingLeft: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{ fontWeight: 700, fontSize: "1.5rem" }}
                  color="#fff"
                >
                  Let us help you applying your scheme
                </Typography>
                <Typography variant="subtitle1" color="#fff">
                  Our process is quick and easy, to get a scheme from Government
                  to exact user
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              padding: 2,
              boxShadow: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "0px 20px 20px 0px",
            }}
          >
            <Grid
              container
              spacing={3}
              sx={{
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }} pt={3}>
                  Login To Account
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                        margin="normal"
                        required
                        id="email"
                        label="Email ID"
                        fullWidth
                        placeholder="Enter Email ID"
                        autoComplete="email"
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <FormControl
                  sx={{ mt: 3, mb: 1, width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <Controller
                    name="pass"
                    control={control}
                    render={({ field }) => {
                      return (
                        <OutlinedInput
                          {...field}
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          name="password"
                        />
                      );
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={10} justifyContent="flex-end">
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button onClick={forgotPassowrd}>Forget Password?</Button>
                </Stack>
              </Grid>
              <Grid item xs={10} pb={5}>
                <Button
                  variant="contained"
                  onClick={() => onLogin()}
                  sx={{
                    width: "100%",
                    padding: "15px",
                  }}
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Toaster position="top-center" reverseOrder={false} />
    </ThemeProvider>
  );
}

export default Login;
