import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { TextField, Grid, Box } from "@mui/material";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";

const TextFields = (props) => {
  const {
    defaultValue,
    id,

    Action,
    fieldName,
    onChange,
    names,
    displayRule,
    errorText,
    defaultVlues,
    corticonField,
    isRequired,
    colorind,
    display,
    currency,
    isAccess,
  } = props;
  const selector = useSelector((state) => state.dropedField);
  const formattedValue = new Intl.NumberFormat("en-US").format(
    defaultValue / 100
  );

  function handleKeyPress(e) {
    if (
      [
        "vacationNoOfDays",
        "sickLeaveNoOfDays",
        "statutoryHolidays",
        "recuiterMonthlyCommissionPerHour",
        "recuiterMonthlyCommission",
        "optionalHolidays",
      ].includes(names) &&
      e.keyCode !== 13
    ) {
      return !/[0-9]/.test(e.key) && e.preventDefault();
    } else if ( [
      "candidateName",
      "candidateJobTitle",
      "jobId",
    ].includes(names)&& e.keyCode !== 13) {
      return !/[0-9A-Za-z-/: _-]/.test(e.key) && e.preventDefault();
    } else if (
      [
        "candidateBillRate",
        "refferalAmount",
        "otherExpenses",
        "otherExpensesHourly",
        "payRate",
        "maxPay",
        "payRatePerDiem",
        "grossMarginDollar",
        "recuiterMonthlyCommission",
        "recuiterMonthlyCommissionPerHour",
        "totalCommissionForMonthPro",
        "recruiterMonthlyCommissionPro",
        "hourlyRate",
        "recruiterMonthlyCommissionUSDVal",
        "ttlMonthlyCommissionUSDVal",
        "recruiterMonthlyCommissionPro",
        "hourlyRate",
      ].includes(names) &&
      e.keyCode !== 13
    ) {
      return /[^0-9.]/g.test(e.key) && e.preventDefault();
    }else if([
      "candidateName",
      "candidateJobTitle",
    ].includes(names) &&
    e.keyCode !== 13){
      return /[A-Za-z0-9_.-]*/g.test(e.key) && e.preventDefault();
    } else {
      return /[^a-zA-Z\s]|(?:\s)\s{2,}/g.test(e.key) && e.preventDefault();
    }
  }
  const renderColor = (status) => {
    switch (status) {
      case "RED":
        return "#D81414 solid 1px";
      case "GRN":
        return " #58F35F solid 1px";
      case "YLW":
        return "#F3C858 solid 1px";
      default:
        return;
    }
  };

  return (
    <>
      {display && displayRule && (
        <Box id={names}>
          <Grid
            container
            sx={{
              lineHeight: "3.5",
              // height: "66px",
              alignItems: "center",
              //columnGap: "30px",
              marginBottom: "5px",
              fontWeight: "600",
              wrap: "noWrap",
              fontSize: "14px",
            }}
          >
            <Box
              id={names}
              sx={{
                color: "var(--Black, var(--Text, #000))",
                textAlign: "right",
                fontSize: "14px",
                padding: "20px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                width: "195px",
                height: "56px",
                display: "flex",
                alignItems: "flex-start",
                gap: "5px",
                justifyContent: "flex-end",
              }}
              className={isRequired && "mandatoryField"}
            >
              {fieldName}
            </Box>

            {Action === "View" || corticonField ? (
              <TextField
                disabled
                InputProps={{
                  style: {
                    fontSize: "14px",
                    borderColor: "rgba(0,0,0,0.9)",
                    color: "grey",
                    backgroundColor: "#f2f2f2",
                  },
                  startAdornment: [
                    "candidateBillRate",
                    "refferalAmount",
                    "otherExpenses",
                    "otherExpensesHourly",
                    "payRate",
                    "maxPay",
                    "payRatePerDiem",
                    "grossMarginDollar",
                    "recuiterMonthlyCommission",
                    "recMnthlyCommissionBonus",
                    "recuiterMonthlyCommissionPerHour",
                    "totalCommissionForMonthPro",
                    "recruiterMonthlyCommissionPro",
                    "hourlyRate",
                  ].includes(names) ? (
                    <InputAdornment position="start">
                      {currency === "IND"
                        ? "₹"
                        : currency === "USA"
                        ? "$"
                        : "$"}
                    </InputAdornment>
                  ) : (
                    ""
                  ),
                }}
                id="outlined-disabled"
                className="defaultFieldStruc"
                value={
                  [
                    "grossMarginPercentage",
                    "b2biMargin",
                    "blendedGrossMarginPercentage",
                    "allowedMarkupPct",
                  ].includes(names)
                    ? defaultValue + "%"
                    : defaultValue
                }
                name={names}
                sx={{
                  border:
                    names === "grossMarginPercentage"
                      ? renderColor(colorind)
                      : null,
                  borderRadius: "6px",
                  // Set the border color based on conditions
                }}
              />
            ) : (
              <>
                <TextField
                  InputProps={{
                    onKeyPress: (e) => handleKeyPress(e),
                    onKeyDown: (e) => onkeydown && onkeydown(e),

                    startAdornment: [
                      "candidateBillRate",
                      "refferalAmount",
                      "otherExpenses",
                      "otherExpensesHourly",
                      "payRate",
                      "maxPay",
                      "payRatePerDiem",
                      "grossMarginDollar",
                      "recuiterMonthlyCommission",
                      "recMnthlyCommissionBonus",
                      "recuiterMonthlyCommissionPerHour",
                      "totalCommissionForMonthPro",
                      "recruiterMonthlyCommissionPro",

                      "hourlyRate",
                    ].includes(names) && (
                      <InputAdornment position="start">
                        {currency === "IND"
                          ? "₹"
                          : currency === "USA"
                          ? "$"
                          : "$"}
                      </InputAdornment>
                    ),

                    style: {
                      fontSize: "14px",

                      pointerEvents: isAccess ? "none" : "auto",
                    },
                  }}
                  onInput={(e) => {
                    if (names === "candidateJobTitle") {
                      e.target.value &&
                        (e.target.value = e.target.value
                          .toString()
                          .slice(0, 50));
                    } else if (names === "jobId") {
                      e.target.value &&
                        (e.target.value = e.target.value
                          .toString()
                          .slice(0, 35));
                    } else if (
                      [
                        "candidateBillRate",
                        "otherExpenses",
                        "otherExpensesHourly",
                        "perDiemCount",
                        "refferalAmount",
                        "payRate",
                      ].includes(names)
                    ) {
                      e.target.value &&
                        (e.target.value = e.target.value
                          .toString()
                          .replace(/\D/g, "")
                          ?.substr(0, 9)
                          .replace(/(\d)(\d{2})$/, "$1.$2"));
                    } else if (
                      [
                        "totalCommissionFor24Months",
                        "sickLeaveNoOfDays",
                        "vacationNoOfDays",
                        "statutoryHolidays",
                        "optionalHolidays",
                      ].includes(names)
                    ) {
                      e.target.value &&
                        (e.target.value = e.target.value
                          .toString()
                          .slice(0, 2));
                    }
                  }}
                  value={
                    [
                      "candidateBillRate",
                      "refferalAmount",
                      "otherExpenses",
                      "otherExpensesHourly",
                      "payRate",
                      "maxPay",
                      "payRatePerDiem",
                      "grossMarginDollar",
                      "recuiterMonthlyCommission",
                      "recMnthlyCommissionBonus",
                      "recuiterMonthlyCommissionPerHour",
                      "totalCommissionForMonthPro",
                      "recruiterMonthlyCommissionPro",
                      "hourlyRate",
                    ].includes(names)
                      ? defaultValue
                      : defaultValue
                  }
                  onChange={(event) => {
                    onChange(event.target.value, names);
                  }}
                  error={errorText}
                  id={
                    errorText && !defaultValue
                      ? "outlined-error-helper-text"
                      : "outlined-basic"
                  }
                  variant="outlined"
                  sx={{
                    fontSize: "14px !important",
                    border:
                      names === "grossMarginPercentage"
                        ? "solid 1px red"
                        : null,
                  }}
                  helperText={errorText}
                  className="defaultFieldStruc"
                  name={names}
                />
              </>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default TextFields;
