import React, { useEffect, useMemo, useState } from "react";
import { AccExedashboardData } from "../../../services/apiRequests";
import { getLookup } from "../../../services/apiRequests";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import { groupLookup, pairWithValue } from "../../../utils/helpers";
import { DataGrid } from "@mui/x-data-grid";
import useLoader from "../../../Loader/useLoader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Routepath } from "../../../routing/routpath";
import CircleIcon from "@mui/icons-material/Circle";
import {
  renderGrossMargincolor,
  renderCandidateStatus,
} from "../../../constance/DashboardJson";
import {
  setAeNotificationDetails,
  setDashboardChild,
  setNotificationActionAcs,
  setNotificationDetails,
  setscreenId,
} from "../../../reduxApp/fieldStore";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  status: {
    border: "1px solid var(--Secon-3-Grey, #C2C2B7)",
    display: "flex",
    alignItems: "center",
    padding: "5px",
    borderRadius: "6px",
    width: "100%",
    cursor: "pointer",
  },
  statusBox: {
    display: "flex",
    alignItems: "center",
    pr: "5px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const AccDashboard = () => {
  const [showLoader, hideLoader] = useLoader();
  const [notificationWhole, setNotigicationAll] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [rows, setRow] = useState();
  const [lookupGroup, setLookupGroup] = useState({});
  const [pageState, setPageState] = useState({
    page: 0,
    pageSize: 10,
  });
  const classes = useStyles();

  const handleEdit = (objItem) => {
    if (objItem.status === "SUB") {
      const findObj = notificationWhole?.find(
        (obj) => obj.applicationId === objItem.id
      );
      dispatch(
        setNotificationDetails({
          Event: "View",
          id: findObj.candidateDetailId,
        })
      );

      dispatch(setNotificationActionAcs(objItem.type));
      Navigate(Routepath.AccountExecutveMain);
    }
    if (
      objItem.status === "PEN" ||
      objItem.status === "DEN" ||
      objItem.status === "NCR"
    ) {
      const findObj = notificationWhole?.find(
        (obj) => obj.applicationId === objItem.id
      );
      viewMydetails("View", findObj.candidateDetailId, objItem?.candidateName);
    }
    if (objItem.status === "ONB") {
      const findObj = notificationWhole?.find(
        (obj) => obj.applicationId === objItem.id
      );
      viewMydetails("Edit", findObj.candidateDetailId, objItem?.candidateName);
    }
    if (objItem.status === "CMP") {
      const findObj = notificationWhole?.find(
        (obj) => obj.applicationId === objItem.id
      );
      dispatch(setscreenId(3));
      dispatch(
        setAeNotificationDetails({
          Event: "Edit",
          id: findObj.candidateDetailId,
        })
      );
      Navigate(Routepath.OnBording);
    }
  };

  const viewMydetails = (userAction, id, userName) => {
    dispatch(setscreenId(3));
    dispatch(
      setDashboardChild({
        userAction: userAction,
        id: id,
        userName: userName,
      })
    );
    Navigate(Routepath.RateCalculatorTabView);
  };
  const [tablebody, setTableBody] = useState();
  const tableHeader = useMemo(() => {
    return [
      { headerName: "ID", field: "id", flex: 1, minWidth: 130 },
      {
        headerName: "Candidate Name",
        field: "candidateName",
        flex: 1,
        minWidth: 160,
      },
      {
        headerName: "Job Id",
        field: "jobId",
        flex: 1,
        minWidth: 130,
      },
      {
        headerName: "Client Name",
        field: "customerName",
        flex: 1,
        minWidth: 200,
      },
      {
        headerName: "Gross Margin %",
        field: "grossMarginPercentage",
        flex: 1,
        minWidth: 140,
        renderCell: (params) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px",
                borderRadius: "6px",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <CircleIcon
                sx={{
                  color:
                    renderGrossMargincolor?.[params?.row?.colorInd] ?? "white",
                  width: "15px",
                }}
              />
              {params?.row?.grossMarginPercentage}%
            </div>
          );
        },
      },
      {
        headerName: "Start Date",
        field: "candidateStartDt",
        flex: 1,
        minWidth: 100,
      },
      {
        headerName: "Recruiter Name",
        field: "recruiterName",
        flex: 1,
        minWidth: 160,
      },
      {
        headerName: "Status",
        field: "status",
        flex: 1,
        minWidth: 140,

        renderCell: (params) => {
          return (
            <Grid className={classes.status}>
              <Box className={classes.statusBox}>
                <CircleIcon
                  sx={{
                    color:
                      renderCandidateStatus?.[
                        pairWithValue(
                          lookupGroup.CANDIDATE_STATUS,
                          params?.row?.status
                        )
                      ] ?? "white",
                    width: "15px",
                  }}
                />

                <Tooltip
                  title={pairWithValue(
                    lookupGroup.CANDIDATE_STATUS,
                    params?.row?.status
                  )}
                >
                  <span>
                    {pairWithValue(
                      lookupGroup.CANDIDATE_STATUS,
                      params?.row?.status
                    )}
                  </span>
                </Tooltip>
              </Box>
            </Grid>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 100,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <>
              <IconButton
                aria-label="edit"
                onClick={() => handleEdit(params.row)}
              >
                <EditIcon sx={{ color: "#6495ED" }} />
              </IconButton>

              {/* <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton> */}
            </>
          );
        },
      },
    ];
  });

  const handlePagination = (pages) => {
    setPageState({
      page: pages.page,
      pageSize: pages.pageSize,
    });
  };

  useEffect(() => {
    getDashoard();
  }, [pageState, dataChanged]);

  async function getDashoard() {
    showLoader();
    try {
      const res = await AccExedashboardData(pageState.page, pageState.pageSize);
      const lookup = await getLookup();
      if (lookup.status === 200) {
        const groupedLookup = groupLookup(lookup.data);

        if (res.status === 200 && groupedLookup) {
          setLookupGroup(groupedLookup);
          setNotigicationAll(res?.data?.content ?? []);
          const columnDta = res?.data?.content?.map((obj, key) => {
            return {
              id: obj.applicationId,
              jobId: obj.jobId,
              customerName: obj.customerName,
              candidateName: obj.candidateName,
              grossMarginPercentage: obj.grossMarginPercentage,
              colorInd: obj.colorInd,
              candidateStartDt: obj.candidateStartDt,
              recruiterName: `${obj.recuiterFirstName}  ${obj.recuiterLastName}`,
              status: obj.candidateStatus,
            };
          });
          setTableBody(columnDta);
          setRow(res.data);
        }
      }
    } catch (e) {
      //
    } finally {
      hideLoader();
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <DataGrid
        rows={tablebody ?? []}
        columns={tableHeader ?? []}
        rowCount={rows?.totalElements ?? 0}
        pageSizeOptions={[5, 10, 20, 30]}
        paginationMode="server"
        onPaginationModelChange={handlePagination}
        paginationModel={pageState}
        localeText={{ noRowsLabel: "No data found" }}
        sx={{
          marginTop: "1.8rem",
          backgroundColor: "white",
          "& .MuiDataGrid-cell": {
            "&:first-of-type": {
              fontWeight: "bold",
              fontSize: "1.1em",
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "0.9rem",
            fontWeight: "700",
            color: "#000000",
          },
          "& .MuiDataGrid-row": {
            color: "black",
            textAlign: "center",
          },
        }}
      ></DataGrid>
      {/* <TablePagination
        component="div"
        count={rows?.totalElements || 0}
        rowsPerPage={rowsPerPage || 10}
        rowsPerPageOptions={[5, 10, 25]}
        page={page || 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Box>
  );
};

export default AccDashboard;
