export const groupLookup = (lookups = []) => {
  return lookups.reduce((acc, lkp) => {
    if (acc[lkp.lookupType]) {
      acc[lkp.lookupType].push({
        label: lkp.lookupLabel,
        value: lkp.lookupCd,
        leaveValue: lkp.lookupVal,
      });
    } else {
      acc[lkp.lookupType] = [
        {
          label: lkp.lookupLabel,
          value: lkp.lookupCd,
          leaveValue: lkp.lookupVal,
        },
      ];
    }

    return acc;
  }, {});
};

export function pairWithValue(array, payloadValue) {
  let values = "";
  if (payloadValue) {
    values = array.find((obj) => {
      return obj.value === payloadValue;
    });
  }
  return values.label;
}

export function pairWithLabels(array, payloadLable) {
  const key = array.find((obj) => {
    return obj.label === payloadLable;
  });
  return key.value;
}
