import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Button,
  InputAdornment,
  TextField,
  Popover,
  IconButton,
} from "@mui/material";
import { DateRange, DateRangePicker } from "react-date-range";
import DateRangeIcon from "@mui/icons-material/DateRange";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import dayjs from "dayjs";
import { range } from "lodash";

const DateRangePickers = ({
  onDateChange,
  inputValue,
  activeTab,
  placeholder,
  disableFuture,
}) => {
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().format("MM-DD-YYYY"));
  const [endDate, setEndDate] = useState(dayjs().format("MM-DD-YYYY"));

  const onAdornmentClick = (e) => {
    setDisplayCalendar(true);
    setAnchorEl(e.currentTarget);
  };

  const onPopoverClose = () => {
    setDisplayCalendar(false);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setDisplayCalendar(false);
    setAnchorEl(null);
  };

  const onSelectDateRanges = ({ selection }) => {
    const { startDate, endDate } = selection;

    setStartDate(startDate);
    setEndDate(endDate);
    onDateChange({ startDate, endDate });
  };

  useEffect(() => {
    setStartDate(dayjs().format("MM-DD-YYYY"));
    setEndDate(dayjs().format("MM-DD-YYYY"));
  }, [activeTab]);

  return (
    <>
      <TextField
        fullWidth={true}
        value={inputValue}
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onAdornmentClick}>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onPopoverClose}
      >
        <Box>
          <DateRangePicker
            ranges={[
              {
                startDate,
                endDate,
                key: "selection",
              },
            ]}
            onChange={onSelectDateRanges}
            // maxDate={activeTab == "1" ? new Date() : undefined}
            // minDate={activeTab == "0" ? new Date() : undefined}
            showMonthAndYearPickers={true}
            moveRangeOnFirstSelection={true}
            showDateDisplay={false}
            scroll={{ enabled: false }}
            maxDate={disableFuture}
            months={2}
            direction="horizontal"
            className="calanderElement"
            staticRanges={[]} // Remove static ranges like "Today" and "This Week"
            inputRanges={[]}
          />
        </Box>
        <Stack direction="row" display="flex" justifyContent="flex-end">
          <Button onClick={handleClose}>Ok</Button>
        </Stack>
      </Popover>
    </>
  );
};

export default DateRangePickers;
