import Index from "./component/Index";
import "./App.css";
import AuditingTable from "./component/navbar/Auditing/AuditingTable";
function App() {
  return (
    <div className="App">
      <Index />
      {/* <AuditingTable/> */}
    </div>
  );
}

export default App;
