import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    position: "relative",
    zIndex: 2,
    height: "50px",
    padding: "10px 30px 10px 20px",
    justifyContent: "space-between",

    alignItems: "center",
    flexShrink: "0",
    background: "var(--Footer-Bg, #D9D9D9)",
  },
  textformat: {
    color: "var(--Prim-1-N-Black, #2C2C2C)",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px" /* 171.429% */,
    letterSpacing: "0.5px",
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid
          item
          xs={4}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <p className={classes.textformat}>
            Copyright © 2024 All Rights Reserved
          </p>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <p className={classes.textformat}>
            Privacy Policy Terms & Conditions
          </p>
        </Grid>
      </Grid>
    </div>
  );
};
export default Footer;
