import dayjs from "dayjs";

// const { getAccess } = useAuth();
//const { noAccess: noAccessRC } = getAccess();
export const renderGrossMargincolor = {
  RED: "#D81414",
  GRN: "#58F35F",
  YLW: "#F3C858",
};
export const renderCandidateStatus = {
  Pending: "#F3C858",
  Submited: "#61B0E9",
  Approved: "#58F35F",
  Denied: "#D81414",
  Returned: "#9D50AF",
  ReSubmitted: "#6C6C6C",
  Onboarded: "#D175C0",
  Terminated: "#672B2B",
  "Need Correction": "#010203",
};
export const accessField = {
  totalCommissionFor24Months: "",
  recruiterMonthlyCommission: "",
  recruiterMonthlyCommissionPerHour: "",
  totalCommissionForMonthPro: "",
  recruiterMonthlyCommissionPro: "",
  hourlyRate: "",
};

export const dashboard_api = {
  dirtyStatus: "",
  candidateName: "", //ca
  candidateJobTitle: "", //ca
  candidateStartDt: dayjs().format("YYYY-MM-DD"), //ca
  onboardingDt: null,
  terminationDt: null,
  candidateBusinessUnit: "",
  tnVisaPremiumYN: null,
  //tnVisaCountry: null,
  candidateCountry: "", //ca
  calculationCountry: "",
  recuiterFirstName: "", //ca
  recuiterLastName: "", //ca
  loginUserId: "", //ca
  preId: "NO", //ca
  candidateTypeOfResource: "", //ca
  candidateBillRate: "", //ca
  vmsFee: "", //ca
  vacationNoOfDays: 0,
  sickLeaveNoOfDays: 0, //ca
  holidays: "NO", //ca
  candidateInsurenceType: "NON",
  refferalAmount: 0, //ca
  immgirationType: "NO",
  otherExpenses: 0, //ca
  otherExpensesHourly: 0,
  jobId: "",
  totalCommissionForMonthPro: "",
  recruiterMonthlyCommissionPro: "", //AE Monthly Commission...
  hourlyRate: "",
  perDiemCount: 0,
  perDiem: "",
  maxPay: "", //ca
  payRate: "", //ca
  allowedMarkupPct: "",
  payRatePerDiem: "",
  b2biMargin: "", //ca
  grossMarginPercentage: "",
  candidateJobID: "",
  grossMarginDollar: "", //ca
  blendedGrossMarginPercentage: "",
  totalCommissionFor24Months: 24, //Recruiter Total Comission for # months//ORANGE
  recruiterCountry: "",
  recuiterMonthlyCommission: "",
  statutoryHolidays: 0,
  recruiterMonthlyCommission: "", //Recruiter Monthly comission...//ORANGE
  recuiterMonthlyCommissionPerHour: "", //Recuiter Monthly Commission PerHour
  // totalCommissionForMonthPro: "", //Total AE Commission For Month//ae
  provinceState: "",
  recuiterTotCommissionForMonths: "",
  vacationPayHourly: "",
  vacationPayAnnual: "",
  optionalHolidays: 0,
  ttlMonthlyCommissionUSDVal: "",
  recruiterMonthlyCommissionUSDVal: "",
  hourlyRate: "", //hr
  recMnthlyCommissionBonus: "",

  //
};
export const disableField = [
  "maxPay",
  "perDiem",
  "payRatePerDiem",
  "b2biMargin",
  "grossMarginPercentage",
  "grossMarginDollar",
  "blendedGrossMarginPercentage",
  "recuiterTotCommissionForMonths",
  "recuiterMonthlyCommission",
  "recuiterMonthlyCommissionPerHour",
  "recMnthlyCommissionBonus",
];

export const database_List = [
  {
    name: "candidateName",
    field: "Textfield",
    divName: "Candidate Name",
    defaultVlues: "",
    display: "none",
    mandatory: true,
  },
  {
    name: "candidateJobTitle",
    field: "Textfield",
    divName: "Job Title",
    display: "none",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "jobId",
    field: "Textfield",
    display: "block",
    divName: "Job ID ",
    defaultVlues: "",
    // mandatory: true,
  },
  {
    name: "candidateStartDt",
    field: "Datefield",
    display: "block",
    divName: "Start Date",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "onboardingDt",
    field: "Datefield",
    display: "block",
    divName: "On Bording Date",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "terminationDt",
    field: "Datefield",
    display: "block",
    divName: "Termination Date",
    defaultVlues: "",
    mandatory: false,
  },
  {
    name: "candidateBillRate",
    field: "Textfield",
    divName: "Bill Rate",
    display: "block",
    currency: "$",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "candidateCountry",
    display: "block",
    field: "dropDown",
    divName: "Working Location",
    lookupType: "COUNTRY",
    mandatory: true,
  },
  {
    name: "leaveStateCd",
    display: "block",
    field: "dropDown",
    divName: "State",
    lookupType: "STATE",
    mandatory: true,
  },

  {
    name: "calculationCountry",
    display: "block",
    field: "dropDown",
    divName: "Calculation Location",
    lookupType: "CALCOUNTRY",
    mandatory: true,
    corticonField: true,
  },
  {
    name: "candidateBusinessUnit",
    display: "block",
    field: "dropDown",
    divName: "Business Unit",
    lookupType: "BUSINESSUNIT",
    mandatory: true,
  },

  {
    fName: "recuiterFirstName",
    fdisplay: "block",
    ldisplay: "block",
    FistcorticonField: true,
    Fmandatory: true,
    Lmandatory: true,
    LastcorticonField: true,
    lName: "recuiterLastName",
    field: "TwoText",
    divName: "Recruiter Name",
  },

  {
    name: "preId",
    field: "dropDown",
    divName: "Pre-ID",
    lookupType: "YESNO",
    display: "block",
    mandatory: false,
  },
  {
    name: "candidateTypeOfResource",
    field: "dropDown",
    display: "block",
    divName: "Type of Resource",
    lookupType: "RESOURCETYPE",
    defaultVlues: "",
    mandatory: true,
  },

  {
    name: "vmsFee",
    field: "dropDown",
    divName: "VMS Fee",
    lookupType: "VMS",
    display: "block",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "provinceState",
    field: "dropDown",
    lookupType: "PROVINCES_STRY_HLDY",
    display: "block",
    currency: "$",
    corticonField: false,
    divName: "Province State",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "vacationPayHourly",
    field: "Textfield",
    display: "block",
    currency: "$",
    corticonField: true,
    divName: "4% Vacation Pay Hourly",
    defaultVlues: "",
  },
  {
    name: "vacationPayAnnual",
    field: "Textfield",
    display: "block",
    currency: "$",
    corticonField: true,
    divName: "4% Vacation Pay Annual",
    defaultVlues: "",
  },
  {
    name: "optionalHolidays",
    field: "Textfield",
    display: "block",
    currency: "$",
    corticonField: false,
    divName: "Optional Holidays",
    defaultVlues: "",
  },
  {
    name: "holidays",
    field: "dropDown",
    divName: "Holidays(6 Days)",
    display: "block",
    lookupType: "YESNO",
    defaultVlues: "NO",
    mandatory: true,
  },
  {
    name: "statutoryHolidays",
    field: "Textfield",
    divName: "Statutory Holidays",
    display: "block",

    defaultVlues: "No",
    mandatory: false,
  },

  {
    name: "allowedMarkupPct",
    field: "Textfield",
    divName: "Allowed Markup",
    display: "block",
    defaultVlues: 0,
    corticonField: true,
    // mandatory: false,
  },
  {
    name: "vacationNoOfDays",
    field: "Textfield",
    divName: "Vacation (# of Days)",
    display: "block",
    defaultVlues: 0,
    mandatory: true,
  },
  {
    name: "sickLeaveNoOfDays",
    field: "Textfield",
    divName: "Sick Leave (# of days)",
    display: "block",
    defaultVlues: "",
    mandatory: false,
  },

  {
    name: "candidateInsurenceType",
    display: "block",
    field: "dropDown",
    divName: "Insurance Type",
    lookupType: "INSURENCE",
    defaultVlues: "SIN",
    mandatory: true,
  },
  {
    name: "refferalAmount",
    currency: "$",
    display: "block",
    field: "Textfield",
    divName: "Referral Fee",
    // lookupType: "REFERRALFEE",
    defaultVlues: 0.0,
    // mandatory: true,
  },
  {
    name: "immgirationType",
    field: "dropDown",
    divName: "Immigration",
    lookupType: "IMMIGRATION",
    display: "block",
    defaultVlues: "NO",
    mandatory: true,
  },
  {
    name: "otherExpenses",
    field: "Textfield",
    divName: "Other Exp-Fixed Dollar ",
    display: "block",
    defaultVlues: 0,
    currency: "$",
    // mandatory: true,
  },
  {
    name: "otherExpensesHourly",
    currency: "$",
    field: "Textfield",
    divName: "Other Expenses-Hourly",
    display: "block",
    defaultVlues: 0.0,
    // mandatory: true,
  },
  {
    fName: "perDiemCount",
    fdisplay: "block",
    lName: "perDiem",
    field: "TwoText",
    ldisplay: "block",
    FistcorticonField: false,
    Fmandatory: false,
    Lmandatory: false,
    LastcorticonField: true,
    // mandatory: true,
    divName: "Allowance",

    defaultVlues: 0,
  },
  {
    name: "payRate",
    field: "Textfield",
    display: "block",
    currency: "$",
    divName: "Pay Rate",
    defaultVlues: "",
    corticonField: false,
    mandatory: true,
  },
  {
    fName: "totalCommissionFor24Months",
    display: "block",
    FistcorticonField: false,
    LastcorticonField: true,
    Fmandatory: false,
    Lmandatory: false,
    lName: "recuiterTotCommissionForMonths",
    fdisplay: "block",
    ldisplay: "block",
    field: "TwoText",
    divName: "Total Commission for # months",

    defaultVlues: 0,
  },

  {
    name: "maxPay",
    field: "Textfield",
    display: "block",
    currency: "$",
    corticonField: true,
    divName: "Max Pay (including Allowance)",
    defaultVlues: "",
  },

  {
    name: "payRatePerDiem",
    display: "block",
    currency: "$",
    field: "Textfield",
    divName: "Pay Rate  Allowance",
    defaultVlues: "",
    corticonField: true,
  },
  {
    name: "b2biMargin",
    field: "Textfield",
    divName: "B2BI Margin",
    display: "block",
    defaultVlues: "",
    corticonField: true,
  },
  {
    name: "grossMarginPercentage",
    field: "Textfield",
    divName: "Gross Margin",
    display: "block",
    defaultVlues: "",
    corticonField: true,
  },
  {
    name: "grossMarginDollar",
    currency: "$",
    field: "Textfield",
    divName: "Gross Margin in $",
    display: "block",
    defaultVlues: "",
    corticonField: true,
  },
  {
    name: "blendedGrossMarginPercentage",
    field: "Textfield",
    divName: "Blended Gross Margin",
    display: "block",
    defaultVlues: "",
    corticonField: true,
  },

  {
    name: "recuiterMonthlyCommission",
    currency: "",
    display: "block",
    field: "Textfield",
    corticonField: true,
    divName: "Recruiter Monthly Commission ",
    defaultVlues: "",
  },
  {
    name: "recMnthlyCommissionBonus",
    currency: "",
    display: "block",
    field: "Textfield",
    corticonField: true,
    divName: "Recruiter Monthly Commission Bonus",
    defaultVlues: "",
  },

  {
    name: "recuiterMonthlyCommissionPerHour", //hr
    currency: "",
    field: "Textfield",
    display: "block",
    corticonField: true,
    divName: "Recruiter Monthly Commission per hour worked",
    defaultVlues: "",
  },
  {
    name: "totalCommissionForMonthPro", //ae
    display: "block",
    field: "Textfield",
    divName: "Total AE Commission",
    currency: "",
    corticonField: true,
    // mandatory: false,
  },
  {
    name: "recruiterMonthlyCommissionPro", //ae
    display: "block",
    field: "Textfield",
    divName: "AE Recruiter Monthly Commission",
    currency: "",
    corticonField: true,
    // mandatory: true,
  },

  {
    name: "ttlMonthlyCommissionUSDVal",
    field: "Textfield",
    display: "block",
    currency: "$",
    corticonField: true,
    divName: "Total Commission for # months (USD)",
    defaultVlues: "",
  },
  {
    name: "recruiterMonthlyCommissionUSDVal",
    field: "Textfield",
    display: "block",
    currency: "$",
    corticonField: true,
    divName: "Recruiter Monthly Commission(USD)",
    defaultVlues: "",
  },
  {
    name: "hourlyRate", //hr
    display: "block",
    field: "Textfield",
    divName: "Hourly Rate",
    currency: "",
    corticonField: true,
    // mandatory: true,
  },
];

export const databaseListQuickCalc = [
  {
    name: "candidateName",
    field: "Textfield",
    divName: "Candidate Name",
    defaultVlues: "",
    display: "none",
    mandatory: true,
  },
  {
    name: "candidateJobTitle",
    field: "Textfield",
    divName: "Job Title",
    display: "none",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "jobId",
    field: "Textfield",
    display: "block",
    divName: "Job ID ",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "candidateStartDt",
    field: "Datefield",
    display: "block",
    divName: "Start Date",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "onboardingDt",
    field: "Datefield",
    display: "block",
    divName: "On Bording Date",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "terminationDt",
    field: "Datefield",
    display: "block",
    divName: "Termination Date",
    defaultVlues: "",
    mandatory: false,
  },
  {
    name: "candidateBillRate",
    field: "Textfield",
    divName: "Bill Rate",
    display: "block",
    currency: "$",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "candidateCountry",
    display: "block",
    field: "dropDown",
    divName: "Working Location",
    lookupType: "COUNTRY",
    mandatory: true,
  },
  {
    name: "leaveStateCd",
    display: "block",
    field: "dropDown",
    divName: "State",
    lookupType: "STATE",
    mandatory: true,
  },

  {
    name: "calculationCountry",
    display: "block",
    field: "dropDown",
    divName: "Calculation Location",
    lookupType: "CALCOUNTRY",
    mandatory: true,
    corticonField: true,
  },
  {
    name: "candidateBusinessUnit",
    display: "block",
    field: "dropDown",
    divName: "Business Unit",
    lookupType: "BUSINESSUNIT",
    mandatory: true,
  },

  {
    fName: "recuiterFirstName",
    fdisplay: "block",
    ldisplay: "block",
    FistcorticonField: true,
    Fmandatory: true,
    Lmandatory: true,
    LastcorticonField: true,
    lName: "recuiterLastName",
    field: "TwoText",
    divName: "Recruiter Name",
  },

  {
    name: "preId",
    field: "dropDown",
    divName: "Pre-ID",
    lookupType: "YESNO",
    display: "block",
    mandatory: false,
  },
  {
    name: "candidateTypeOfResource",
    field: "dropDown",
    display: "block",
    divName: "Type of Resource",
    lookupType: "RESOURCETYPE",
    defaultVlues: "",
    mandatory: true,
  },

  {
    name: "vmsFee",
    field: "dropDown",
    divName: "VMS Fee",
    lookupType: "VMS",
    display: "block",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "payRate",
    field: "Textfield",
    display: "block",
    currency: "$",
    divName: "Pay Rate",
    defaultVlues: "",
    corticonField: false,
    mandatory: true,
  },
  {
    name: "provinceState",
    field: "dropDown",
    lookupType: "PROVINCES_STRY_HLDY",
    display: "block",
    currency: "$",
    corticonField: false,
    divName: "Province State",
    defaultVlues: "",
    mandatory: true,
  },
  {
    name: "vacationPayHourly",
    field: "Textfield",
    display: "block",
    currency: "$",
    corticonField: true,
    divName: "4% Vacation Pay Hourly",
    defaultVlues: "",
  },
  {
    name: "vacationPayAnnual",
    field: "Textfield",
    display: "block",
    currency: "$",
    corticonField: true,
    divName: "4% Vacation Pay Annual",
    defaultVlues: "",
  },
  {
    name: "optionalHolidays",
    field: "Textfield",
    display: "block",
    currency: "$",
    corticonField: false,
    divName: "Optional Holidays",
    defaultVlues: "",
  },
  {
    name: "holidays",
    field: "dropDown",
    divName: "Holidays(6 Days)",
    display: "block",
    lookupType: "YESNO",
    defaultVlues: "NO",
    mandatory: true,
  },
  {
    name: "statutoryHolidays",
    field: "Textfield",
    divName: "Statutory Holidays",
    display: "block",

    defaultVlues: "No",
    mandatory: false,
  },

  {
    name: "allowedMarkupPct",
    field: "Textfield",
    divName: "Allowed Markup",
    display: "block",
    defaultVlues: 0,
    corticonField: true,
    // mandatory: false,
  },
  {
    name: "vacationNoOfDays",
    field: "Textfield",
    divName: "Vacation (# of Days)",
    display: "block",
    defaultVlues: 0,
    mandatory: true,
  },
  {
    name: "sickLeaveNoOfDays",
    field: "Textfield",
    divName: "Sick Leave (# of days)",
    display: "block",
    defaultVlues: "",
    mandatory: false,
  },

  {
    name: "candidateInsurenceType",
    display: "block",
    field: "dropDown",
    divName: "Insurance Type",
    lookupType: "INSURENCE",
    defaultVlues: "SIN",
    mandatory: true,
  },
  {
    name: "refferalAmount",
    currency: "$",
    display: "block",
    field: "Textfield",
    divName: "Referral Fee",
    // lookupType: "REFERRALFEE",
    defaultVlues: 0.0,
    mandatory: true,
  },
  {
    name: "immgirationType",
    field: "dropDown",
    divName: "Immigration",
    lookupType: "IMMIGRATION",
    display: "block",
    defaultVlues: "NO",
    mandatory: true,
  },
  {
    name: "otherExpenses",
    field: "Textfield",
    divName: "Other Exp-Fixed Dollar ",
    display: "block",
    defaultVlues: 0,
    currency: "$",
    mandatory: true,
  },
  {
    name: "otherExpensesHourly",
    currency: "$",
    field: "Textfield",
    divName: "Other Expenses-Hourly",
    display: "block",
    defaultVlues: 0.0,
    mandatory: true,
  },
  {
    fName: "perDiemCount",
    fdisplay: "block",
    lName: "perDiem",
    field: "TwoText",
    ldisplay: "block",
    FistcorticonField: false,
    Fmandatory: false,
    Lmandatory: false,
    LastcorticonField: true,
    // mandatory: true,
    divName: "Allowance",

    defaultVlues: 0,
  },
  {
    fName: "totalCommissionFor24Months",
    display: "block",
    FistcorticonField: false,
    LastcorticonField: true,
    Fmandatory: false,
    Lmandatory: false,
    lName: "recuiterTotCommissionForMonths",
    fdisplay: "block",
    ldisplay: "block",
    field: "TwoText",
    divName: "Total Commission for # months",

    defaultVlues: 0,
  },

  {
    name: "maxPay",
    field: "Textfield",
    display: "block",
    currency: "$",
    corticonField: true,
    divName: "Max Pay (including Allowance)",
    defaultVlues: "",
  },

  {
    name: "payRatePerDiem",
    display: "block",
    currency: "$",
    field: "Textfield",
    divName: "Pay Rate  Allowance",
    defaultVlues: "",
    corticonField: true,
  },
  {
    name: "b2biMargin",
    field: "Textfield",
    divName: "B2BI Margin",
    display: "block",
    defaultVlues: "",
    corticonField: true,
  },
  {
    name: "grossMarginPercentage",
    field: "Textfield",
    divName: "Gross Margin",
    display: "block",
    defaultVlues: "",
    corticonField: true,
  },
  {
    name: "grossMarginDollar",
    currency: "$",
    field: "Textfield",
    divName: "Gross Margin in $",
    display: "block",
    defaultVlues: "",
    corticonField: true,
  },
  {
    name: "blendedGrossMarginPercentage",
    field: "Textfield",
    divName: "Blended Gross Margin",
    display: "block",
    defaultVlues: "",
    corticonField: true,
  },

  {
    name: "recuiterMonthlyCommission",
    currency: "",
    display: "block",
    field: "Textfield",
    corticonField: true,
    divName: "Recruiter Monthly Commission ",
    defaultVlues: "",
  },
  {
    name: "recMnthlyCommissionBonus",
    currency: "",
    display: "block",
    field: "Textfield",
    corticonField: true,
    divName: "Recruiter Monthly Commission Bonus",
    defaultVlues: "",
  },

  {
    name: "recuiterMonthlyCommissionPerHour", //hr
    currency: "",
    field: "Textfield",
    display: "block",
    corticonField: true,
    divName: "Recruiter Monthly Commission per hour worked",
    defaultVlues: "",
  },
  {
    name: "totalCommissionForMonthPro", //ae
    display: "block",
    field: "Textfield",
    divName: "Total AE Commission",
    currency: "",
    corticonField: true,
    // mandatory: false,
  },
  {
    name: "recruiterMonthlyCommissionPro", //ae
    display: "block",
    field: "Textfield",
    divName: "AE Recruiter Monthly Commission",
    currency: "",
    corticonField: true,
    // mandatory: true,
  },

  {
    name: "ttlMonthlyCommissionUSDVal",
    field: "Textfield",
    display: "block",
    currency: "$",
    corticonField: true,
    divName: "Total Commission for # months (USD)",
    defaultVlues: "",
  },
  {
    name: "recruiterMonthlyCommissionUSDVal",
    field: "Textfield",
    display: "block",
    currency: "$",
    corticonField: true,
    divName: "Recruiter Monthly Commission(USD)",
    defaultVlues: "",
  },
  {
    name: "hourlyRate", //hr
    display: "block",
    field: "Textfield",
    divName: "Hourly Rate",
    currency: "",
    corticonField: true,
    // mandatory: true,
  },
];

export const directComponent = [
  {
    field: "Start time",
    amount: "$55",
    percentage: "100%",
  },
  {
    field: "Wavier",
    amount: "$00.00",
    percentage: "0%",
  },
  {
    field: "Annualized Merit",
    amount: "$00.00",
    percentage: "0%",
  },
  {
    field: "Annualized Direct Comp",
    amount: "$55.00",
    percentage: "100%",
  },
  {
    field: "Target Compensation",
    amount: "$114,400.00",
  },
];
