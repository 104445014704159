import { Paper, Table, TableCell, TableRow } from "@mui/material";
import React from "react";

export const B2BIMariginLimits = (props) => {
  const { value } = props;
  const B2BI = [
    {
      field: "Data Analytics",
      amount: `${value?.Data_Analytics[0]?.label || "0.00"}%`,
    },
    {
      field: "Information Technology",

      amount: `${value?.Information_Technology[0]?.label || "0.00"}%`,
    },
    {
      field: "Technical Coordination",
      amount: `${value?.Technical_Coordination[0]?.label || "0.00"}%`,
    },
    {
      field: "Design",
      amount: `${value?.Design[0]?.label || "0.00"}%`,
    },
    {
      field: "Professional/Creative ",
      amount: `${value?.Professional_Creative[0]?.label || "0.00"}%`,
    },
    {
      field: "Clerical",
      amount: `${value?.Clerical[0]?.label || "0.00"}%`,
    },
    {
      field: "Engineering and FEA",
      amount: `${value?.Engineering_and_FEA[0]?.label || "0.00"}%`,
    },
    {
      field: "Hands On",
      amount: `${value?.Hands_On[0]?.label || "0.00"}%`,
    },
  ];
  return (
    <Paper sx={{ height: "auto", overflow: "hiden" }}>
      <div style={{ height: "100%", overflow: "auto" }}>
        <Table
          sx={{
            width: "300px",
            //   "& .MuiTableCell-root": {
            //     border: "1px solid #c4c4c4",
            //   },
            position: "relative",
            //   overflowX: "auto",
          }}
        >
          <TableRow sx={{ width: "100%" }}>
            <TableCell
              variant="head"
              sx={{
                fontWeight: 700,
                color: "#44A0E3",
                textAlign: "left",
                padding: "10px",
              }}
            >
              Job Title
            </TableCell>

            <TableCell
              variant="head"
              sx={{
                fontWeight: 700,
                color: "#44A0E3",
                textAlign: "left",
                padding: "10px",
              }}
            >
              Margin Limits
            </TableCell>
          </TableRow>
          {B2BI.map((row) => (
            <TableRow>
              <TableCell sx={{ textAlign: "left", padding: "10px" }}>
                {row.field}
              </TableCell>
              <TableCell sx={{ textAlign: "left", padding: "10px" }}>
                {row.amount}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </div>
    </Paper>
  );
};
