import React from "react";
import { TextField, Grid, Box } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

const NumberTextFields = (props) => {
  const {
    defaultValue,
    id,
    Action,
    fieldName,
    onChange,
    names,
    errorText,
    defaultVlues,
    corticonField,
    isRequired,
    currency,
  } = props;

  return (
    <Box id={names}>
      <Grid
        container
        sx={{
          lineHeight: "3.5",
          marginBottom: "5px",
          fontWeight: "600",
          wrap: "noWrap",
          fontSize: "14px",
        }}
      >
        <Box
          id={names}
          sx={{
            color: "var(--Black, var(--Text, #000))",
            textAlign: "right",
            fontSize: "14px",
            padding: "20px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            width: "195px",
            height: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          className={isRequired && "mandatoryField"}
        >
          {fieldName}
        </Box>
        {Action === "View" || corticonField ? (
          <TextField
            disabled
            InputProps={
              currency === "India"
                ? {
                    style: {
                      fontSize: "14px",
                      borderColor: "rgba(0,0,0,0.9)",
                      color: "grey",
                      backgroundColor: "#f2f2f2",
                    },
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }
                : {
                    style: {
                      fontSize: "14px",
                      borderColor: "rgba(0,0,0,0.9)",
                      color: "grey",
                      backgroundColor: "#f2f2f2",
                    },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }
            }
            id="outlined-disabled"
            className="defaultFieldStruc"
            value={defaultValue}
            name={names}
          />
        ) : (
          <>
            <TextField
              InputProps={{
                // onInput: (event) =>
                //   (event.target.value = event.target.value.replace(
                //     /^\d+\.\d{0,2}$/,
                //     ""
                //   )),
                style: {
                  fontSize: "14px",
                },

                startAdornment: (
                  <InputAdornment position="start">
                    {currency === "India" ? "₹" : "$"}
                  </InputAdornment>
                ),
              }}
              onInput={(e) => {
                e.target.value &&
                  (e.target.value = e.target.value.toString().slice(0, 100));
              }}
              value={defaultValue}
              // InputProps={{
              //   onInput: (event) =>
              //     (event.target.value = event.target.value.replace(
              //       /[^0-9]/g,
              //       ""
              //     )),
              // }}
              onChange={(e) => {
                onChange(e, names);
              }}
              error={errorText}
              id={
                errorText && !defaultValue
                  ? "outlined-error-helper-text"
                  : "outlined-basic"
              }
              variant="outlined"
              sx={{ fontSize: "14px !important" }}
              helperText={errorText}
              className="defaultFieldStruc"
              name={names}
            />
          </>
        )}
      </Grid>
    </Box>
  );
};

export default NumberTextFields;
