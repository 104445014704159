import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from "react-router-dom";
import { getAuthToken } from '../../selectors/loginAuthSelector';

function PrivateRoute({ children }) {
  // const {RC,BRtoPR,Bid,RCedit}=
   const token = useSelector(getAuthToken);

  const tokenData = token?.accessToken;
  if (!tokenData) {
    return <Navigate to="/Login" replace />;
  }

  return <Outlet />;
}

export default PrivateRoute;
