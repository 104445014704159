import { createSlice } from "@reduxjs/toolkit";
let initialState = {
  token: {
    Uid: "",
    mailid: "",
    roles: "",
    userName: "",
    accessToken: "",
    refreshToken: "",
  },
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state, action) {
      state.token = {
        Uid: "",
        mailid: "",
        roles: "",
        userName: "",
        accessToken: "",
        refreshToken: "",
      };
    },
    userData(state, action) {
      state.token = {
        Uid: action.payload?.sub,
        mailid: action.payload?.email,
        // roles: action.payload?.resource_access.qakrc.roles[0],
        // roles: action.payload?.resource_access.prodrc.roles[0],
        roles:
          action.payload?.resource_access?.[process.env.REACT_APP_REALMS]
            .roles[0],
        userName: action.payload?.name,
        accessToken: action.payload?.accessToken,
        refreshToken: action.payload?.refreshToken,
      };
    },
  },
});

export const { userData, logout } = authSlice.actions;
export default authSlice.reducer;
