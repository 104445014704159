import axios from "axios";

const HttpClient = axios.create({
  baseURL: process.env.REACT_APP_SERVICES_BASE_URL,
});

export const AuthClient = axios.create({
  baseURL: process.env.REACT_APP_SERVICES_KEY_CLOCK_REGISTER,
});


export default HttpClient;
