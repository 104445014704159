import { useState } from "react";
import {
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  TableCell,
  TableBody,
  Table,
  Box,
  Tooltip,
  Collapse,
  IconButton,
  Stack,
  TablePagination,
} from "@mui/material";
import { setVmsModal, setVmsStates } from "../../../reduxApp/fieldStore";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import styled from "styled-components";
import React from "react";
// export const vmsCustomState = {
//   userAction: "",
//   candidateId: -1,
//   vmsJson: {
//     vmsCompany: "",
//     bussinessUnit: "",
//     customerName: "",
//     feePercent: "",
//   },
//};
const CreateVmsTables = ({
  candidateList,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalpages,
}) => {
  const dispatch = useDispatch();
  const employSelector = useSelector((state) => {
    return state.dropedField;
  });

  const columns = [
    "BUSINESS UNIT",
    "CUSTOMER NAME",
    "SUPERVISOR",
    // "VMS",
    // "VMS COMPANY",
    "ACTION",
  ];

  const rowDatefromapi = [
    "bussinessUnit",
    "customerNameWithFee",
    "supervisername",
    // "vmsId",
    // "vmsCompany",
  ];
  const [selectedId, setSelectedId] = useState([]);

  const Title = styled(Box)({
    width: "100%",
    fontWeight: "500",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
    "$ .MuiDataGrid-columnHeaderRow": { backgroundColor: "yellow" },
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event, newPage) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  return (
    <Title>
      {candidateList.length != 0 && (
        <TableContainer sx={{ width: "90%" }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  borderBottom: "1px solid  #D9D9D9",
                  background: "#F7F7F7",
                }}
              >
                {columns.map((rowItem, key) => (
                  <TableCell
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      padding: "8px 16px 8px 16px",
                    }}
                  >
                    {rowItem}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {candidateList.map((objItem) => {
                return (
                  <TableRow>
                    {rowDatefromapi.map((clmdata) => (
                      <TableCell
                        sx={{
                          padding: "8px 16px 8px 16px",
                        }}
                      >
                        {clmdata === "supervisername"
                          ? `${objItem?.supervisorFirstName ?? ""}  ${
                              objItem?.supervisorLastName ?? ""
                            }`
                          : objItem[clmdata]}
                      </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        cursor: "pointer",
                        padding: "8px 16px 8px 0",
                      }}
                    >
                      {
                        <Box>
                          <Stack item container direction={"row"}>
                            <IconButton
                              onClick={() => {
                                setSelectedId(
                                  selectedId === objItem.vmsId
                                    ? ""
                                    : objItem.vmsId
                                );
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>

                            <IconButton
                              sx={{
                                visibility:
                                  selectedId === objItem.vmsId
                                    ? "visible"
                                    : "hidden",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                dispatch(setVmsModal("VMSMODAL"));
                                dispatch(
                                  setVmsStates({
                                    vmsId: objItem.vmsId,
                                    action: "Edit",
                                  })
                                );
                              }}
                            >
                              <Tooltip title="Edit" placement="top">
                                <ModeEditOutlinedIcon
                                  fontSize="small"
                                  sx={{ color: "#6e57a8" }}
                                />
                              </Tooltip>
                            </IconButton>

                            <IconButton
                              sx={{
                                visibility:
                                  selectedId === objItem.vmsId
                                    ? "visible"
                                    : "hidden",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                dispatch(setVmsModal("Delete"));
                                dispatch(
                                  setVmsStates({
                                    vmsId: objItem.vmsId,
                                    action: "Delete",
                                  })
                                );
                              }}
                            >
                              <Tooltip title="Delete" placement="top">
                                <DeleteForeverOutlinedIcon
                                  fontSize="small"
                                  sx={{ color: "#cf4242" }}
                                />
                              </Tooltip>
                            </IconButton>
                          </Stack>
                        </Box>
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={totalpages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </Title>
  );
};

export default CreateVmsTables;
