import React, { useEffect } from "react";
import MenuBar from "./menu/MenuBar";
import Footer from "./footer/Footer";
import LeftIndex from "./navbar/Layouts";
import { useSelector } from "react-redux";
const Index = () => {
  const selectore = useSelector((state) => state.dropedField);

  return (
    <div style={{ width: "100%" }}>
      <MenuBar />
      <LeftIndex />
      <Footer />
    </div>
  );
};

export default Index;
