import {
  Grid,
  Box,
  MenuItem,
  Typography,
  Stack,
  Button,
  TextField,
  InputAdornment,
  Tooltip,
  Link,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import MonthRangePicker from "../../../../CommonContainer/MonthRangePicker/MonthRangePicker";
import { ReactComponent as EmpTypes } from "../../../../assets/icons/account_tree.svg";
import dayjs from "dayjs";
import { ChooseClient } from "../../../../assets/icons";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import styles from "../index.module.css";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { alpha, styled } from "@mui/material/styles";
import {
  commissionDate,
  excelReport,
  getReportsDashboard,
  RecruiterList,
} from "../../../../services/apiRequests";
import { DataGrid } from "@mui/x-data-grid";
import { countBy, forEach, isEmpty } from "lodash";
import Switch, { SwitchProps } from "@mui/material/Switch";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import useLoader from "../../../../Loader/useLoader";
import toast from "react-hot-toast";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import {
  ClearReportFilters,
  setDashboardChild,
  setReportFilters,
  setscreenId,
  setVisibleAccess,
} from "../../../../reduxApp/fieldStore";
import { useNavigate } from "react-router-dom";
import { Routepath } from "../../../../routing/routpath";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CommissionMonthReport from "./CommissionMonth24";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import useAuth from "../../../../hooks/useAuth";
import AssessmentIcon from "@mui/icons-material/Assessment";

const CustomDashboard = ({
  name,
  options,
  value,
  HandleChange,
  label,
  access,
  labValue,
  iconData,
}) => {
  return (
    <TextField
      select
      value={value}
      name={name}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{iconData}</InputAdornment>
        ),
      }}
      sx={{ width: "100%" }}
      onChange={(e) => {
        HandleChange(e);
      }}
    >
      <MenuItem
        value="none"
        sx={{ display: name === "candidateStatus" ? "none" : "flex" }}
      >
        Select
      </MenuItem>
      {options.map((obj) => {
        return (
          <MenuItem
            value={obj[labValue]}
            disabled={
              access && (obj[labValue] === "USA" || obj[labValue] === "Canada")
            }
          >
            {obj[label]}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const SummaryReports = ({
  extractedData,
  basicgetData,
  setBasicGetData,
  tabValue,
}) => {
  const [showLoader, hideLoader] = useLoader();
  var sampleRows = [];
  const [rows, setRow] = useState([]);
  const [countryRecruiter, setCountryRecruiter] = useState({});
  const [zStyle, setXIndex] = useState("none");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [noOfCandidates, setnoOfCandidates] = useState({});
  const [RecruiterNames, setRecruiterList] = useState([]);
  const [byIdRowData, setByIdRowData] = useState([]);
  const [openCommission, setOpenCommission] = useState(false);
  const [count, setCount] = useState();
  const [pageState, setPageState] = useState({
    page: 0,
    pageSize: 50,
  });
  const { getAccess } = useAuth();
  const { noAccess: reportFilter } = getAccess("27");

  const [value, setValue] = useState("1");

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const closeCommission = () => {
    setOpenCommission(false);
  };

  const [dateRange, setDateRange] = useState({
    fromDate: null,
    toDate: null,
  });
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(null);
  const navigate = useNavigate();

  const viewMydetails = (userAction, id, userName) => {
    dispatch(setscreenId(3));
    dispatch(setVisibleAccess(1));
    dispatch(
      setDashboardChild({
        userAction: userAction,
        id: id,
        userName: userName,
      })
    );
    navigate(Routepath.RateCalculatorTabView);
  };

  const handlePagination = (pages) => {
    setPageState({
      page: pages.page,
      pageSize: pages.pageSize,
    });
  };

  const HandleChange = (e) => {
    const basicData = {
      ...basicgetData,
      [e.target.name]: e.target.value,
      startDate: startDate,
      endDate: endDate,
    };
    if (e.target.name === "role" || e.target.name === "country") {
      basicData.recruiterName = "none";
    }
    setBasicGetData(basicData);
  };

  const downloadExcel = async () => {
    try {
      showLoader();
      const payload = {
        recruiterId:
          basicgetData.recruiterName === "none"
            ? null
            : basicgetData.recruiterName,
        role: basicgetData.role === "none" ? null : basicgetData.role,
        country: basicgetData.country === "none" ? null : basicgetData.country,
        preId: basicgetData.preId === "none" ? null : basicgetData.preId,
        empType: basicgetData.empType === "none" ? null : basicgetData.empType,
        startDt: basicgetData.startDate ?? null,
        endDt: basicgetData.endDate ?? null,
        clientName:
          basicgetData.clientName === "none" ? null : basicgetData.clientName,
        bussinessUnintName:
          basicgetData.bussinessUnintName === "none"
            ? null
            : basicgetData.bussinessUnintName,
        page: 1,
        size: 5000,
        sortBy: null,
        // startYear: dayjs(startDate).year(),
        // endYear: dayjs(endDate).year(),
        candidateStatus:
          basicgetData.candidateStatus === ""
            ? null
            : basicgetData.candidateStatus,
      };

      const response = await excelReport(payload);
      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Correct MIME type for Excel
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CandidateReports.xlsx"); // Set the desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the link element
        window.URL.revokeObjectURL(url); // Free up memory
      }
    } catch (error) {
      console.error("Error while downloading the PDF:", error);
      toast.error("Failed to download PDF");
    } finally {
      hideLoader();
    }
  };

  const getDetail = async (id) => {
    try {
      showLoader();
      const res = await commissionDate(id);
      if (res.status === 200) {
        setByIdRowData(res.data);
        setOpenCommission(true);
        setCount(res.data.length);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const clearFilter = () => {
    setBasicGetData({
      bussinessUnintName: "none",
      clientName: "none",
      country: "none",
      empType: "none",
      recruiterName: "none",
      startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
      endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      role: "none",
      preId: null,
      candidateStatus: "CMP",
    });
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        minWidth: 200,
        field: "candidateName",
        id: 1,
        renderCell: (params) => (
          <>
            <Link
              component="button"
              variant="body2"
              onClick={() =>
                viewMydetails(
                  "View",
                  params.row.candidateDetailId,
                  params.row.candidateName
                )
              }
            >
              {params.row.candidateName}
            </Link>
          </>
        ),
        renderHeader: () => (
          <Tooltip title="Candidate Name">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Candidate Name
            </Typography>
          </Tooltip>
        ),
      },
      {
        headerName: "Bussiness Unit",
        minWidth: 130,
        field: "bussinessUnit",
        id: 2,
        renderHeader: () => (
          <Tooltip title="Bussiness Unit">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Bussiness Unit
            </Typography>
          </Tooltip>
        ),
      },
      {
        headerName: "Client Name",
        minWidth: 150,
        field: "clientName",
        id: 7,
        renderHeader: () => (
          <Tooltip title="Client Name">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Client Name
            </Typography>
          </Tooltip>
        ),
      },
      {
        headerName: "Recruiter Name",
        minWidth: 170,
        field: "recruiterName",
        renderHeader: () => (
          <Tooltip title="Recruter Name">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Recruter Name
            </Typography>
          </Tooltip>
        ),
      },
      {
        headerName: "Account Executive",
        minWidth: 190,
        field: "buSupervisor",
        renderHeader: () => (
          <Tooltip title="Account Executive">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Account Executive
            </Typography>
          </Tooltip>
        ),
      },

      {
        headerName: "Onboarding Date",
        minWidth: 150,
        field: "onboardingDate",
        id: 3,
        valueFormatter: (params) => ` ${params.value ?? "-"}`,
        renderHeader: () => (
          <Tooltip title="Onboarding Date">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Onboarding Date
            </Typography>
          </Tooltip>
        ),
      },

      {
        headerName: " Total Commission",
        minWidth: 130,
        field: "monthlyCommissionBonus",
        id: 8,
        renderHeader: () => (
          <Tooltip title="Total Commission">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Total Comm
            </Typography>
          </Tooltip>
        ),
        renderCell: (params) => {
          return params.row?.recruiterCountry === "India"
            ? `₹ ${params.value ?? "0.00"}`
            : params.row?.recruiterCountry === "CANADA"
            ? `$ ${params.value ?? "0.00"}` //canada ca$
            : `$ ${params.value ?? "0.00"}`;
        },
        align: "right",
        headerAlign: "right",
      },
    ];

    if (!reportFilter) {
      baseColumns.push(
        {
          headerName: "AE Monthly Commission",
          minWidth: 160,
          field: "aeMonthlyCommission",
          id: 9,
          valueFormatter: (params) => `$ ${params.value}`,
          align: "right",
          headerAlign: "right",
          renderHeader: () => (
            <Tooltip title="AE Monthly Commission">
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "700",
                  color: "#000000",
                  whiteSpace: "nowrap",
                }}
              >
                AE Monthly Comm
              </Typography>
            </Tooltip>
          ),
        },
        {
          headerName: "AE Total Commission",
          minWidth: 130,
          field: "aeTotalCommission",
          id: 9,
          valueFormatter: (params) => `$ ${params.value}`,
          align: "right",
          headerAlign: "right",
          renderHeader: () => (
            <Tooltip title="AE Total Commission">
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "700",
                  color: "#000000",
                  whiteSpace: "nowrap",
                }}
              >
                AE Total Comm
              </Typography>
            </Tooltip>
          ),
        }
      );
    }

    // if (countryRecruiter === "India") {
    // baseColumns.splice(
    //   6,
    //   0,
    //   // {
    //   //   headerName: "Commission Month",
    //   //   minWidth: 160,
    //   //   field: "commissionMonth",
    //   //   id: 6,
    //   // },
    //   {
    //     headerName: "Monthly Commission",
    //     minWidth: 170,
    //     field: "monthlyCommission",
    //     id: 7,
    //     valueFormatter: (params) => {
    //       return countryRecruiter === "India"
    //         ? `₹ ${params.value ?? "0.00"}`
    //         : countryRecruiter === "CANADA"
    //         ? `CA$ ${params.value ?? "0.00"}`
    //         : `$ ${params.value ?? "0.00"}`;
    //     },
    //     align: "right",
    //     headerAlign: "right",
    //   },
    //   {
    //     headerName: "Monthly Commission Bonus",
    //     minWidth: 220,
    //     field: "commissionBonus",
    //     id: 7,
    //     valueFormatter: (params) => {
    //       return countryRecruiter === "India"
    //         ? `₹ ${params.value ?? "0.00"}`
    //         : countryRecruiter === "CANADA"
    //         ? `CA$ ${params.value ?? "0.00"}`
    //         : `$ ${params.value ?? "0.00"}`;
    //     },
    //     align: "right",
    //     headerAlign: "right",
    //   }
    // );
    // } else {
    baseColumns.splice(
      6,
      0,
      {
        headerName: "Commission Period",
        minWidth: 160,
        field: "fromMnth",
        renderCell: (params) => {
          return (
            <>
              {params?.row?.fromMnth && params?.row?.toMnth
                ? `(${params.row.fromMnth}) - (${params.row.toMnth})`
                : "-"}
            </>
          );
        },
        id: 6,
        renderHeader: () => (
          <Tooltip title="Commission Period">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Comm Period
            </Typography>
          </Tooltip>
        ),
      },
      // {
      //   headerName: "Commission To Month",
      //   minWidth: 200,
      //   field: "toMnth",
      //   renderCell: (params) => {
      //     return params.row.toMnth ?? "-";
      //   },
      //   id: 6,
      // },
      {
        headerName: "Monthly Commission",
        minWidth: 130,
        field: "monthlyCommission",
        id: 7,
        renderCell: (params) => {
          return params.row?.recruiterCountry === "India"
            ? `₹ ${params.value ?? "0.00"}`
            : params.row?.recruiterCountry === "CANADA"
            ? `$ ${params.value ?? "0.00"}`
            : `$ ${params.value ?? "0.00"}`;
        },
        align: "right",
        headerAlign: "right",
        renderHeader: () => (
          <Tooltip title="Monthly Commission">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Monthly Comm
            </Typography>
          </Tooltip>
        ),
      },
      {
        headerName: "Monthly Commission Bonus",
        minWidth: 180,
        field: "commissionBonus",
        id: 7,
        renderCell: (params) => {
          return params.row?.recruiterCountry === "India"
            ? `₹ ${params.value ?? "0.00"}`
            : params.row?.recruiterCountry === "CANADA"
            ? `$ ${params.value ?? "0.00"}`
            : `$ ${params.value ?? "0.00"}`;
        },
        align: "right",
        headerAlign: "right",
        renderHeader: () => (
          <Tooltip title="Monthly Commission Bonus">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Monthly Comm Bonus
            </Typography>
          </Tooltip>
        ),
      }
      // {
      //   headerName: "Total Monthly Commission",
      //   minWidth:150,
      //   field: "totalMonthlyCommission",
      //   id: 9,
      //   valueFormatter: (params) => `$ ${params.value ?? "0.00"}`,
      // }
    );

    baseColumns.push({
      headerName: "View",
      field: "Comment",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => getDetail(params.row.applicationId)}
              disabled={params.row.recruiterCountry === "India"}
            >
              <VisibilityIcon />
            </IconButton>
          </>
        );
      },
      renderHeader: () => (
        <Tooltip title="View">
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontWeight: "700",
              color: "#000000",
              whiteSpace: "nowrap",
            }}
          >
            View
          </Typography>
        </Tooltip>
      ),
    });
    // }

    return baseColumns;
  }, [countryRecruiter]);

  const Aecolumns = useMemo(() => {
    return [
      {
        headerName: "Candidate Name",
        minWidth: 180,
        field: "candidateName",
        id: 1,
        renderCell: (params) => (
          <Link
            component="button"
            variant="body2"
            onClick={() =>
              viewMydetails(
                "View",
                params.row.candidateDetailId,
                params.row.candidateName
              )
            }
          >
            {params.row.candidateName}
          </Link>
        ),
        renderHeader: () => (
          <Tooltip title="Candidate Name">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Candidate Name
            </Typography>
          </Tooltip>
        ),
      },
      {
        headerName: "Bussiness Unit",
        minWidth: 140,
        field: "bussinessUnit",
        id: 2,
        renderHeader: () => (
          <Tooltip title="Bussiness Unit">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Bussiness Unit
            </Typography>
          </Tooltip>
        ),
      },
      {
        headerName: "Client Name",
        minWidth: 140,
        field: "clientName",
        id: 7,
        renderHeader: () => (
          <Tooltip title="Client Name">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Client Name
            </Typography>
          </Tooltip>
        ),
      },
      {
        headerName: "Recruiter Name",
        minWidth: 160,
        field: "recruiterName",
        renderHeader: () => (
          <Tooltip title="Recruiter Name">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Recruiter Name
            </Typography>
          </Tooltip>
        ),
      },
      {
        headerName: "Account Executive",
        minWidth: 200,
        field: "buSupervisor",
        renderHeader: () => (
          <Tooltip title="Account Executive">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Account Executive
            </Typography>
          </Tooltip>
        ),
      },

      {
        headerName: "Onboarding Date",
        minWidth: 150,

        field: "onboardingDate",
        id: 3,
        renderHeader: () => (
          <Tooltip title="Onboarding Date">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              Onboarding Date
            </Typography>
          </Tooltip>
        ),
      },
      {
        minWidth: 150,
        field: "aeTotalCommission",
        id: 9,
        valueFormatter: (params) => `$ ${params.value}`,
        align: "right",
        headerAlign: "right",
        renderHeader: () => (
          <Tooltip title="AE Total Commission">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              AE Total Comm
            </Typography>
          </Tooltip>
        ),
      },

      {
        headerName: "AE Monthly Commission",
        minWidth: 150,
        field: "aeMonthlyCommission",
        id: 9,
        valueFormatter: (params) => `$ ${params.value}`,
        align: "right",
        headerAlign: "right",
        renderHeader: () => (
          <Tooltip title="AE Monthly Commission">
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap",
              }}
            >
              AE Monthly Comm
            </Typography>
          </Tooltip>
        ),
      },
    ];
  }, [countryRecruiter]);

  const commissionColumns = useMemo(() => {
    return [
      {
        headerName: "Candidate Name",
        flex: 1,
        field: "candidateName",
        id: 1,
        renderCell: (params) => (
          <Link
            component="button"
            variant="body2"
            onClick={() =>
              viewMydetails(
                "View",
                params.row.candidateDetailId,
                params.row.candidateName
              )
            }
          >
            {params.row.candidateName}
          </Link>
        ),
      },
      {
        headerName: "Onboarding Date",
        flex: 1,
        field: "onboardingDt",
        id: 3,
      },
      {
        headerName: "Commission Month",
        flex: 1,
        field: "commissionYearAndMnth",
        id: 4,
      },
      {
        headerName: "Monthly Commission",
        flex: 1,
        field: "monthlyCommission",
        id: 7,
        valueFormatter: (params) => {
          return `$ ${params.value ?? "0.00"}`;
        },
        align: "right",
        headerAlign: "right",
      },

      {
        headerName: "Total Monthly Commission",
        flex: 1,
        field: "totalCommissionFor24Months",
        id: 9,
        valueFormatter: (params) => `$ ${params.value ?? "0.00"}`,
      },
    ];
  });

  const getPaginationSizeOptions = (candidateCount) => {
    const defaultOptions = [50];
    if (candidateCount <= 50) {
      return defaultOptions;
    }
    const paginationOptions = [];
    const maxPageSize = Math.min(candidateCount, 100);
    for (let i = 50; i <= maxPageSize; i += 50) {
      paginationOptions.push(i);
    }

    return paginationOptions;
  };

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const getRecruter = async () => {
    try {
      showLoader();
      const res1 = await RecruiterList(basicgetData.role, basicgetData.country);
      if (res1.status === 200) {
        setRecruiterList(res1.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const getDashboardDetails = async () => {
    try {
      showLoader();
      const payload = {
        recruiterId:
          basicgetData.recruiterName === "none"
            ? null
            : basicgetData.recruiterName,
        role: basicgetData.role === "none" ? null : basicgetData.role,
        country: basicgetData.country === "none" ? null : basicgetData.country,
        preId: basicgetData.preId === "none" ? null : basicgetData.preId,
        empType: basicgetData.empType === "none" ? null : basicgetData.empType,
        startDt: basicgetData.startDate ?? null,
        endDt: basicgetData.endDate ?? null,
        clientName:
          basicgetData.clientName === "none" ? null : basicgetData.clientName,
        bussinessUnintName:
          basicgetData.bussinessUnintName === "none"
            ? null
            : basicgetData.bussinessUnintName,
        page: pageState.page + 1,
        size: pageState.pageSize,
        sortBy: null,
        // startYear: dayjs(startDate).year(),
        // endYear: dayjs(endDate).year(),
        candidateStatus:
          basicgetData.candidateStatus === ""
            ? null
            : basicgetData.candidateStatus,
      };
      const res = await getReportsDashboard(payload);
      if (res?.status === 200) {
        setnoOfCandidates({
          candidatesCount: res.data?.candidatesCount ?? "",
          countryCount: res.data?.countryCount ?? "",
          totalCommissionAmout: res.data?.totalCommissionAmout ?? "",
          clientsCount: res.data?.clientsCount ?? "",
          monthlyCommissionAmout: res.data?.monthlyCommissionAmout ?? "",
          monthlyCommissionWithBonusForInd:
            res.data?.monthlyCommissionIndia ?? "",
          monthlyCommissionUsa: res.data?.monthlyCommissionUsa ?? "",
          monthlyCommissionCanada: res.data?.monthlyCommissionCanada ?? "",
          country: res.data?.recruiterCountry ?? "",
        });
        dispatch(setReportFilters(basicgetData));
        if (isEmpty(res?.data?.candidatesData)) {
          setRow([]);
        } else {
          setCountryRecruiter(res?.data?.recruiterCountry ?? []);
          setRow(res?.data?.candidatesData ?? []);
        }
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
    } finally {
      hideLoader();
    }
  };

  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ fromDate: startDate, toDate: endDate });
    const formattedStartDate = dayjs(startDate).format("MM-DD-YYYY");
    const formattedEndDate = dayjs(endDate).format("MM-DD-YYYY");
    setInputValue(`${formattedStartDate} - ${formattedEndDate}`);
  };

  useEffect(() => {
    getDashboardDetails();
  }, [pageState]);

  useEffect(() => {
    getRecruter();
  }, [basicgetData.role, basicgetData.country]);

  const handleMonthRangeSelect = (startDate, endDate) => {
    const basicData = {
      ...basicgetData,
      startDate: startDate,
      endDate: endDate,
    };
    setBasicGetData(basicData);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    setStartDate(basicgetData.startDate ?? null);
    setEndDate(basicgetData.endDate ?? null);
  }, [basicgetData]);

  useEffect(() => {
    if (basicgetData.role !== "RCTR" && basicgetData.role !== "ACCMNGR") {
      setBasicGetData((prev) => ({
        ...prev,
        country: "none",
      }));
    }
  }, [basicgetData.role]);

  return (
    <Box>
      <Box pb={2}>
        <Grid container spacing={3} pb={2}>
          <Grid item xs={4} sm={6} md={4}>
            <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
              Choose Role
            </Typography>

            <CustomDashboard
              labValue="value"
              label="label"
              iconData={<PermIdentityIcon />}
              options={extractedData?.ROLE ?? []}
              name="role"
              value={basicgetData.role}
              HandleChange={HandleChange}
            />
          </Grid>
          {(basicgetData.role === "RCTR" ||
            basicgetData.role === "ACCMNGR") && (
            <Grid item xs={4} sm={6} md={4}>
              <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
                Choose Recruiter Country
              </Typography>

              <CustomDashboard
                labValue="label"
                label="value"
                name="country"
                access={reportFilter}
                iconData={<PublicOutlinedIcon />}
                HandleChange={HandleChange}
                value={basicgetData.country}
                options={extractedData?.CALCOUNTRY ?? []}
              />
            </Grid>
          )}
          <Grid item xs={4} sm={6} md={4}>
            <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
              Choose Recruiter
            </Typography>
            <CustomDashboard
              labValue="id"
              label="name"
              iconData={<ChooseClient />}
              name="recruiterName"
              HandleChange={HandleChange}
              value={basicgetData.recruiterName}
              options={RecruiterNames}
            />
          </Grid>
          <Grid item xs={4} sm={6} md={4}>
            <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
              Choose Month Range
            </Typography>
            <MonthRangePicker
              maxDate={dayjs().endOf("month").toDate()}
              onSelect={handleMonthRangeSelect}
              defaultValue={[basicgetData.startDate, basicgetData.endDate]}
            />
          </Grid>
          <Grid item xs={4} sm={6} md={4}>
            <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
              Choose Employee Type
            </Typography>
            <CustomDashboard
              labValue="value"
              label="label"
              name="empType"
              iconData={<EmpTypes />}
              HandleChange={HandleChange}
              value={basicgetData.empType}
              options={extractedData?.RESOURCETYPE ?? []}
            />
          </Grid>
          <Grid item xs={4} sm={6} md={4}>
            <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
              Choose Status
            </Typography>
            <CustomDashboard
              labValue="value"
              label="label"
              iconData={<CheckCircleIcon />}
              name="candidateStatus"
              HandleChange={HandleChange}
              value={basicgetData.candidateStatus}
              options={extractedData?.REPORTS_STATUS ?? []}
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography sx={{ color: "#6C6C6C", fontWeight: "600" }}>
              Pre Id
            </Typography>

            <IOSSwitch
              {...label}
              onChange={(e) => {
                setBasicGetData({
                  ...basicgetData,
                  preId: e.target.checked ? "YES" : "NO",
                });
              }}
              sx={{ margin: "0.5rem" }}
            />
          </Grid>
          <Grid
            item
            xs={
              basicgetData.role === "RCTR" || basicgetData.role === "ACCMNGR"
                ? 10
                : 12
            }
            sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              sx={{
                marginTop: "0.5rem",
                textTransform: "capitalize",
                padding: "6px 26px",
                border: "1px solid #27282A ",
                color: "#27282A",
              }}
              onClick={clearFilter}
            >
              Clear
            </Button>

            <Button
              variant="contained"
              sx={{
                marginTop: "0.5rem",
                backgroundColor: "#6E9FFF",
                color: "white",
                padding: "6px 26px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                // if (basicgetData.country !== "none") {
                //   if (basicgetData.recruiterName === "none") {
                //     toast.error("Please Choose Recruiter");
                //     return;
                //   }
                // }
                // If no issues, proceed with fetching dashboard details
                getDashboardDetails();
              }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          md={
            basicgetData.country == null || basicgetData.country === "none"
              ? 3
              : 4
          }
          sm={12}
        >
          <Box
            sx={{
              backgroundColor: "#9DC8C7",
            }}
            className={styles.colorBox}
          >
            <Typography sx={{ color: "white" }}>No of Candidate</Typography>
            <Stack
              justifyContent="end"
              sx={{ color: "white", marginTop: "1rem", alignItems: "center" }}
              direction="row"
            >
              <AccountCircleOutlinedIcon />
              <Typography
                sx={{
                  flexWrap: "nowrap",
                  color: "white",
                  fontWeight: "Bolder",
                  fontSize: "1.2rem",
                }}
              >
                {` ${noOfCandidates?.candidatesCount ?? ""}  Candidates`}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          md={
            basicgetData.country == null || basicgetData.country === "none"
              ? 3
              : 4
          }
          sm={12}
        >
          <Box sx={{ backgroundColor: "#948DB9" }} className={styles.colorBox}>
            <Typography sx={{ color: "white" }}>No of Clients</Typography>
            <Stack
              justifyContent="end"
              sx={{ color: "white", marginTop: "1rem", alignItems: "center" }}
              direction="row"
            >
              <AccountBoxOutlinedIcon />
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "Bolder",
                  fontSize: "1.2rem",
                }}
              >
                {`${noOfCandidates?.clientsCount ?? ""}  Clients`}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          md={
            basicgetData.country == null || basicgetData.country === "none"
              ? 6
              : 4
          }
          sm={12}
        >
          <Box
            sx={{
              backgroundColor: "#EDB148",
            }}
            className={styles.colorBox}
          >
            <Typography sx={{ color: "white" }}>
              {reportFilter
                ? "Monthly Commission Bonus"
                : basicgetData.country === "India"
                ? "Monthly Commission Bonus"
                : basicgetData.country === "none"
                ? "Monthly Commission Bonus / Monthly Commission"
                : "Monthly Commission "}
            </Typography>

            <Stack
              justifyContent="end"
              sx={{ color: "white", marginTop: "1rem" }}
              direction="row"
              spacing={2}
            >
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "bolder", // Use correct casing
                  fontSize: "1.2rem",
                }}
              >
                {reportFilter
                  ? new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(noOfCandidates.monthlyCommissionWithBonusForInd)
                  : basicgetData.country === "India"
                  ? new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(noOfCandidates.monthlyCommissionWithBonusForInd)
                  : basicgetData.country === "USA"
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(noOfCandidates.monthlyCommissionUsa)
                  : basicgetData.country === "Canada"
                  ? new Intl.NumberFormat("en-CA", {
                      style: "currency",
                      currency: "CAD",
                    }).format(noOfCandidates.monthlyCommissionCanada) +
                    "(Canada)"
                  : [
                      new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(
                        noOfCandidates.monthlyCommissionWithBonusForInd || 0
                      ),

                      new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(noOfCandidates.monthlyCommissionUsa || 0),

                      new Intl.NumberFormat("en-CA", {
                        style: "currency",
                        currency: "CAD",
                      }).format(noOfCandidates.monthlyCommissionCanada || 0) +
                        "(Canada)",
                    ].join(" / ")}

                {/* Conditional rendering of the commission based on country */}
                {/* {(() => {
                  const {
                    country,
                    monthlyCommissionAmout,
                    monthlyCommissionWithBonusForInd,
                    monthlyCommissionUsa,
                    monthlyCommissionCanada,
                  } = noOfCandidates;
                  const amount =
                    country === "India"
                      ? monthlyCommissionWithBonusForInd ?? 0
                      : monthlyCommissionAmout ?? 0;

                  switch (country) {
                    case "India":
                      return new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(amount);
                    case "CANADA":
                      return new Intl.NumberFormat("en-CA", {
                        style: "currency",
                        currency: "CAD",
                      }).format(amount);
                    case "USA":
                    default:
                      return new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(amount);
                  }
                })()} */}
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label="Recruiter Commission"
              value="1"
              sx={{ textTransform: "capitalize" }}
            />
            <Tab
              label="Account Executive Commission "
              value="2"
              disabled={reportFilter}
              sx={{ textTransform: "capitalize" }}
            />
          </TabList>
        </Box>
        <Grid
          sx={{
            display: "flex",
            marginRight: "15px",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={() => downloadExcel()}>
            <Tooltip title="Export as an Excel" placement="top">
              <AssessmentIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <TabPanel value="1">
          <DataGrid
            rows={rows ?? []}
            key={rows.length}
            getRowId={(row, index) =>
              `${row.applicationId}-${row.buSupervisor}-${index}`
            }
            columns={columns ?? []}
            rowCount={noOfCandidates?.candidatesCount}
            pageSizeOptions={getPaginationSizeOptions(
              noOfCandidates?.candidatesCount
            )}
            paginationMode="server"
            onPaginationModelChange={handlePagination}
            paginationModel={pageState}
            localeText={{ noRowsLabel: "No data found" }}
            sx={{
              backgroundColor: "white",
              width: "100%",
              "& .MuiDataGrid-virtualScroller": {
                maxHeight: "80vh",
              },
              "& .MuiDataGrid-overlayWrapperInner": {
                maxHeight: "80vh",
              },
              maxHeight: "80vh",
              "& .MuiDataGrid-columnHeaders": {
                minWidth: "1000px",
              },
              "& .MuiDataGrid-cell": {
                whiteSpace: "nowrap", // Prevent text wrap in cells
                minWidth: "fit-content", // Let columns fit the content
                "&:first-of-type": {
                  fontWeight: "bold",
                  fontSize: "1.1em",
                },
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
                whiteSpace: "nowrap", // Prevent text wrap in headers
              },
              "& .MuiDataGrid-row": {
                color: "black",
                textAlign: "center",
              },
            }}
            columnBuffer={10}
          />
        </TabPanel>
        <TabPanel value="2">
          <DataGrid
            rows={rows ?? []}
            key={rows.length}
            getRowId={(row, index) =>
              `${row.applicationId}-${row.buSupervisor}-${index}`
            }
            columns={Aecolumns ?? []}
            rowCount={noOfCandidates?.candidatesCount}
            pageSizeOptions={getPaginationSizeOptions(
              noOfCandidates?.candidatesCount
            )}
            paginationMode="server"
            onPaginationModelChange={handlePagination}
            paginationModel={pageState}
            autoHeight
            localeText={{ noRowsLabel: "No data found" }}
            sx={{
              backgroundColor: "white",
              "& .MuiDataGrid-cell": {
                "&:first-of-type": {
                  fontWeight: "bold",
                  fontSize: "1.1em",
                },
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: "0.9rem",
                fontWeight: "700",
                color: "#000000",
              },
              "& .MuiDataGrid-row": {
                color: "black",
                textAlign: "center",
              },
            }}
          />
        </TabPanel>
      </TabContext>

      <Dialog
        fullWidth
        maxWidth="md"
        open={openCommission}
        onClose={closeCommission}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Summary Report</DialogTitle>
        <DialogContent>
          <CommissionMonthReport
            rows={byIdRowData}
            columns={commissionColumns}
            count={count}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeCommission}
            autoFocus
            sx={{ textTransform: "capitalize" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SummaryReports;
