import React, { useState } from "react";
import { useEffect } from "react";

import {
  updateCandidateById,
  getCandidateById,
  postEditedVms,
  getApiResponse,
  postCandidate,
  deleteCell,
} from "../../../services/apiRequests";
import {
  getVmsDataByPagesandRows,
  activityDataSave,
} from "../../../services/apiRequests";
import { styled } from "@mui/styles";

import CommonContainer from "../../../CommonContainer/CommonContainer";
import { Box } from "@mui/material";

import VMSModal from "./VMSModal";

import DeleteModal from "./DeleteModal";

import CreateVmsTables from "./CreateVmsTables";
import { useDispatch, useSelector } from "react-redux";
import {
  setVmsStates,
  setVmsModal,
  setDashboardChild,
  setMatchedLookupobj,
} from "../../../reduxApp/fieldStore";
import useLoader from "../../../Loader/useLoader";
import toast from "react-hot-toast";
export const vmsJson = {
  vmsCompany: "",
  bussinessUnit: "",
  customerName: "",
  feePercent: "",
  effectiveBeginingDt: "",
};
const VMSValue = () => {
  const dispatch = useDispatch();

  const [fetchingVmsData, setFetchingVmsData] = useState(false);
  const [vmsDatas, setVmsdatas] = useState([]);
  const [totalpages, setTotalPages] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [vmsValues, setvmsObj] = useState(vmsJson);
  const [apiIntegrated, setApiIntegrated] = useState(false);
  const [showLoader, hideLoader] = useLoader();

  const employIntegration = useSelector((state) => {
    return state.dropedField;
  });
  useEffect(() => {
    dispatch(
      setDashboardChild({
        id: "",
        userAction: "",
        userName: "",
      })
    );
    dispatch(setMatchedLookupobj({}));
    (async () => {
      setFetchingVmsData(true);
      try {
        showLoader();
        await getVmsDataByPagesandRows(page, rowsPerPage)
          .then((res) => {
            setVmsdatas(res.data.content);
          })
          .catch(console.log)
          .finally(() => {
            setFetchingVmsData(false);
          });
      } catch (error) {
        toast.error("Server Error");
      } finally {
        hideLoader();
      }
    })();
  }, [page, rowsPerPage]);
  const getVmsApi = async () => {
    setFetchingVmsData(true);
    try {
      showLoader();
      await getApiResponse("getvmsdata")
        .then((res) => {
          const vmsWhole = res.data.content.map((obj) => {});
          setVmsdatas(res.data.content);
          setTotalPages(res.data.totalElements);
        })
        .catch(console.log)
        .finally(() => {
          setFetchingVmsData(false);
        });
      return () => {};
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    getVmsApi();
  }, [apiIntegrated]);

  const Title = styled(Box)({
    width: "100%",
    fontWeight: "400",
    backgroundColor: "white",
  });

  async function vmsEdit() {
    await postEditedVms(employIntegration.vmsStats.vmsId, vmsValues).then(() =>
      setApiIntegrated(!apiIntegrated)
    );
    setvmsObj(vmsJson);
  }
  const postApi = async (data = vmsValues) => {
    const date = new Date();
    data[
      "effectiveBeginingDt"
    ] = `${date.getFullYear()}-${date.getMonth()}-${String(
      date.getDate()
    ).padStart(2, "0")}`;

    await postCandidate(data).then(() => setApiIntegrated(!apiIntegrated));

    setvmsObj(vmsJson);
  }; //posting new data
  const deleteVmscell = async (id) => {
    deleteCell(id).then(() => setApiIntegrated(!apiIntegrated));
  };

  const handleAddcommon = () => {
    dispatch(setVmsModal("VMSMODAL"));
    dispatch(
      setVmsStates({
        vmsId: -1,
        action: "Post",
      })
    );
  };
  return (
    <>
      <CommonContainer
        DisplayAddnew={true}
        handleChange={handleAddcommon}
        header="VMS Values"
        isAccess={true}
        addCalc="Add New VMS"
      />
      {!vmsDatas.length ? (
        <div>Loading...</div>
      ) : (
        // <Title>
        <CreateVmsTables
          candidateList={vmsDatas}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          page={page}
          totalpages={totalpages}
          rowsPerPage={rowsPerPage}
        />
        // </Title>
      )}
      {employIntegration.vmsModal != "" &&
        (employIntegration.vmsModal === "Delete" ? (
          <DeleteModal deleteVmscell={deleteVmscell} />
        ) : (
          <VMSModal
            setvmsObj={setvmsObj}
            vmsValues={vmsValues}
            vmsEdit={vmsEdit}
            postApi={postApi}
          />
        ))}
    </>
  );
};

export default VMSValue;
