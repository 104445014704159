import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import { useState, useEffect } from "react";
import {
  getAccountexecute,
  changeApprover,
} from "../../../services/apiRequests";
const AssignModal = ({ setassignModal, assignModal, appID }) => {
  const [dropdownValues, setDDValues] = useState([]);
  const [aeName, setAeName] = useState();
  const MyComponent = styled(Dialog)({
    width: "500px",
    background: "yellow",
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const values = await getAccountexecute();
        if (values.status === 200) {
          setDDValues(values.data);
        }
      } catch (error) {
        console.error("Error fetching account data:", error);
      }
    };

    fetchData();

    return () => {};
  }, []);
  const assignedVal = (id) => {
    setAeName(id.target.value);
  };

  function autoRefresh() {
    window.location = window.location.href;
  }

  const assginTo = async () => {
    if (aeName) {
      const posted = await changeApprover({
        application_id: appID,
        assigned_to: aeName,
      });
      if (posted.status === 200) {
        setassignModal(false);
        setInterval(autoRefresh(), 2000);
      }
    }
  };
  return (
    <>
      <Dialog open={assignModal} style={{ width: "100%" }}>
        <Box style={{ alignSelf: "self-end" }}>
          <Button
            style={{ alignSelf: "flex-end" }}
            onClick={() => setassignModal(false)}
          >
            <CloseIcon style={{ alignSelf: "flex-end", color: "grey" }} />
          </Button>
        </Box>
        <Box
          style={{
            border: "2px solid grey",
            margin: "15px",
            padding: "15px",
            display: "flex",
            gap: "3em",
            fontSize: "28px",
            fontFamily: "Segoe UI Emoji",
          }}
        >
          <p> Account Executives</p>
          <Select
            style={{ width: "500px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            onChange={assignedVal}
          >
            {dropdownValues.map((item) => {
              return (
                <MenuItem value={item.id}>
                  {item.firstName + " " + item.lastName}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: " 0px 10px 10px 10px",
            gap: "15px",
          }}
        >
          <Button variant="outlined" onClick={() => setassignModal(false)}>
            Calcel
          </Button>
          <Button variant="contained" onClick={assginTo}>
            Send
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default AssignModal;
