import React from "react";
import PropTypes from "prop-types";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { getLoaderState } from "./loaderSelector";

function Loader() {
  const open = useSelector(getLoaderState);
  if (!open) return;
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 999999 }}
      open={true}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loader;
