import axios from "axios";
import HttpClient, { AuthClient } from "../utils/HttpClient";

export const getApiResponse = (apiLink) => HttpClient.get(`/rcs/${apiLink}`);

export const getVmsDataByPagesandRows = (page, rows) =>
  HttpClient.get(`/rcs/getvmsdata?page=${page}&size=${rows}`);

export const getDashboardDataByNames = (name) => {
  return HttpClient.get(`/rcs/searchbycandidate/${name}`);
};

export const getDashboardByIdOrder = (page, size, arrow) => {
  return HttpClient.get(
    `/rcs/getdashboarddata?page=${page}&size=${size}&sort=candidate_detail_id,${arrow}`
  );
};

export const activityDataSave = (obj) => {
  return HttpClient.post("/rcs/activityDataSave", obj);
};

export const changeStatusInfo = (data) => {
  return HttpClient.post("/rcs/changeStatus", data);
};

export const deleteDashboardItem = (id) => {
  return HttpClient.post(`/rcs/deletebycandidateid/${id}`);
};

export const getDashboardByNameOrder = (page, size, arrow) => {
  return HttpClient.get(
    `/rcs/getdashboarddata?page=${page}&size=${size}&sort=candidate_name,${arrow}`
  );
};

export const getDashboardbyPageandRows = (page, rows) =>
  HttpClient.get(`/rcs/getdashboarddata?page=${page}&size=${rows}`);

export const getLoginApi = (data) => HttpClient.get(`/rcs/userLogin`);

export const postDashboard = (data) => {
  HttpClient.get(`/rcs/createnewcandidate`, data);
};

export const getCandidateById = (originApi, myId) =>
  HttpClient.get(`rcs/${originApi}/${myId}`);

export const getBidBreakDownById = (id) =>
  HttpClient.get(`/rcs/getBidBreakDown/${id}`); //BidBreak Down

export const getBrtoPrbyId = (id) => HttpClient.get(`/rcs/getBrToPr/${id}`);

export const postEditedVms = (id, data) =>
  HttpClient.post(`/rcs/update/${id}`, data);

export const updateCandidateById = (id, data) =>
  HttpClient.post(`/rcs/edithistorysave/${id}`, data);

export const submitMyrecord = (id, data) =>
  HttpClient.post(`/rcs/submit/${id}`, data);

export const postCandidate = (data) => {
  return HttpClient.post("/rcs/newvmsdata", data);
}; //vms post

export const loginHeader = (data) => {
  return HttpClient.post("/rcs/userLogin", (Headers = { data }));
};

export const deleteCell = (id) => HttpClient.delete(`/rcs/deletevms/${id}`);

export const getLookup = () => HttpClient.get("/rcs/getLookup");

export const getSupervisorsData = () =>
  HttpClient.get("/rcs/getSupervisorsData");

export const postRegisterData = (data) => {
  return AuthClient.post(
    `/keycloak/${process.env.REACT_APP_REALMS}/createUser`,
    data
  );
};

export const reporterDashboard = (id) => {
  return HttpClient.get(`/rcs/rolesDashboard/${id}`);
};

export const updatePassowrdRed = (data) => {
  return AuthClient.post(
    `/keycloak/${process.env.REACT_APP_REALMS}/updatePasswordRequiredAction`,
    data
  );
};

export const updatePassowrd = (data) => {
  return AuthClient.post(
    `/keycloak/${process.env.REACT_APP_REALMS}/updateUsers`,
    data
  );
};

export const postRateCalc = (Data) => {
  return HttpClient.post("/rcs/createnewcandidate", Data);
};

export const calculateData = (Data) => {
  return HttpClient.post("/rcs/calculate", Data);
};

export const getAccountexecute = () => {
  return HttpClient.get(`/rcs/getAccountexecute`);
};

export const changeApprover = (obj) => {
  return HttpClient.post(`/rcs/changeApprover`, obj);
};

export const rcSubmit = (id, data) =>
  HttpClient.post(`/rcs/submit/${id}`, data);

export const getVmsCompanyName = () => HttpClient.get("/rcs/getvmsdata");

export const getNotificationTables = () => HttpClient.get("/rcs/alerts");

export const updateNotificationAction = (id, data) =>
  HttpClient.post("/rcs/statusUpdate", data);

export const submitAeNotification = (id, data) =>
  HttpClient.post(`/rcs/OnboardingDateUpdate/${id}`, data);

export const submitNotification = (id, data) =>
  HttpClient.post(`/rcs/statusUpdate/${id}`, data);

export const getAuditingTable = () => HttpClient.get("/rcs/getActivityData");

export const getReportsDashboard = (payload) => {
  return HttpClient.post("/rcs/reports", payload);
};

export const RecruiterList = (role, Country) => {
  const rec = role === "none" ? "RCTR" : role;
  return HttpClient.post("/rcs/getUsers", {
    roles: [rec],
    country: Country === "none" ? [] : [Country],
  });
};

export const MonthlyCommisions = (payload) => {
  return HttpClient.post("rcs/reports/monthlyCommission", payload);
};

export const GetComments = (applicationId) => {
  return HttpClient.get(`rcs/comments/get/${applicationId}`);
};

export const PostComment = (payload) => {
  return HttpClient.post("/rcs/comments", payload);
};

export const ApprovedData = (page) => {
  return HttpClient.get(
    `rcs/getApprovedData?page=${page.page}&size=${page.pageSize}&sortBy=applicationId`
  );
};

export const AccExedashboardData = (page = 0, size = 10) => {
  return HttpClient.get(`rcs/getAccExedashboarddata?page=${page}&size=${size}`);
};

export const deboardUser = (id) => {
  return HttpClient.post(`/rcs/deleteUser/${id}`);
};

export const terminationCandidate = (payload) => {
  return HttpClient.post("/rcs/terminationDateUpdate", payload);
};

export const cancelCandidate = (id) => {
  return HttpClient.post(`/rcs/candidate/cancel/${id}`);
};

export const commissionDate = (id) => {
  return HttpClient.get(`/rcs/reports/getCommissionData/${id}`);
};

export const excelReport = (data) => {
  return HttpClient.post(`/rcs/reports/summary/downloadRecord`, data, {
    responseType: "arraybuffer",
  });
};