import React from "react";
import { Typography, Modal, Paper, Dialog, Button, Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  display: "flex",
  border: "none",
  flexDirection: "column",
  boxShadow: 24,
  alignItems: "center",
  p: 4,
  border: "none",
  outline: "none",
};
const SucessModel = ({ open, handleClose, handleApprove, words }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ padding: "0 1rem 0 1rem" }}>
        <Box
          sx={{
            //  width: "330px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            sx={{ fontWeight: "600", color: "grey", margin: "10px" }}
          >
            Confirm {words}
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="p"
            sx={{ color: "grey", textAlign: "center" }}
          >
            Are you sure you want to {words} the application?
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignSelf: "stretch",
          flexDirection: "column",
        }}
      >
        <Box
          display={"flex"}
          sx={{
            margin: "25px",
            display: "flex",
            padding: "0px 10px",
            justifyContent: "space-evenly",
            alignItems: "flex-end",
            alignSelf: "stretch",
          }}
        >
          <Button
            onClick={handleClose}
            style={{
              color: "black",
              padding: "10px 35px",
              fontSize: "14px",
              border: "1px solid black",
              background: "white",
              borderRadius: "3px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleApprove}
            style={{
              background: "#4BB543",
              borderRadius: "3px",
              marginLeft: "7px",
              border: "none",
              padding: "10px 35px",
              color: "white",
              fontSize: "14px",
            }}
          >
            Approve
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SucessModel;
