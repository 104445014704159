import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./MonthRangePicker.css";

const MonthRangePicker = ({ onSelect, defaultValue, maxDate }) => {
  const [value, setValue] = useState([null, null]);
  useEffect(() => {
    if (defaultValue[0] === null && defaultValue[1] === null) {
      setValue([]);
    } else if (Array.isArray(defaultValue) && defaultValue.length === 2) {
      const startDate = new Date(defaultValue[0]);
      const endDate = new Date(defaultValue[1]);
      setValue([startDate, endDate]);
    }
  }, [defaultValue]);

  const handleDateChange = (range) => {
    setValue(range);
    if (range[0] && range[1]) {
      const startMonth = range[0].month.index + 1;
      const endMonth = range[1].month.index + 1;
      const startYear = range[0].year;
      const endYear = range[1].year;
      const lastDayOfMonth = new Date(endYear, endMonth, 0).getDate();
      const startDate = `${startYear}-${startMonth
        .toString()
        .padStart(2, "0")}-01`;
      const endDate = `${endYear}-${endMonth
        .toString()
        .padStart(2, "0")}-${lastDayOfMonth.toString().padStart(2, "0")}`;
      onSelect(startDate, endDate);
    }
  };

  return (
    <div className="month-range-picker">
      <DatePicker
        range
        value={value}
        onChange={handleDateChange}
        format="MMMM YYYY"
        maxDate={maxDate}
        numberOfMonths={2}
        months={[
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]}
        onlyMonthPicker
        render={(value, openCalendar) => (
          <TextField
            value={value}
            onClick={openCalendar} // Open calendar when clicked
            fullWidth
            readOnly
            variant="outlined"
            placeholder="Select Month Range"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={openCalendar}>
                    <CalendarMonthIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        style={{
          width: "100%",
          boxSizing: "border-box", // Ensures padding and border are included in width
        }}
      />
    </div>
  );
};

export default MonthRangePicker;
