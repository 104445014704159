import { React, useMemo, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { subRoutes } from "../../routing/routes";
import LeftnavLinks from "./LeftnavLinks";
import PrivateRoute from "../Login/PrivateRoute";
import Login from "../Login/Login";
import HttpClient from "../../utils/HttpClient";
import { useSelector } from "react-redux";
import { getAuthToken } from "../../selectors/loginAuthSelector";
import Registration from "../Login/Registration";

const LeftIndex = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="" element={<PrivateRoute />}>
        <Route exact path="/" element={<LeftnavLinks />}>
          {subRoutes.map((routeLIst, keys) => {
            return (
              <Route
                key={keys}
                path={routeLIst.path}
                exact
                element={<routeLIst.Element />}
              />
            );
          })}
        </Route>
      </Route>
    </Routes>
  );
};

export default LeftIndex;
