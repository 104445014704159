import React from "react";
import {
  Typography,
  Modal,
  Paper,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "551px",
  bgcolor: "background.paper",
  display: "flex",
  border: "none",
  flexDirection: "column",
  boxShadow: 24,
  alignItems: "center",
  p: 4,
  border: "none",
  outline: "none",
};
const AreYouSureModel = ({ open, handleClose, HandleCancel, HandleDelete }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        // width: "637px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogContent>
        <Box>
          <Typography
            style={{
              fontSize: "3rem",
              textAlign: "center",
              color: "#EFB725",
              fontWeight: "600",
              fill: "1",
            }}
          >
            ALERT
          </Typography>
          <Box
            sx={{
              //  width: "330px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h4"
              sx={{ fontWeight: "600", color: "#6C6C6C", margin: "10px" }}
            >
              Are you sure ?
            </Typography>
            <Typography
              id="modal-modal-description"
              variant="p"
              sx={{ color: "#6C6C6C", textAlign: "center" }}
            >
              Do you want to Decline Application
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignSelf: "stretch",
              flexDirection: "column",
            }}
          >
            <Box
              display={"flex"}
              sx={{
                margin: "25px",
                display: "flex",
                padding: "0px 10px",
                justifyContent: "space-evenly",
                alignItems: "flex-end",
                alignSelf: "stretch",
              }}
            >
              <Button
                onClick={HandleCancel}
                style={{
                  color: "black",
                  padding: "10px 35px",
                  fontSize: "14px",
                  border: "1px solid black",
                  background: "white",
                  borderRadius: "3px",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={HandleDelete}
                style={{
                  background: "var(--Warning-Color, #EFB725)",
                  borderRadius: "3px",
                  marginLeft: "7px",
                  border: "none",
                  padding: "10px 35px",
                  color: "white",
                  fontSize: "14px",
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      {/* <DialogContent sx={style}>
        <span
          class="material-symbols-outlined"
          style={{
            fontSize: "80px",
            color: "#EFB725",
            fontWeight: "600",
            fill: "1",
          }}
        >
          ALERT
        </span>
        <Box
          sx={{
            //  width: "330px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            sx={{ fontWeight: "600", color: "#6C6C6C", margin: "10px" }}
          >
            Are you sure ?
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="p"
            sx={{ color: "#6C6C6C", textAlign: "center" }}
          >
            Do you want to Decline Application
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignSelf: "stretch",
            flexDirection: "column",
          }}
        >
          <Box
            display={"flex"}
            sx={{
              margin: "25px",
              display: "flex",
              padding: "0px 10px",
              justifyContent: "space-evenly",
              alignItems: "flex-end",
              alignSelf: "stretch",
            }}
          >
            <Button
              onClick={HandleCancel}
              style={{
                color: "black",
                padding: "10px 35px",
                fontSize: "14px",
                border: "1px solid black",
                background: "white",
                borderRadius: "3px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={HandleDelete}
              style={{
                background: "var(--Warning-Color, #EFB725)",
                borderRadius: "3px",
                marginLeft: "7px",
                border: "none",
                padding: "10px 35px",
                color: "white",
                fontSize: "14px",
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </DialogContent> */}
    </Dialog>
  );
};

export default AreYouSureModel;
