import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/es/storage/session";
// import { userData } from '../../reduxApp/fieldStore';
import authReducer from "./authSlice";
import loaderReducer from "../../Loader/loaderSlice";
import fieldStore from "../../reduxApp/fieldStore";

const persistConfig = {
  key: "root",
  version: 1,
  storage: sessionStorage,
};

export function createReducer() {
  const rootReducer = combineReducers({
    auth: persistReducer(persistConfig, authReducer),
    // auth: authReducer,
    loader: loaderReducer,
    dropedField: fieldStore,
  });

  return rootReducer;
}
